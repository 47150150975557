import { Dispatch } from 'store';
import { ValidationError } from 'yup';

import {
  dispatchManualError,
  getApiErrorMessage,
  isApiError,
  isZmyleApiError,
} from '../errors';

import { getI18nNamespace } from './language';

export const apiErrorHandler = (error: Error, dispatch: Dispatch) => {
  if (!isZmyleApiError(error)) {
    dispatchManualError({
      error,
      tags: { unexpectedErrorObjectShape: true },
    });
  }

  if (!isApiError(error) || !error?.response) {
    const errorTranslator = getI18nNamespace('error');

    const unexpectedErrorMessage = errorTranslator('error.unexpectedError');

    dispatch.alert.OPEN_ALERT_DIALOG({
      alertDialogMessage: unexpectedErrorMessage,
      alertDialogType: 'error',
    });

    return;
  }

  const { data } = error.response;

  const errorMessage = getApiErrorMessage(data);

  dispatch.alert.OPEN_ALERT_DIALOG({
    alertDialogMessage: errorMessage,
    alertDialogType: 'error',
  });
};

export const yupErrorHandler = <T>(
  error: unknown,
): Record<Partial<keyof T>, string> | null => {
  if (!error || typeof error !== 'object' || !('inner' in error)) return null;

  const yupError = error as ValidationError;
  const errors = {} as Record<Partial<keyof T>, string>;

  yupError.inner.forEach(err => {
    if (err.path && err.message) errors[err.path] = err.message;
  });

  return errors;
};
