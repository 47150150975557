import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { buildYupLocale } from 'services/yup';

// English imports
import enAccountsDashboard from 'locales/en/accountsDashboard.json';
import enCompany from 'locales/en/company.json';
import enComponents from 'locales/en/components.json';
import enForm from 'locales/en/form.json';
import enNetwork from 'locales/en/network.json';
import enRetailer from 'locales/en/retailer.json';
import enError from 'locales/en/error.json';

// German imports
import deAccountsDashboard from 'locales/de/accountsDashboard.json';
import deCompany from 'locales/de/company.json';
import deComponents from 'locales/de/components.json';
import deForm from 'locales/de/form.json';
import deNetwork from 'locales/de/network.json';
import deRetailer from 'locales/de/retailer.json';
import deError from 'locales/de/error.json';
import { config } from './config';

export const translationResources = {
  en: {
    accountsDashboard: enAccountsDashboard,
    company: enCompany,
    components: enComponents,
    form: enForm,
    network: enNetwork,
    retailer: enRetailer,
    error: enError,
  },
  de: {
    accountsDashboard: deAccountsDashboard,
    company: deCompany,
    components: deComponents,
    form: deForm,
    network: deNetwork,
    retailer: deRetailer,
    error: deError,
  },
} as const;

export const defaultNS: keyof (typeof translationResources)['en'] =
  'accountsDashboard';

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init(
    {
      resources: translationResources,
      defaultNS,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      saveMissing: true,
      react: {
        defaultTransParent: 'span',
      },
      missingKeyHandler: (ng, ns, key, fallbackValue) => {
        if (config.isDevelopment) {
          // eslint-disable-next-line no-console
          console.error(`Key not found ${key}, ${ng}, ${ns}, ${fallbackValue}`);
        }
      },
    },
    buildYupLocale,
  );

export default i18n;
