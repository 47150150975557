import { MdChevronLeft, MdClose } from 'react-icons/md';
import { ReactNode } from 'react';
import { Icon } from 'components/Icon';

import {
  DialogTitleStyled,
  Title,
  IconButtonStyled,
} from 'components/WizardDialog/WizardDialog.styled';

interface Props {
  title?: ReactNode;
  goBack?(): void;
  onClickClose?(): void;
  hasPreviousStep: boolean;
}

export const WizardDialogHeader = (props: Props) => {
  const { title, goBack, onClickClose, hasPreviousStep } = props;

  return (
    <DialogTitleStyled>
      {hasPreviousStep ? (
        <IconButtonStyled
          onClick={goBack}
          data-testid="wizard-back-step-button"
        >
          <Icon icon={MdChevronLeft} size="1.55rem" />
        </IconButtonStyled>
      ) : (
        <span />
      )}

      <Title data-testid="wizard-title">{title}</Title>

      {onClickClose ? (
        <IconButtonStyled
          onClick={onClickClose}
          data-testid="close-dialog-button"
        >
          <Icon icon={MdClose} size="1.3rem" />
        </IconButtonStyled>
      ) : (
        <span />
      )}
    </DialogTitleStyled>
  );
};
