import { createTheme } from '@mui/material';
import { getPathname, isCompany, isNetwork, isRetailer } from 'utils/paths';
import { benefitGradient } from 'styles/gradient';
import { benefitTheme, networkTheme } from './benefitTheme';
import { defaultTheme, defaultThemeConfig, zmlColors } from './defaultTheme';
import { storeTheme } from './storeTheme';

export const networkThemeStyle: ThemeStyleBackOffice = {
  name: 'network',
  theme: networkTheme,
  gradient: networkTheme.palette.gradient,
  primaryColor: networkTheme.palette.primary.main,
};

export const benefitThemeStyle: ThemeStyleBackOffice = {
  name: 'benefit',
  theme: benefitTheme,
  gradient: benefitTheme.palette.gradient,
  primaryColor: benefitTheme.palette.primary.main,
};

export const storeThemeStyle: ThemeStyleBackOffice = {
  name: 'store',
  theme: storeTheme,
  gradient: storeTheme.palette.gradient,
  primaryColor: storeTheme.palette.primary.main,
};

export const defaultThemeStyle: ThemeStyleBackOffice = {
  name: 'default',
  theme: defaultTheme,
  gradient: defaultTheme.palette.gradient,
  primaryColor: defaultTheme.palette.primary.main,
};

export const getThemeStyleBackOffice = (currentPath?: string) => {
  const pathname = getPathname();

  const path = currentPath || pathname;

  if (isCompany(path)) return benefitThemeStyle;

  if (isNetwork(path)) return networkThemeStyle;

  if (isRetailer(path)) return storeThemeStyle;

  return defaultThemeStyle;
};

interface BuildDynamicThemeParams {
  primaryMain: string;
  primaryContrast: string;
  secondaryMain: string;
  secondaryContrast: string;
}

export const buildDynamicTheme = (params: BuildDynamicThemeParams) => {
  return createTheme({
    ...defaultThemeConfig,
    palette: {
      ...defaultThemeConfig,
      primary: {
        main: params.primaryMain,
        contrastText: params.primaryContrast,
      },
      secondary: {
        main: params.secondaryMain,
        contrastText: params.secondaryContrast,
      },
      zml: zmlColors,
      gradient: benefitGradient,
    },
  });
};

export const convertApiColorToRgb = (data: ZmyleColor) => {
  const { red, green, blue, alpha } = data;

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};
