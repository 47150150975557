import { differenceInDays } from 'date-fns';
import { formatCurrency } from 'utils/currency';
import {
  divideBy100,
  formatDateToApp,
  parseDateFromServer,
} from 'utils/helpers';

export const oneTimeBenefitAdapter = (
  data: OneTimeBenefit,
): AdaptedOneTimeBenefit => {
  const atDate = parseDateFromServer(data.at) || new Date();

  const daysCountSinceCreated = differenceInDays(new Date(), atDate);

  const isCancelable =
    !data.cancelledAt &&
    daysCountSinceCreated <= 45 &&
    !data.invoiceIds.giftcardFundsInvoiceId;

  return {
    ...data,
    isCancelable,
    formatted: {
      at: formatDateToApp(atDate),
      amount: formatCurrency(divideBy100(data.amount)),
      cancelledAt: data.cancelledAt
        ? formatDateToApp(parseDateFromServer(data.cancelledAt))
        : '',
    },
  };
};
