import {
  IconButton as MaterialIconButton,
  Grow as MaterialGrow,
  Popper as MaterialPopper,
  MenuItem as MaterialMenuItem,
  MenuList as MaterialMenuList,
  ListItem,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { drawerWidth } from 'components/Sidebar/Sidebar.styled';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-block: 1.5rem 3.5rem;

  aside {
    > button {
      margin-left: 0.5rem;
    }

    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
`;

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    padding: 0;
    margin: 0 0.5rem 0 0;
  }
`;

interface IconButtonProps {
  $isFilled?: boolean;
}

export const IconButtonStyled = styled(MaterialIconButton)<IconButtonProps>`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  height: 1.5rem;
  width: 1.5rem;
  padding: 0.125rem;
  background-color: ${({ $isFilled, theme }) =>
    $isFilled ? theme.palette.zml.white : 'transparent'};

  ${MOBILE_BREAKPOINT} {
    filter: brightness(0.97);
  }
`;

export const ListItemStyled = styled(ListItem)<any>`
  padding: 0.625rem;
  margin: 0.5rem 0 0 0;
`;

export const Popper = styled(MaterialPopper)`
  z-index: 4;
`;

export const Grow = styled(MaterialGrow)`
  transform-origin: 0 0 0;
`;

export const MenuList = styled(MaterialMenuList)`
  padding: 0.75rem 0.5rem;
`;

export const MenuItem = styled(MaterialMenuItem)`
  margin: 0 0.313rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.813rem;
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.palette.zml.blue800};

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.zml.white};
  }
`;

export const TypographyStyled = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 300;
  padding-top: 0.625rem;
  letter-spacing: unset;
  padding-bottom: 0.625rem;
  text-transform: none;
  transform: translateY(25%);

  max-width: calc(120vw - ${drawerWidth});
`;
