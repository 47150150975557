import { Box, IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const Placeholder = styled.span`
  position: absolute;
  top: 0;
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  display: block;
  opacity: 0.333;
  user-select: none;
  text-decoration: none;
`;

export const Container = styled(Box)`
  position: relative;

  p {
    font-weight: 300;
  }

  .richText_bold {
    font-weight: 400;
  }

  .richText_strikethrough {
    text-decoration: line-through;
  }

  .richText_italic {
    font-style: italic;
  }

  ul,
  ol {
    list-style: initial;
    padding: 0;
    margin: 0;
    margin-left: 16px;
    list-style-position: inside;

    li.richText_listItem {
      margin: 8px 32px;
    }

    li.nestedListItem {
      list-style-type: none;
    }

    li.nestedListItem:before,
    li.nestedListItem:after {
      display: none;
    }
  }

  ol {
    list-style-type: decimal;
  }
`;

export const UL = styled.ul`
  list-style: initial;

  padding-left: 1.5rem;
`;

export const OL = styled.ol`
  padding-left: 1.5rem;
`;

interface MarkProps {
  $bold?: boolean;
  $italic?: boolean;
  $strikeThrough?: boolean;
}

export const Mark = styled.span<MarkProps>`
  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 400;
    `}

  ${({ $italic }) =>
    $italic &&
    css`
      font-style: italic;
    `}

  ${({ $strikeThrough }) =>
    $strikeThrough &&
    css`
      text-decoration: line-through;
    `}
`;

interface IconButtonStyledProps {
  $isActive?: boolean;
}

export const IconButtonStyled = styled(IconButton)<IconButtonStyledProps>`
  padding: 0.25rem;
  border: 1px solid ${({ theme }) => theme.palette.zml.grey100};
  border-radius: 6px;

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.palette.zml.grey100};
      color: ${({ theme }) => theme.palette.zml.grey900};
    `}
`;
