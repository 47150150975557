import { Paper } from '@mui/material';
import styled, { css } from 'styled-components';
import {
  IconColors,
  storeGradient,
  benefitGradient,
  networkGradient,
  defaultGradient,
} from 'styles/gradient';

export const Card = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  word-wrap: break-word;
  font-size: 0.875rem;

  border: 0;
  color: ${({ theme }) => theme.palette.zml.blue800};
  background: ${({ theme }) => theme.palette.zml.white};
  box-shadow: 0 0.063rem 0.25rem 0 rgb(0 0 0 / 14%);
  margin: 1.875rem 0;
  border-radius: 0.375rem;
`;

interface CardHeaderProps {
  $hasDivider?: boolean;
}

export const CardHeader = styled.div<CardHeaderProps>`
  margin: 0.938rem;
  margin-bottom: 0;
  padding: 0;
  position: relative;

  ${({ $hasDivider }) =>
    $hasDivider &&
    css`
      hr {
        margin-top: 0.938rem;
      }
    `}
`;

export const CardTitle = styled.div`
  color: ${({ theme }) => theme.palette.zml.blue800};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > aside {
    align-self: flex-start;
  }

  * {
    font-size: 1.125rem;
  }
`;

export const CardBodyStyled = styled.div`
  margin: 0.938rem;
`;

const grey = css`
  background-color: ${({ theme }) => theme.palette.zml.grey100};
`;

const cardIconStyle = {
  grey,
  storeGradient,
  benefitGradient,
  networkGradient,
  defaultGradient,
};

export type IconBackground =
  | 'grey'
  | 'storeGradient'
  | 'benefitGradient'
  | 'networkGradient'
  | 'defaultGradient'
  | 'theme';

interface CardIconProps {
  color: IconBackground;
  iconBg?: keyof ZmyleThemeColors;
}

export const CardIcon = styled.div<CardIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  && {
    > svg {
      height: 100%;
      width: 100%;
      margin: 0;
    }
  }

  float: left;
  padding: 1rem;
  margin-top: -2.25rem;
  margin-right: 0.938rem;
  border-radius: 0.1875rem;
  width: 3.938rem;
  height: 4.063rem;

  box-shadow: 0 0.25rem 0.25rem ${IconColors.darkBoxShadow};
  color: ${({ theme }) => theme.palette.zml.white};

  ${({ color }) => cardIconStyle[color]}

  ${({ color, theme }) => color === 'theme' && theme.palette.gradient}

  ${({ iconBg, theme }) =>
    iconBg &&
    css`
      background: ${theme.palette.zml[iconBg]};
    `}
`;
