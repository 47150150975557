import { IconButton, MenuItem, MenuList, Paper } from '@mui/material';
import styled from 'styled-components';

export const PopoverMenuPaper = styled(Paper)`
  position: relative;
  display: inline-flex;

  margin-top: -2rem;
  margin-right: -0.5rem;

  border-radius: 6px;
  border-top-right-radius: 20px;
  padding-inline: 0;
  padding-top: 0;
  box-shadow: 0 0 3px #0000004d;
`;

export const PopoverMenuCloseButtonBase = styled(IconButton)`
  align-self: flex-end;
  margin-right: 0.25rem;
  margin-top: 0.25rem;

  svg {
    transform: rotate(-45deg);
  }
`;

export const PopoverMenuItemContainer = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.75rem;
  padding-right: 1.5rem;
  gap: 1rem;

  font-size: 0.75rem;

  span {
    font-weight: 400;
  }

  &:hover {
    transition: all 0.2s ease-in-out;

    color: ${({ theme }) => theme.palette.zml.pink400};

    svg {
      color: ${({ theme }) => theme.palette.zml.pink400};
    }
  }
`;

export const PopoverMenuList = styled(MenuList).attrs({ role: 'menu' })`
  display: flex;
  flex-direction: column;
  padding-inline: 0;
  padding-top: 0;
`;
