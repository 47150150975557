import { cloneElement, ReactElement } from 'react';
import { ThemeProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { LoadingScreen } from 'components/LoadingScreen';

import { AlertDialog } from 'components/AlertDialog';
import { SnackbarAlert } from 'components/SnackbarAlert';
import { useNetwork } from 'hooks/network';
import { AuthBackground } from './AuthLayout.styled';

interface Props {
  children: ReactElement;
}

export const AuthLayout = ({ children }: Props) => {
  const authTheme = useSelector((state: RootState) => state.theme.auth);

  const { signedInNetwork } = useNetwork();

  return (
    <ThemeProvider theme={authTheme}>
      <StyledThemeProvider theme={authTheme}>
        <AuthBackground />
        <LoadingScreen />
        {cloneElement(children, { network: signedInNetwork })}
        <AlertDialog />
        <SnackbarAlert />
      </StyledThemeProvider>
    </ThemeProvider>
  );
};
