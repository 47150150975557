import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import { TopbarDashboard } from 'components/Topbar/TopbarDashboard';

import { LoadingScreen } from 'components/LoadingScreen';
import { SnackbarAlert } from 'components/SnackbarAlert';
import { AlertDialog } from 'components/AlertDialog';
import { defaultTheme } from 'styles/defaultTheme';
import { Footer } from 'components/Footer';
import { Container, Content } from './AccountsLayout.styled';

interface Props {
  children: ReactNode;
}

export const AccountsLayout = (props: Props) => {
  const { children } = props;

  return (
    <ThemeProvider theme={defaultTheme}>
      <StyledThemeProvider theme={defaultTheme}>
        <Container>
          <Content>
            <LoadingScreen />
            <TopbarDashboard />
            {children}

            <AlertDialog />
            <SnackbarAlert />
          </Content>

          <Footer />
        </Container>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};
