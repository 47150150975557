import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Container, ThemeProvider, useMediaQuery } from '@mui/material';
import { ReactNode } from 'react';
import { useBackOfficeTheme } from 'hooks/theme';

import { defaultTheme } from 'styles/defaultTheme';
import { Sidebar } from 'components/Sidebar';
import { TopbarBackoffice } from 'components/Topbar';
import { Footer } from 'components/Footer';
import { LoadingScreen } from 'components/LoadingScreen';
import { SnackbarAlert } from 'components/SnackbarAlert';
import { AlertDialog } from 'components/AlertDialog';
import { useDisclosure } from 'hooks/disclosure';
import { BackofficeLayoutStyled } from './BackofficeLayout.styled';

const { values: breakpoints } = defaultTheme.breakpoints;

interface Props {
  children: ReactNode;
}

export const BackofficeLayout = ({ children }: Props) => {
  const { isOpen, onToggle } = useDisclosure();

  const isNotMobile = useMediaQuery(
    defaultTheme.breakpoints.up(breakpoints.MOBILE),
  );
  const openSidebar = isOpen || isNotMobile;

  const { theme } = useBackOfficeTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <BackofficeLayoutStyled isOpen={isNotMobile}>
          <Sidebar
            open={openSidebar}
            anchor={isNotMobile ? 'left' : 'right'}
            handleDrawerToggle={onToggle}
            variant={isNotMobile ? 'persistent' : 'temporary'}
          />
          <Container component="main">
            <LoadingScreen />
            <div>
              <TopbarBackoffice handleHamburguerMenuClick={onToggle} />
              {children}

              <AlertDialog />
              <SnackbarAlert />
            </div>
            <Footer />
          </Container>
        </BackofficeLayoutStyled>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};
