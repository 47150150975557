import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

export const useRetailer = () => {
  const retailer = useSelector(
    (state: RootState) => state.retailer.currentRetailer,
  );
  const retailers = useSelector((state: RootState) => state.retailer.retailers);

  const dispatch = useDispatch<Dispatch>();

  return {
    retailer,
    retailers,
    retailerActions: dispatch.retailer,
  };
};
