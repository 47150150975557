import { ComponentPropsWithoutRef, ElementType } from 'react';
import {
  Typography as MaterialTypography,
  TypographyProps as MaterialTypographyProps,
} from '@mui/material';
import { MainColors } from 'styles/colors';

type MaterialThemeColors =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

interface Props<T extends ElementType> extends MaterialTypographyProps {
  component?: T;
  color?: keyof ZmyleThemeColors | MaterialThemeColors;
  fontFamily?: 'Roboto' | 'Roboto Slab' | 'Source Code Pro';
  'data-testid'?: string;
}

export type TypographyProps = Props<ElementType>;

export const Typography = <T extends ElementType = 'p'>(
  props: Props<T> & Omit<ComponentPropsWithoutRef<T>, keyof Props<T>>,
) => {
  const {
    fontWeight = '300',
    variant = 'body2',
    fontSize = '1rem',
    color = 'blue800',
    ...rest
  } = props;

  const resolveColor = (): string => {
    const zmyleColor = MainColors[color];

    if (zmyleColor) return zmyleColor;

    return color;
  };

  const fontColor = resolveColor();

  return (
    <MaterialTypography
      fontWeight={fontWeight}
      variant={variant}
      color={fontColor}
      fontSize={fontSize}
      {...rest}
    />
  );
};
