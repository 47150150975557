import { Box } from '@mui/material';
import styled, { css } from 'styled-components';
import { Button } from 'components/Button';

export const IconContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  transition: opacity 0.2s ease-in-out;
`;

interface UploadImgSectionProps {
  $isDragActive?: boolean;
  $hasImg: boolean;
  $isUploading: boolean;
  $isGiftcardPdf: boolean;
}

export const UploadImgSection = styled(Box).attrs({
  role: 'button',
})<UploadImgSectionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  background-color: ${({ theme }) => theme.palette.zml.blue300};
  box-shadow: 0px 5px 15px ${({ theme }) => theme.palette.zml.boxShadow};

  svg {
    color: ${({ theme }) => theme.palette.zml.white};
    mix-blend-mode: difference;
    filter: drop-shadow(
      0 5px 15px ${({ theme }) => theme.palette.zml.boxShadow}
    );
  }

  transition: filter 0.2s ease-in-out;

  ${({ $isDragActive }) =>
    $isDragActive &&
    css`
      filter: brightness(0.9);
    `}

  ${({ $hasImg }) =>
    $hasImg &&
    css`
      ${IconContainer} {
        opacity: 0;
      }
    `};

  ${({ $isUploading }) =>
    $isUploading &&
    css`
      ${IconContainer} {
        opacity: 1;
      }
    `};

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);

    ${({ $hasImg }) =>
      $hasImg &&
      css`
        ${IconContainer} {
          opacity: 1;
        }
      `};
  }

  ${({ $isGiftcardPdf, theme }) =>
    $isGiftcardPdf &&
    css`
      justify-content: flex-end;
      padding: 0.5rem;

      background-color: ${theme.palette.zml.grey50};
    `}
`;

export const ImageView = styled.img`
  max-width: 100%;
`;

export const ControlButton = styled(Button).attrs({
  variant: 'outlined',
})`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  border: 0;
  padding: 0;
  text-transform: none;

  transition: filter 0.2s ease-in-out;

  &:hover {
    border: 0;
    background: transparent;
    text-decoration: underline;

    filter: brightness(0.6);
  }
`;
