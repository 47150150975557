import { useEffect, useRef, useState } from 'react';
import {
  TextFieldProps,
  CircularProgress,
  IconButton,
  Box,
} from '@mui/material';

import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Icon } from 'components/Icon';
import { useTranslation } from 'hooks/translation';
import { TooltipError } from '../TooltipError';

import { TextFieldStyled, HelperText } from './TextFieldForm.styled';

type Props = TextFieldProps & {
  errortext?: string;
  focused?: boolean;
  submitcount?: number;
  isLoading?: boolean;
  focusColor?: keyof ZmyleThemeColors;
};

export type TextFieldFormProps = Props;

export const TextFieldForm = (props: Props) => {
  const {
    helperText,
    errortext,
    focused,
    submitcount,
    isLoading,
    variant = 'standard',
    focusColor,
    ...rest
  } = props;

  const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation('components');

  useEffect(() => {
    if (focused) inputRef.current?.focus();
  }, [focused, submitcount]);

  const hasError = Boolean(errortext);
  const hasHelperText = Boolean(helperText);

  const fieldType = isPasswordRevealed ? 'text' : rest?.type;

  const getRevealPasswordButton = () => {
    if (rest?.type !== 'password') return null;

    const hasValue = Boolean(rest?.value);

    const onRevealPassword = () => {
      setIsPasswordRevealed(state => !state);
    };

    const revealPasswordTitle = t('textFieldForm.revealPasswordTitle', {
      disableParentElement: true,
    });

    const hidePasswordTitle = t('textFieldForm.hidePasswordTitle', {
      disableParentElement: true,
    });

    const shouldShowButton = hasValue || isPasswordRevealed;

    return (
      <IconButton
        title={isPasswordRevealed ? hidePasswordTitle : revealPasswordTitle}
        data-testid="reveal-password-button"
        onClick={onRevealPassword}
        sx={{ visibility: shouldShowButton ? 'visible' : 'hidden' }}
      >
        {isPasswordRevealed && (
          <Icon
            icon={FaRegEyeSlash}
            data-testid="hide-password-icon"
            color="primary"
            size="1.25rem"
          />
        )}

        {!isPasswordRevealed && (
          <Icon
            icon={FaRegEye}
            data-testid="reveal-password-icon"
            color="primary"
            size="1.25rem"
          />
        )}
      </IconButton>
    );
  };

  return (
    <TextFieldStyled
      $focusColor={focusColor}
      inputRef={inputRef}
      margin="normal"
      fullWidth
      variant={variant}
      error={hasError}
      helperText={hasHelperText && <HelperText>{helperText}</HelperText>}
      {...rest}
      type={fieldType}
      InputProps={{
        ...rest.InputProps,
        endAdornment: (
          <>
            {getRevealPasswordButton()}

            {rest.InputProps?.endAdornment}

            {isLoading && (
              <CircularProgress
                color="primary"
                size="0.938rem"
                data-testid={`loading-field${
                  rest?.name ? `-${rest.name}` : ''
                }`}
              />
            )}

            {errortext && (
              <Box>
                <TooltipError error={errortext} />
              </Box>
            )}
          </>
        ),
      }}
    />
  );
};
