import { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';

import { RichTextButton } from '../RichTextButton';
import { RichTextMark } from '../../@types';

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  number: 'Numbered List',
  paragraph: 'Normal',
  text: 'Text',
  check: 'Check List',
} as const;

type BlockType = keyof typeof blockTypeToBlockName;

interface Highlight {
  isBold: boolean;
  isItalic: boolean;
  isStrikethrough: boolean;
}

export const Toolbar = () => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState<BlockType>('paragraph');
  const [highlight, setHighlight] = useState<Highlight | null>(null);
  // const [canUndo, setCanUndo] = useState(false);
  // const [canRedo, setCanRedo] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if (!$isRangeSelection(selection)) return;

    const anchorNode = selection.anchor.getNode();
    const element =
      anchorNode.getKey() === 'root'
        ? anchorNode
        : anchorNode.getTopLevelElementOrThrow();
    const elementKey = element.getKey();
    const elementDOM = activeEditor.getElementByKey(elementKey);

    setHighlight({
      isBold: selection.hasFormat('bold'),
      isItalic: selection.hasFormat('italic'),
      isStrikethrough: selection.hasFormat('strikethrough'),
    });

    if (elementDOM === null) return;

    if ($isListNode(element)) {
      const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);

      const type = parentList
        ? parentList.getListType()
        : element.getListType();

      setBlockType(type);

      return;
    }

    const type = $isHeadingNode(element) ? element.getTag() : element.getType();

    if (!(type in blockTypeToBlockName)) return;

    setBlockType(type as keyof typeof blockTypeToBlockName);
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return activeEditor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar();
      });
    });
  }, [activeEditor, updateToolbar]);

  // Can Undo & Redo buttons
  // useEffect(() => {
  //   return mergeRegister(
  //     activeEditor.registerUpdateListener(({ editorState }) => {
  //       editorState.read(() => {
  //         updateToolbar();
  //       });
  //     }),
  //     activeEditor.registerCommand<boolean>(
  //       CAN_UNDO_COMMAND,
  //       payload => {
  //         // setCanUndo(payload);
  //         return payload;
  //       },
  //       COMMAND_PRIORITY_CRITICAL,
  //     ),
  //     activeEditor.registerCommand<boolean>(
  //       CAN_REDO_COMMAND,
  //       payload => {
  //         // setCanRedo(payload);
  //         return payload;
  //       },
  //       COMMAND_PRIORITY_CRITICAL,
  //     ),
  //   );
  // }, [activeEditor, updateToolbar]);

  const toggleBulletList = () => {
    if (blockType === 'bullet') {
      return editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }

    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
  };

  const toggleNumberedList = () => {
    if (blockType === 'number') {
      return editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }

    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
  };

  const toggleMark = (mark: RichTextMark) => {
    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, mark);
  };

  const {
    isBold = false,
    isItalic = false,
    isStrikethrough = false,
  } = highlight || {};

  return (
    <Stack direction="row" spacing="0.5rem" mt="1rem">
      <RichTextButton type="bold" onClick={toggleMark} isActive={isBold} />

      <RichTextButton type="italic" onClick={toggleMark} isActive={isItalic} />

      <RichTextButton
        type="strikethrough"
        onClick={toggleMark}
        isActive={isStrikethrough}
      />

      <RichTextButton
        type="bullet"
        onClick={toggleBulletList}
        isActive={blockType === 'bullet'}
      />

      <RichTextButton
        type="number"
        onClick={toggleNumberedList}
        isActive={blockType === 'number'}
      />
    </Stack>
  );
};
