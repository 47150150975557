import { api } from 'services/api';
import { ApiErrorId } from 'utils/api';
import { DownloadsEndpoints } from 'utils/endpoints';

export const getErrorsWhitelist = async () => {
  const response = await api.get<{ errorWhitelist: ApiErrorId[] }>(
    DownloadsEndpoints.GET_WHITELIST_ERRORS,
  );

  return response.data;
};
