import { CircularProgress, ThemeProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { useBackOfficeTheme } from 'hooks/theme';
import { RootState } from 'store';
import { BackdropStyled } from './LoadingScreen.styled';

export const LoadingScreen = () => {
  const isLoading = useSelector((state: RootState) => state.loading);
  const { theme } = useBackOfficeTheme();

  if (!isLoading.screen && !isLoading.page) return null;

  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <BackdropStyled
          $isLoading={isLoading}
          open={isLoading.screen || isLoading.page}
          transitionDuration={{
            appear: isLoading.page ? 0 : undefined,
          }}
        >
          <CircularProgress color="primary" size="2.5rem" />
        </BackdropStyled>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};
