import { TableRowProps } from '@mui/material';
import { TableRowStyled } from './Table.styled';

export const TableRow = (props: TableRowProps) => {
  const { children, onClick, ...rest } = props;

  const isClickable = Boolean(onClick);

  return (
    <TableRowStyled $clickable={isClickable} onClick={onClick} {...rest}>
      {children}
    </TableRowStyled>
  );
};
