import { FormControlLabel } from '@mui/material';
import { MdCheck } from 'react-icons/md';
import styled from 'styled-components';

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-right: 0;

  .MuiTypography-root {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.palette.zml.blue800};
  }
`;

export const CheckedIcon = styled(MdCheck)`
  width: 1.25rem;
  height: 1.25rem;
  border: 0.063rem solid ${({ theme }) => theme.palette.zml.grey100};
  border-radius: 0.188rem;
`;

interface UncheckedIconProps {
  $hasError?: boolean;
}

export const UncheckedIcon = styled(MdCheck)<UncheckedIconProps>`
  width: 1.25rem;
  height: 1.25rem;
  border: 0.063rem solid
    ${({ $hasError, theme }) =>
      $hasError ? theme.palette.zml.red100 : theme.palette.zml.grey100};
  border-radius: 0.188rem;
  color: transparent;
`;
