import { BaseSelectProps } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { Subheader } from './Dropdown';

interface Props<T extends DropdownValue> extends BaseSelectProps<T> {
  listSubheader?: Subheader[];
  options?: DropdownOption<T>[];
}

export const NativeDropdown = <T extends DropdownValue>(props: Props<T>) => {
  const { listSubheader, options } = props;

  return (
    <>
      {listSubheader && (
        <>
          <option aria-label="None" value="" />
          {listSubheader.map(subHeader => (
            <optgroup key={uuid()} label={subHeader.name}>
              {subHeader.options.map(option => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </optgroup>
          ))}
        </>
      )}

      {options &&
        options?.map(option => (
          <option
            key={option.id ?? option.value}
            value={option.value}
            data-testid={`${option.value}-option`}
          >
            {option.label}
          </option>
        ))}
    </>
  );
};
