import { Theme } from '@mui/material';
import { createModel } from '@rematch/core';
import {
  buildDynamicTheme,
  convertApiColorToRgb,
  defaultThemeStyle,
} from 'styles/helpers';
import zmyleLogo from 'assets/img/zmyle-logo.svg';
import { sleep } from 'utils/helpers';
import type { RootModel } from '../model';

interface ChangeAuthThemePayload {
  theme: Theme;
  logoUrl: string;
}

interface ThemeStore {
  backOffice: ThemeStyleBackOffice;
  auth: Theme;
  authLogoUrl: string;
}

export const theme = createModel<RootModel>()({
  state: {
    backOffice: defaultThemeStyle,
    auth: defaultThemeStyle.theme,
    authLogoUrl: zmyleLogo,
  } as ThemeStore,
  reducers: {
    CHANGE_BACKOFFICE_THEME: (state, payload: ThemeStyleBackOffice) => {
      return { ...state, backOffice: payload };
    },
    CHANGE_AUTH_THEME: (state, payload: ChangeAuthThemePayload) => {
      return { ...state, auth: payload.theme, authLogoUrl: payload.logoUrl };
    },
  },
  effects: dispatch => ({
    async buildAuthTheme(payload: SignedNetwork) {
      const { colors, graphics } = payload.settings.layout;

      const networkTheme = buildDynamicTheme({
        primaryMain: convertApiColorToRgb(colors.accentColor),
        primaryContrast: convertApiColorToRgb(colors.accentOhColor),
        secondaryMain: convertApiColorToRgb(colors.highlightColor),
        secondaryContrast: convertApiColorToRgb(colors.highlightOhColor),
      });

      dispatch.theme.CHANGE_AUTH_THEME({
        theme: networkTheme,
        logoUrl: graphics.logoWide.url,
      });

      await sleep(0.2);
    },
  }),
});
