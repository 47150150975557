import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'hooks/translation';
import { TextFieldForm } from 'components/Forms/TextFieldForm';

import { useForm } from 'hooks/form';
import { yup } from 'services/yup';

import { createEmployee, CreateEmployeeParams } from 'repositories/employees';
import { Dispatch } from 'store';

import { apiErrorHandler, getI18nNamespace } from 'utils/helpers';
import { useCompany } from 'hooks/company';
import { Form, Footer, ButtonStyled } from './NewEmployeeForm.styled';

interface NewEmployeeForm {
  name: string;
  staffNumber: string;
  costCenter: string;
  email: string;
  confirmEmail: string;
  phone: string;
}

const initialValues: NewEmployeeForm = {
  name: '',
  staffNumber: '',
  costCenter: '',
  email: '',
  confirmEmail: '',
  phone: '',
};

const companyTranslator = getI18nNamespace('company');
const formTranslator = getI18nNamespace('form');

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .test(
      'is-name-filled',
      companyTranslator('benefits.newEmployeeForm.nameOrStaffNumberIsRequired'),
      (value: string | undefined, context) => {
        const { staffNumber = '' } = context.parent;

        if (!value?.trim() && !staffNumber.trim()) return false;

        return true;
      },
    ),
  staffNumber: yup
    .string()
    .test(
      'is-staff-number-filled',
      companyTranslator('benefits.newEmployeeForm.nameOrStaffNumberIsRequired'),
      (value: string | undefined, context) => {
        const { name = '' } = context.parent;

        if (!value?.trim() && !name.trim()) return false;

        return true;
      },
    ),
  email: yup
    .string()
    .label(companyTranslator('benefits.newEmployeeForm.emailLabel'))
    .email(),
  costCenter: yup.string(),
  confirmEmail: yup.string().oneOf(
    [yup.ref('email')],
    formTranslator('validation.mustBeEqual', {
      field: companyTranslator('benefits.newEmployeeForm.emailLabel'),
      fieldEqual: companyTranslator(
        'benefits.newEmployeeForm.confirmEmailLabel',
      ),
    }),
  ),
});

interface Props {
  onSubmitSuccess?: (createdEmployee: Employee) => void;
  showFullForm?: boolean;
}

export const NewEmployeeForm = (props: Props) => {
  const { onSubmitSuccess, showFullForm = true } = props;

  const { t } = useTranslation('company');

  const dispatch = useDispatch<Dispatch>();
  const { company } = useCompany();

  const onSubmit = async (formData: NewEmployeeForm) => {
    try {
      const { name, email, staffNumber, costCenter } = formData;

      const params: CreateEmployeeParams = {
        companyId: String(company?.id),
        name,
        email,
        staffNumber,
        costCenter,
      };

      const employee = await createEmployee(params);

      if (!employee) return;

      const successMessage = t(
        'benefits.newEmployeeForm.createdEmployeeSuccessMessage',
        { disableParentElement: true },
      );
      dispatch.alert.OPEN_SNACKBAR_SUCCESS(successMessage);

      // eslint-disable-next-line no-use-before-define
      resetForm();
      onSubmitSuccess?.(employee);
    } catch (error) {
      apiErrorHandler(error, dispatch);
    }
  };

  const {
    handleSubmit,
    getFieldProps,
    resetForm,
    isSubmitting,
    errors,
    values,
    validateForm,
  } = useForm<NewEmployeeForm>({
    onSubmit,
    initialValues,
    validationSchema,
  });

  const isNameRequired = !values.staffNumber.trim();
  const isStaffNumberRequired = !values.name.trim();

  useEffect(() => {
    if (errors?.name || errors?.staffNumber) validateForm();
  }, [values.name, values.staffNumber, errors?.name, errors?.staffNumber]);

  return (
    <Form onSubmit={handleSubmit} noValidate data-testid="new-employee-form">
      <TextFieldForm
        {...getFieldProps('name')}
        label={t('benefits.newEmployeeForm.nameLabel')}
        autoComplete="name"
        inputProps={{
          'data-testid': 'name-input',
        }}
        required={isNameRequired}
      />

      <TextFieldForm
        {...getFieldProps('staffNumber')}
        label={t('benefits.newEmployeeForm.staffNumberLabel')}
        inputProps={{
          'data-testid': 'staffNumber-input',
        }}
        required={isStaffNumberRequired}
      />

      <TextFieldForm
        {...getFieldProps('costCenter')}
        label={t('benefits.newEmployeeForm.costCenterLabel')}
        inputProps={{
          'data-testid': 'costCenter-input',
        }}
      />

      {showFullForm && (
        <>
          <TextFieldForm
            {...getFieldProps('email')}
            label={t('benefits.newEmployeeForm.emailLabel')}
            inputProps={{
              'data-testid': 'email-input',
            }}
          />

          <TextFieldForm
            {...getFieldProps('confirmEmail')}
            label={t('benefits.newEmployeeForm.confirmEmailLabel')}
            inputProps={{
              'data-testid': 'confirmEmail-input',
            }}
          />
        </>
      )}

      <Footer>
        <ButtonStyled
          type="submit"
          isLoading={isSubmitting}
          data-testid="submit-new-employee-form"
        >
          {t('benefits.newEmployeeForm.nextButton')}
        </ButtonStyled>
      </Footer>
    </Form>
  );
};
