import { matchPath } from 'react-router-dom';
import paths, { isCompany, isNetwork, isRetailer } from 'utils/paths';
import {
  companyMainMenuItems,
  retailerMainMenuItems,
  networkMainMenuItems,
} from './MainMenu';
import {
  companySettingsMenuItems,
  networkSettingsMenuItems,
  retailerSettingsMenuItems,
} from './SettingsMenu';

interface IsLinkSubPagePathParams {
  currentPath: string;
  menuPath: string;
  isSettingsMenu: boolean;
}

export const isLinkSubPagePath = (params: IsLinkSubPagePathParams) => {
  const { currentPath, menuPath, isSettingsMenu } = params;

  const resolveSettingsMenuItems = () => {
    if (isRetailer(currentPath)) return retailerSettingsMenuItems;

    if (isCompany(currentPath)) return companySettingsMenuItems;

    if (isNetwork(currentPath)) return networkSettingsMenuItems;

    return [];
  };

  const resolveMainMenuItems = () => {
    if (isRetailer(currentPath)) return retailerMainMenuItems;

    if (isCompany(currentPath)) return companyMainMenuItems;

    if (isNetwork(currentPath)) return networkMainMenuItems;

    return [];
  };

  const resolveBasePath = () => {
    if (isRetailer(currentPath)) return paths.RETAILER;

    if (isCompany(currentPath)) return paths.COMPANY;

    if (isNetwork(currentPath)) return paths.NETWORK;

    return '';
  };

  const menuItems = isSettingsMenu
    ? resolveSettingsMenuItems()
    : resolveMainMenuItems();

  const basePath = resolveBasePath();

  const menu = menuItems.find(findMenu => {
    if (!findMenu.subPages) return false;

    return matchPath(menuPath, { path: basePath + findMenu.path, exact: true });
  });

  if (!menu) return false;

  const { subPages = [] } = menu;

  const isLinkedSubPage = subPages.some(subPage =>
    matchPath(currentPath, { path: basePath + subPage }),
  );

  return isLinkedSubPage;
};
