import { formatDateToApp, parseDateFromServer } from 'utils/helpers';

export const networkAccountStatementAdapter = (
  accountStatement: AccountStatement,
): AdaptedAccountStatement => {
  return {
    ...accountStatement,
    formatted: {
      forDate: formatDateToApp(parseDateFromServer(accountStatement.forDate)),
    },
  };
};
