import styled from 'styled-components';
import { Typography } from '@mui/material';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

export const Container = styled.footer`
  margin-top: 1rem;
  padding: 0.5rem 0 0 0;
  position: static;
  bottom: 0;
  width: 100%;

  border-top: 1px solid ${({ theme }) => theme.palette.zml.grey100};
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  margin: 0;
  padding: 0;

  li {
    & + li {
      margin-left: 1.875rem;
    }

    a {
      display: block;
      padding: 1rem;
      transition: 0.2s;
      color: ${({ theme }) => theme.palette.zml.blue800};

      :hover {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: underline;
      }
    }
  }

  ${MOBILE_BREAKPOINT} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    li {
      justify-self: center;
      & + li {
        margin: 0;
      }

      a {
        padding: 1.5rem 0;
      }
    }
  }
`;

export const TypographyStyled = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 500;
`;
