/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Checkbox as MaterialCheckbox,
  CheckboxProps,
  FormControlLabelProps as IFormControlLabelProps,
} from '@mui/material';
import { ReactNode } from 'react';
import { TooltipError } from '../TooltipError';
import {
  CheckedIcon,
  FormControlLabelStyled,
  UncheckedIcon,
} from './Checkbox.styled';

interface Props extends CheckboxProps {
  className?: string;
  FormControlLabelProps?: IFormControlLabelProps;
  focused?: boolean;
  submitcount?: number;
  errortext?: string;
  label?: ReactNode;
}

export const Checkbox = (props: Props) => {
  const {
    FormControlLabelProps,
    className,
    label,
    errortext,
    value,
    focused,
    submitcount,
    ...rest
  } = props;

  return (
    <div className={className}>
      <FormControlLabelStyled
        {...FormControlLabelProps}
        label={label}
        control={
          <TooltipError
            error={errortext}
            placement="bottom"
            open
            showOnHover={Boolean(value)}
          >
            <MaterialCheckbox
              color="primary"
              {...rest}
              icon={<UncheckedIcon $hasError={Boolean(errortext)} />}
              checkedIcon={<CheckedIcon />}
            />
          </TooltipError>
        }
      />
    </div>
  );
};
