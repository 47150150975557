import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;

  ${MOBILE_BREAKPOINT} {
    flex-direction: column;
    align-items: flex-start;

    button + button {
      margin-top: 2rem;
    }
  }
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: transparent;
  border: 0;
  margin-left: 1.75rem;

  color: ${({ theme }) => theme.palette.primary.main};

  svg {
    color: ${({ theme }) => theme.palette.primary.main};
    margin-right: 1.125rem;
  }

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const CenterButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ShowMoreStoresButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.palette.zml.blue800};
  transform: translateX(-50%) translateY(25%);

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ${MOBILE_BREAKPOINT} {
    margin-top: 1.437rem;
    transform: unset;
  }
`;
