import { css } from 'styled-components';

export enum IconColors {
  primary0 = '#bc2d65',
  primary59 = '#d93972',
  primary98 = '#ec407a ',
  primary100 = '#eb3573',
  darkBoxShadow = '#00000024',
}

export const defaultGradient = css`
  background: transparent
    linear-gradient(
      90deg,
      #fd317a 0%,
      #f13179 10%,
      #e53178 20%,
      #dc3177 30%,
      #d43076 40%,
      #cf3076 50%,
      #cb3075 60%,
      #c83075 70%,
      #c73075 80%,
      #c63075 90%,
      #c63075 100%
    )
    0% 0% no-repeat padding-box;
`;

export const storeGradient = css`
  background: transparent
    linear-gradient(
      90deg,
      #f3b42d 0%,
      #f4b02e 10%,
      #f5ac2e 20%,
      #f6a82f 30%,
      #f6a330 40%,
      #f79e30 50%,
      #f89931 60%,
      #f99431 70%,
      #fa8f32 80%,
      #fa8a32 90%,
      #fb8533 100%
    )
    0% 0% no-repeat padding-box;
`;

export const benefitGradient = css`
  background: transparent
    linear-gradient(
      90deg,
      #7f5199 0%,
      #77519b 10%,
      #6f519c 20%,
      #66519e 30%,
      #5f51a0 40%,
      #5751a1 50%,
      #5151a3 60%,
      #4a51a4 70%,
      #4551a5 80%,
      #4051a6 90%,
      #3d51a7 100%
    )
    0% 0% no-repeat padding-box;
`;

export const networkGradient = css`
  background: transparent
    linear-gradient(
      90deg,
      #5ac4db 0%,
      #4fb4d2 10%,
      #44a2c9 20%,
      #3a92c0 30%,
      #3184b8 40%,
      #2a77b1 50%,
      #236baa 60%,
      #1d61a4 70%,
      #19589f 80%,
      #15519b 90%,
      #134d99 100%
    )
    0% 0% no-repeat padding-box;
`;
