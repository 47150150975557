import styled, { keyframes } from 'styled-components';

export const Container = styled.main`
  height: 100vh;
  width: 100vw;
  padding-inline: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`;

const float = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5%);
  }
`;

export const FrameContainer = styled.div`
  display: flex;

  animation: ${float} 5s ease-in-out infinite;

  svg {
    width: 100%;
    height: 100%;
  }
`;
