/* eslint-disable jsx-a11y/control-has-associated-label */
import urlcat from 'urlcat';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { ReactNode, useEffect } from 'react';
import { useForm } from 'hooks/form';
import { yup } from 'services/yup';
import {
  ButtonStyled,
  Footer,
  Form,
  HighlightLink,
  CheckboxStyled,
} from 'components/NewCompanyDialog/NewCompanyDialog.styled';
import { Dispatch, RootState } from 'store';
import {
  createCompany,
  CreateCompanyParams,
  joinCompanyToNetwork,
} from 'repositories/companies';
import paths, { ZmyleLinks } from 'utils/paths';
import { TextFieldForm } from 'components/Forms/TextFieldForm';
import { apiErrorHandler, getI18nNamespace } from 'utils/helpers';

interface NewCompanyForm {
  companyName: string;
  contactPerson: string;
  streetAddress: string;
  zipcode: string;
  city: string;
  billingEmail: string;
  acceptedTerms: boolean;
}

const initialValues: NewCompanyForm = {
  companyName: '',
  contactPerson: '',
  streetAddress: '',
  zipcode: '',
  city: '',
  billingEmail: '',
  acceptedTerms: false,
};

const componentsTranslator = getI18nNamespace('components');

const validationSchema = yup.object().shape({
  companyName: yup
    .string()
    .label(componentsTranslator('newCompanyDialog.companyNameLabel'))
    .required(),
  contactPerson: yup
    .string()
    .label(componentsTranslator('newCompanyDialog.contactPersonLabel'))
    .required(),
  streetAddress: yup
    .string()
    .label(componentsTranslator('newCompanyDialog.streetAddressLabel'))
    .required(),
  zipcode: yup
    .string()
    .label(componentsTranslator('newCompanyDialog.zipCodeLabel'))
    .required(),
  city: yup
    .string()
    .label(componentsTranslator('newCompanyDialog.cityLabel'))
    .required(),
  billingEmail: yup
    .string()
    .label(componentsTranslator('newCompanyDialog.billingEmailLabel'))
    .email()
    .required(),
  acceptedTerms: yup
    .boolean()
    .isTrue(componentsTranslator('newCompanyDialog.acceptTermsTooltip')),
});

interface Props {
  closeDialog?(): void;
  header?: ReactNode;
  networkId?: string;
  retailer?: Retailer;
}

export const NewCompanyForm = (props: Props) => {
  const { closeDialog, header, networkId, retailer } = props;

  const user = useSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();

  const { t } = useTranslation('components');

  const onSubmit = async (formData: NewCompanyForm) => {
    try {
      const {
        companyName,
        contactPerson,
        city,
        streetAddress,
        zipcode,
        billingEmail,
      } = formData;

      const params: CreateCompanyParams = {
        name: companyName,
        name2: contactPerson,
        addressCity: city,
        addressStreet: streetAddress,
        addressZipcode: zipcode,
        email: billingEmail,
      };

      if (user && user?.isAdmin) params.userId = user.id;

      const newCompany = await createCompany(params);

      if (!newCompany) return;

      const successMessage = t('newCompanyDialog.createdCompanySuccessMessage');
      dispatch.alert.OPEN_SNACKBAR_SUCCESS(successMessage);

      const updatedUser = await dispatch.auth.whoAmI();

      if (!updatedUser) return;

      const { authRoles } = updatedUser;

      const currentCompany = authRoles.companies.find(
        findCompany => findCompany.id === newCompany.id,
      );

      const company = currentCompany ?? newCompany;

      const newCompanyDashboard = urlcat(paths.COMPANY + paths.DASHBOARD, {
        companyId: company.id,
      });

      if (networkId) {
        await joinCompanyToNetwork({ networkId, companyId: company.id });
      }

      dispatch.company.SET_CURRENT_COMPANY(company);
      history.replace(newCompanyDashboard);

      // eslint-disable-next-line no-use-before-define
      resetForm();
      closeDialog?.();
    } catch (error) {
      apiErrorHandler(error, dispatch);
    }
  };

  const {
    getFieldProps,
    handleSubmit,
    resetForm,
    setValue,
    setValues,
    values,
    isSubmitting,
  } = useForm<NewCompanyForm>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (!retailer) return;

    const { contact } = retailer;
    const { address } = contact;

    const preFilledValues: NewCompanyForm = {
      companyName: contact?.name ?? initialValues.companyName,
      contactPerson: contact?.name2 ?? initialValues.contactPerson,
      billingEmail: contact?.email ?? initialValues.billingEmail,
      city: address?.city ?? initialValues.city,
      streetAddress: address?.streetAddress ?? initialValues.streetAddress,
      zipcode: address?.zipcode ?? initialValues.zipcode,
      acceptedTerms: initialValues.acceptedTerms,
    };

    if (user?.emails.length) {
      preFilledValues.billingEmail = user.emails[0].email;
    }

    setValues(preFilledValues);
  }, [retailer]);

  const handleChangeAcceptedTerms = () => {
    setValue('acceptedTerms', !values.acceptedTerms);
  };

  return (
    <Form onSubmit={handleSubmit} noValidate data-testid="new-company-form">
      {Boolean(header) && header}

      <TextFieldForm
        {...getFieldProps('companyName')}
        label={t('newCompanyDialog.companyNameLabel')}
        required
        inputProps={{
          'data-testid': 'company-name-input',
        }}
      />

      <TextFieldForm
        {...getFieldProps('contactPerson')}
        label={t('newCompanyDialog.contactPersonLabel')}
        required
        inputProps={{
          'data-testid': 'contact-person-input',
        }}
      />

      <TextFieldForm
        {...getFieldProps('streetAddress')}
        label={t('newCompanyDialog.streetAddressLabel')}
        required
        inputProps={{
          'data-testid': 'street-address-input',
        }}
      />

      <TextFieldForm
        {...getFieldProps('zipcode')}
        label={t('newCompanyDialog.zipCodeLabel')}
        required
        inputProps={{
          'data-testid': 'zipcode-input',
        }}
      />

      <TextFieldForm
        {...getFieldProps('city')}
        label={t('newCompanyDialog.cityLabel')}
        required
        inputProps={{
          'data-testid': 'city-input',
        }}
      />

      <TextFieldForm
        {...getFieldProps('billingEmail')}
        label={t('newCompanyDialog.billingEmailLabel')}
        required
        inputProps={{
          'data-testid': 'billing-email-input',
        }}
      />

      <CheckboxStyled
        {...getFieldProps('acceptedTerms')}
        onClick={handleChangeAcceptedTerms}
        checked={values.acceptedTerms}
        data-testid="accepted-terms-checkbox"
        inputProps={
          {
            'data-testid': 'accepted-terms-input',
          } as KeyValue
        }
        label={
          <Trans
            t={t}
            i18nKey="newCompanyDialog.acceptTermsLabel"
            components={{
              'terms-benefits': (
                <HighlightLink
                  href={ZmyleLinks.BENEFITS_TERMS}
                  target="_blank"
                />
              ),
              'terms-benefits-cockpit': (
                <HighlightLink
                  href={ZmyleLinks.BENEFITS_AGREEMENTS}
                  target="_blank"
                />
              ),
              privacy: (
                <HighlightLink
                  href={ZmyleLinks.PRIVACY_POLICY}
                  target="_blank"
                />
              ),
            }}
          />
        }
      />

      <Footer>
        <ButtonStyled
          type="submit"
          isLoading={isSubmitting}
          data-testid="newCompanyDialog-submit-button"
        >
          {t('newCompanyDialog.nextButton')}
        </ButtonStyled>
      </Footer>
    </Form>
  );
};
