/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReactElement } from 'react';
import { TOptions, Namespace, KeyPrefix, TFunctionReturn } from 'i18next';
import { Box } from '@mui/material';
import {
  UseTranslationOptions,
  useTranslation as useI18nTranslation,
  UseTranslationResponse as UseI18nTranslationResponse,
  FallbackNs,
} from 'react-i18next';

type TWrapperReturn<
  N extends Namespace,
  K extends KeyPrefix<FallbackNs<N>>,
  T = TOptions,
> = T extends {
  disableParentElement: true;
}
  ? TFunctionReturn<N, K, T>
  : ReactElement;

type TWrapper<N extends Namespace> = <
  K extends KeyPrefix<FallbackNs<N>>,
  T extends TOptions,
>(
  key: K,
  tOptions?: T,
  defaultValue?: string,
) => TWrapperReturn<N, K, T>;

type UseTranslationResponse<
  N extends Namespace,
  TKPrefix extends KeyPrefix<FallbackNs<N>>,
> = Omit<UseI18nTranslationResponse<FallbackNs<N>, TKPrefix>, 't'> & {
  t: TWrapper<N>;
};

export const useTranslation = <N extends Namespace>(
  ns?: N,
  options?: UseTranslationOptions<KeyPrefix<FallbackNs<N>>>,
): UseTranslationResponse<N, KeyPrefix<FallbackNs<N>>> => {
  const { t, ...rest } = useI18nTranslation(ns, options);

  // @ts-expect-error: t function type conflicts
  const tWrapper: TWrapper<N> = (key, tOptions, defaultValue) => {
    const { disableParentElement = false, ...restTOptions } = (tOptions ||
      {}) as TOptions;

    // @ts-expect-error: t result type conflicts
    const tResult = t(key, { ...restTOptions, ns }) as string;

    if (disableParentElement) return tResult ?? defaultValue;

    return (
      <Box component="span" whiteSpace="pre-line">
        {String(tResult) ?? defaultValue}
      </Box>
    );
  };

  return { t: tWrapper, ...rest };
};
