import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';

interface TextFieldStyledProps {
  $focusColor?: keyof ZmyleThemeColors;
}

export const TextFieldStyled = styled(TextField)<TextFieldStyledProps>`
  > label {
    font-size: 0.875rem;
  }

  input {
    padding-right: 0.5rem;
    font-size: 0.875rem;
  }

  .MuiInput-input {
    color: ${({ theme }) => theme.palette.zml.blue800};
  }

  .MuiInput-root {
    :before {
      border-color: ${({ theme }) => theme.palette.zml.grey100};
    }

    :hover::before:not(:disabled) {
      border-color: ${({ theme }) => theme.palette.zml.blue800};
    }
  }

  .Mui-disabled {
    :before {
      border-bottom-style: dotted;
    }
  }

  .MuiFormLabel-asterisk {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.zml.grey100};
  }

  ${({ $focusColor, theme }) =>
    $focusColor &&
    css`
      label.Mui-focused {
        color: ${theme.palette.zml[$focusColor]};
      }

      .MuiInput-underline:after {
        border-bottom-color: ${theme.palette.zml[$focusColor]};
      }

      .MuiFilledInput-underline:after {
        border-bottom-color: ${theme.palette.zml[$focusColor]};
      }

      .MuiOutlinedInput-root {
        .Mui-focused {
          border-color: ${theme.palette.zml[$focusColor]};
        }
      }

      .MuiFormLabel-asterisk {
        color: ${theme.palette.zml[$focusColor]};
      }
    `}
`;

export const HelperText = styled.span`
  color: ${({ theme }) => theme.palette.zml.blue800};
`;
