import { Alert } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;

  button {
    float: right;
    padding: 0.2rem;
    margin-left: auto;

    svg {
      color: ${({ theme }) => theme.palette.zml.blue800};
    }
  }
`;

export const AlertStyled = styled(Alert)`
  display: flex;
  align-items: flex-start;

  .MuiAlert-message {
    width: 100%;
  }
`;
