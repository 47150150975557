import { ReactNode, forwardRef } from 'react';
import { Divider, Typography } from '@mui/material';
import {
  Card,
  CardHeader,
  CardIcon,
  CardTitle,
  IconBackground,
  CardBodyStyled,
} from './CardWithIcon.styled';

interface Props {
  id?: string;
  title?: ReactNode | string;
  headerRight?: ReactNode;
  icon?: ReactNode;
  iconColor?: IconBackground;
  iconBg?: keyof ZmyleThemeColors;
  children?: ReactNode;
  isBoldTitle?: boolean;
  hasDivider?: boolean;
}

export const CardWithIcon = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    icon,
    iconColor = 'theme',
    iconBg,
    title = '',
    headerRight,
    isBoldTitle,
    children,
    hasDivider,
    ...rest
  } = props;

  const isTitleString = typeof title === 'string';
  const hasHeaderRight = Boolean(headerRight);

  return (
    <Card ref={ref} {...rest}>
      <CardHeader $hasDivider={hasDivider}>
        <CardIcon
          data-testid="card-icon-container"
          color={iconColor}
          iconBg={iconBg}
        >
          {icon}
        </CardIcon>
        <CardTitle data-testid="card-with-icon-title">
          {isTitleString ? (
            <Typography
              component="h1"
              fontWeight={isBoldTitle ? '400' : '300'}
              variant="body2"
            >
              {title}
            </Typography>
          ) : (
            title
          )}

          {hasHeaderRight && <aside>{headerRight}</aside>}
        </CardTitle>

        {hasDivider && <Divider />}
      </CardHeader>
      <CardBodyStyled data-testid="card-with-icon-body">
        {children}
      </CardBodyStyled>
    </Card>
  );
});
