import { formatCurrency } from 'utils/currency';
import {
  formatDateToApp,
  formatDateAndTimeToApp,
  parseDateFromServer,
} from 'utils/helpers';

export const invoiceAdapter = (invoice: Invoice): AdaptedInvoice => {
  return {
    ...invoice,
    formatted: {
      date: formatDateToApp(parseDateFromServer(invoice.invoiceDate)),
      dateAndTime: formatDateAndTimeToApp(
        parseDateFromServer(invoice.invoiceDate),
      ),
      total: formatCurrency(invoice.total / 100),
    },
  };
};
