import { addDays } from 'date-fns';
import { isApiError, isZmyleApiError } from 'utils/errors';
import { BookingReasons } from 'utils/api';
import { store } from 'store';
import { apiErrorHandler, getI18nNamespace } from 'utils/helpers';
import { yup } from 'services/yup';
import { AnySchema, DateSchema } from 'yup';
import { benefitBookingErrorHandler } from './benefitBookingErrorHandler';
import { isQuarterlyMonth } from './components/BenefitSchedule/utils';
import { BenefitCheckoutForm } from './types';

export const benefitCheckoutFormErrorHandler = (error: any) => {
  const { dispatch } = store;

  if (isApiError(error) && isZmyleApiError(error.response?.data)) {
    const { errorId } = error.response.data;

    const message = benefitBookingErrorHandler(errorId as BookingReasons);

    if (message) {
      dispatch.alert.OPEN_ALERT_DIALOG({
        alertDialogMessage: message,
        alertDialogType: 'error',
      });

      return;
    }
  }

  if (typeof error === 'string') {
    const message = benefitBookingErrorHandler(error as BookingReasons);

    if (message) {
      dispatch.alert.OPEN_ALERT_DIALOG({
        alertDialogMessage: message,
        alertDialogType: 'error',
      });

      return;
    }
  }

  apiErrorHandler(error, dispatch);
};

const componentsTranslator = getI18nNamespace('components');

const benefitCheckoutT = componentsTranslator('benefitCheckoutDialog', {
  disableParentElement: true,
  returnObjects: true,
});

const today = new Date();

export const recurringBenefitValidationSchema: Record<
  keyof Pick<BenefitCheckoutForm, 'startsAt' | 'endsAt' | 'interval'>,
  AnySchema
> = {
  interval: yup
    .string()
    .required()
    .label(benefitCheckoutT.intervalsDropdown.label),
  startsAt: yup
    .date()
    .min(today)
    .nullable()
    .required()
    .when('interval', {
      is: 'monthly',
      then: schema =>
        schema.label(benefitCheckoutT.benefitSchedule.monthlyStartsAtLabel),
      otherwise: schema =>
        schema.when('interval', {
          is: 'quarterly',
          then: () =>
            schema
              .label(benefitCheckoutT.benefitSchedule.quarterlyStartsAtLabel)
              .min(today)
              .test(
                'is-valid-month',
                benefitCheckoutT.benefitSchedule.invalidQuarterlyDateMessage,
                (value = null) => value !== null && isQuarterlyMonth(value),
              ),
          otherwise: () =>
            schema.label(
              benefitCheckoutT.benefitSchedule.annuallyStartsAtLabel,
            ),
        }),
    }),
  endsAt: yup
    .date()
    .nullable()
    .when('interval', {
      is: 'monthly',
      then: schema =>
        schema
          .label(benefitCheckoutT.benefitSchedule.monthlyEndsAtLabel)
          .when(
            'startsAt',
            (
              startsAt: BenefitCheckoutForm['startsAt'],
              endsAtSchema: DateSchema<Date | null | undefined>,
            ) => endsAtSchema.min(addDays(startsAt || today, 1)),
          ),
      otherwise: schema =>
        schema.when('interval', {
          is: 'quarterly',
          then: () =>
            schema
              .label(benefitCheckoutT.benefitSchedule.quarterlyEndsAtLabel)
              .test(
                'is-valid-month',
                benefitCheckoutT.benefitSchedule.invalidQuarterlyDateMessage,
                (value = null) => {
                  if (value === null) return true;

                  return isQuarterlyMonth(value);
                },
              ),
          otherwise: () =>
            schema
              .label(benefitCheckoutT.benefitSchedule.annuallyEndsAtLabel)
              .when(
                'startsAt',
                (
                  startsAt: BenefitCheckoutForm['startsAt'],
                  endsAtSchema: DateSchema<Date | null | undefined>,
                ) => endsAtSchema.min(addDays(startsAt || today, 1)),
              ),
        }),
    }),
};
