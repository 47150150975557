import urlcat from 'urlcat';
import { createModel } from '@rematch/core';
import { api } from 'services/api';
import { RootModel, store } from 'store';
import { GiftcardEndpoints } from 'utils/endpoints';

interface SearchGiftcardsPayload {
  retailerId: string;
  shortId?: string;
  includeCampaigns?: boolean;
}

type GiftcardItems = MiniGiftcard[] | null;

export const giftcard = createModel<RootModel>()({
  state: {
    items: null as GiftcardItems,
    isGetGiftcardsLoading: false,
    isRedeemGiftcardLoading: false,
    isActivateGiftcardLoading: false,
  },
  reducers: {
    RESET: state => {
      return {
        ...state,
        items: null,
      };
    },
    SET_GIFTCARDS: (state, payload: GiftcardItems) => {
      return {
        ...state,
        items: payload,
      };
    },
    SET_LOADING_GET_GIFTCARDS: (state, payload: boolean) => {
      return {
        ...state,
        isGetGiftcardsLoading: payload,
      };
    },
    SET_LOADING_REDEEM_GIFTCARD: (state, payload: boolean) => {
      return {
        ...state,
        isRedeemGiftcardLoading: payload,
      };
    },
    SET_LOADING_ACTIVATE_GIFTCARD: (state, payload: boolean) => {
      return {
        ...state,
        isActivateGiftcardLoading: payload,
      };
    },
  },
  effects: dispatch => ({
    async clearGiftcards() {
      dispatch({ type: 'giftcard/RESET' });
    },
    async searchGiftcards({
      shortId,
      includeCampaigns,
      retailerId,
    }: SearchGiftcardsPayload) {
      try {
        dispatch.giftcard.SET_LOADING_GET_GIFTCARDS(true);

        const { data } = await api.get(
          urlcat(GiftcardEndpoints.SEARCH, {
            retailerId,
            shortId,
            includeCampaigns,
          }),
        );

        const giftcards = data || null;

        dispatch.giftcard.SET_GIFTCARDS(giftcards);
      } finally {
        dispatch.giftcard.SET_LOADING_GET_GIFTCARDS(false);
      }
    },
    async getGiftcards() {
      const { data: giftcards } = await api.get<any>(
        urlcat(GiftcardEndpoints.GIFTCARDS, {
          retailerId: store.getState().retailer.currentRetailer?.id,
        }),
      );
      if (!giftcards) return [];
      dispatch({ type: 'giftcard/SET_GIFTCARDS', payload: giftcards });
    },
  }),
});
