import { Component, ReactNode } from 'react';
import { captureException } from 'utils/errors';
import { FallbackUI } from './components/FallbackUI';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    captureException({ error, tags: { source: 'ErrorBoundary Component' } });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) return <FallbackUI />;

    return children;
  }
}
