import 'promise-polyfill/src/polyfill';
import 'url-polyfill';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import 'services/i18n';
import { Providers, ErrorBoundary } from 'components';
import { AppSignalClient } from 'services/appSignal';

import App from './App';

AppSignalClient.init();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <ErrorBoundary>
      <Providers>
        <App />
      </Providers>
    </ErrorBoundary>
  </StrictMode>,
);
