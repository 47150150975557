import { useRef, useState } from 'react';

interface Params<T> {
  initialValues: T;
}

export const useValues = <T extends Record<string, any>>(params: Params<T>) => {
  const { initialValues } = params;

  const [values, _setValues] = useState<T>(initialValues);

  const initialValuesRef = useRef(initialValues);

  const setValue = (field: keyof T, value: T[keyof T]) => {
    _setValues(prevValues => ({ ...prevValues, [field]: value }));
  };

  const setValues = (newValues: Partial<T>) => {
    _setValues({ ...values, ...newValues });
  };

  const resetValues = () => {
    setValues(initialValuesRef.current);
  };

  return { values, setValue, setValues, resetValues };
};
