import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { formatToNumber } from './helpers';

/*
 *
 *  OPTIONS
 *
 */
const generatePrefix = (currency?: string) => {
  switch (currency) {
    case 'USD':
      return '$ ';
    case 'EUR':
      return '';
    default:
      return '';
  }
};
const generateSuffix = (currency?: string) => {
  switch (currency) {
    case 'USD':
      return '';
    case 'EUR':
      return ' €';
    default:
      return ' €';
  }
};
const generateThousandSeparatorSymbol = (currency?: string) => {
  switch (currency) {
    case 'USD':
      return ',';
    case 'EUR':
      return '.';
    default:
      return '.';
  }
};
const generateDecimalSymbol = (currency?: string) => {
  switch (currency) {
    case 'USD':
      return '.';
    case 'EUR':
      return ',';
    default:
      return ',';
  }
};
const generateLocale = (currency?: string) => {
  switch (currency) {
    case 'USD':
      return 'en-IN';
    case 'EUR':
      return 'de-DE';
    default:
      return 'de-DE';
  }
};

interface GenerateMaskOptionsParams {
  currency: Currency;
  decimalLimit?: number;
}
const generateMaskOptions = (params: GenerateMaskOptionsParams) => {
  const { currency, decimalLimit } = params;

  return {
    prefix: generatePrefix(currency),
    suffix: generateSuffix(currency),
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: generateThousandSeparatorSymbol(currency),
    allowDecimal: true,
    decimalSymbol: generateDecimalSymbol(currency),
    decimalLimit: decimalLimit || 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  };
};

/*
 *
 * HELPERS
 *
 */
class CurrencyService {
  constructor(private currency: Currency) {
    const storedCurrency = localStorage.getItem('currency') as Currency | null;

    if (storedCurrency) {
      this.currency = storedCurrency;
    } else {
      localStorage.setItem('currency', currency);
    }
  }

  get getCurrency() {
    return this.currency;
  }

  setCurrency(currency: Currency) {
    this.currency = currency;
  }
}

export const currencyInstance = new CurrencyService('EUR');

export const formatCurrency = (amount: number, minimumFractionDigits = 2) => {
  const currency = currencyInstance.getCurrency;

  const locale = generateLocale(currency);

  const stringAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits: 2,
  }).format(amount);

  if (process.env.NODE_ENV === 'test') {
    return stringAmount.replace(/\xa0/g, ' ').replace(/\u202f/g, ' ');
  }

  return stringAmount;
};

export const formatCurrencyToNumber = (value: string) => {
  const unformattedValue = value.replace(/€|\./g, '');

  return formatToNumber(unformattedValue);
};

/*
 *
 * INTERNAL FUNCTIONS
 *
 */

export const generateNumberMask = (
  currency: Currency,
  decimalLimit?: number,
) => {
  const currencyMask = createNumberMask(
    generateMaskOptions({ currency, decimalLimit }),
  );

  return currencyMask;
};
