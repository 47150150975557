import { createTheme } from '@mui/material';
import { StoreColors } from './colors';

import { defaultThemeConfig, zmlColors } from './defaultTheme';
import { storeGradient } from './gradient';

export const storeTheme = createTheme({
  ...defaultThemeConfig,
  palette: {
    ...defaultThemeConfig,
    primary: {
      main: StoreColors.primary,
      contrastText: zmlColors.white,
    },
    zml: zmlColors,
    gradient: storeGradient,
  },
});
