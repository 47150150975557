import { PropsWithChildren, useMemo } from 'react';
import { IconType } from 'react-icons';
import { Stack, Typography } from '@mui/material';

import { getI18nNamespace } from 'utils/helpers';

import { Icon } from '../../Icon';
import { WizardDialog } from '../../WizardDialog';

import { useBenefitCheckout } from '../BenefitCheckoutContext';
import { BenefitCheckoutType } from '../types';
import { BenefitCheckoutIcons } from '../BenefitCheckoutIcons';

const componentTranslator = getI18nNamespace('components');

const titleByType: Record<
  BenefitCheckoutType,
  { title: string; icon: IconType }
> = {
  oneTime: {
    title: componentTranslator('benefitCheckoutDialog.title.oneTime'),
    icon: BenefitCheckoutIcons.OneTime,
  },
  recurring: {
    title: componentTranslator('benefitCheckoutDialog.title.recurring'),
    icon: BenefitCheckoutIcons.Recurring,
  },
  scheduled: {
    title: componentTranslator('benefitCheckoutDialog.title.scheduled'),
    icon: BenefitCheckoutIcons.Scheduled,
  },
};

export const BenefitCheckoutDialog = (props: PropsWithChildren) => {
  const { children } = props;

  const { isOpen, onClose, resetCheckout, type, action, isSubmitted } =
    useBenefitCheckout();

  const { title, icon } = useMemo(() => {
    if (isSubmitted) {
      return {
        title: componentTranslator('benefitCheckoutDialog.success.title'),
        icon: BenefitCheckoutIcons.Success,
      };
    }

    if (action === 'delete') {
      if (type === 'recurring') {
        return {
          title: componentTranslator(
            'benefitCheckoutDialog.title.deleteRecurring',
          ),
          icon: BenefitCheckoutIcons.Recurring,
        };
      }

      return {
        title: componentTranslator(
          'benefitCheckoutDialog.title.deleteScheduled',
        ),
        icon: BenefitCheckoutIcons.Scheduled,
      };
    }

    if (action === 'update') {
      return {
        title: componentTranslator(
          'benefitCheckoutDialog.title.updateRecurring',
        ),
        icon: BenefitCheckoutIcons.Recurring,
      };
    }

    return titleByType[type];
  }, [type, action, isSubmitted]);

  return (
    <WizardDialog
      isOpen={isOpen}
      onClickClose={isSubmitted ? undefined : onClose}
      closeOnClickAway={false}
      onExited={resetCheckout}
      size="md"
      data-testid="benefit-checkout-dialog"
      title={
        <Stack
          direction={isSubmitted ? 'row-reverse' : 'row'}
          spacing="1rem"
          alignItems="center"
        >
          <Icon icon={icon} size="1.5rem" />

          <Typography fontSize="1.25rem">{title}</Typography>
        </Stack>
      }
    >
      {children}
    </WizardDialog>
  );
};
