import {
  Drawer as MaterialDrawer,
  Divider as MaterialDivider,
  ListItemIcon,
  ListItem,
} from '@mui/material';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Logo = styled.img`
  && {
    height: 2.25rem;
    margin: 1rem 0.586rem;
  }
`;

export const drawerWidth = '16.25rem';

export const IconContainer = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.zml.blue800};
  min-width: 1.5rem;
  margin-right: 1rem;

  svg {
    .c,
    .a {
      fill: ${({ theme }) => theme.palette.zml.blue800};
    }
  }
`;

export const Drawer = styled(MaterialDrawer)`
  .MuiDrawer-paper {
    width: ${drawerWidth};
    box-shadow: 0 0.625rem 1.875rem -0.75rem rgb(0 0 0 / 42%),
      0 0.25rem 1.563rem 0 rgb(0 0 0 / 12%),
      0 0.5rem 0.625rem -0.313rem rgb(0 0 0 / 20%);
  }

  &&& {
    * {
      font-size: 0.938rem;
    }
  }

  .active {
    ${({ theme }) => theme.palette.gradient};
    color: ${({ theme }) => theme.palette.zml.white};
    box-shadow: 0px 7px 10px ${({ theme }) => theme.palette.primary.main};
    transition: all 300ms linear;

    ${IconContainer} svg {
      color: ${({ theme }) => theme.palette.zml.white};
    }

    :hover {
      ${({ theme }) => theme.palette.gradient};
    }

    svg {
      .c,
      .a {
        fill: ${({ theme }) => theme.palette.zml.white};
      }
    }
  }
`;

export const DrawerContent = styled.div`
  padding: 1rem;
`;

export const Divider = styled(MaterialDivider)`
  border-color: ${({ theme }) => theme.palette.zml.grey100};
`;

export const NavLink = styled(RouterNavLink)`
  border-radius: 0.1875rem;
  color: ${({ theme }) => theme.palette.zml.blue800};

  & + & {
    margin-top: 1rem;
  }
`;

export const ListItemStyled = styled(ListItem).attrs({
  button: true,
} as KeyValue)`
  padding: 0.625rem;
`;
