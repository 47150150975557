import { IconButton } from '@mui/material';
import styled from 'styled-components';

import { TableRow } from 'components/Table';

export const IconButtonStyled = styled(IconButton)`
  svg {
    color: ${({ theme }) => theme.palette.primary.main};
    filter: brightness(0.85);
    height: 1.5rem;
  }
`;

export const TableRowStyled = styled(TableRow)`
  td {
    height: 3.531rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
  }
`;
