export const capitalize = (text: string) => {
  return text
    .toLowerCase()
    .split(' ')
    .map(char => char.charAt(0).toUpperCase() + char.substring(1))
    .join(' ');
};

export const nameToInitials = (name: string) => {
  const nameArray = name.trim().split(' ');

  if (nameArray.length === 1) {
    const twoFirstLetters = nameArray[0].substr(0, 2).toUpperCase();
    return twoFirstLetters;
  }

  const firstLetter = nameArray[0].charAt(0);
  const lastLetter = nameArray[nameArray.length - 1].charAt(0);

  return firstLetter + lastLetter;
};
