import { createModel } from '@rematch/core';
import { getCompanyById } from 'repositories/companies';
import { AppTimezone } from 'services/timezone';
import { RootModel } from 'store';
import { currencyInstance } from 'utils/currency';

interface CompanyStore {
  currentCompany: Company | null;
  companies: Company[];
}

const initialState: CompanyStore = { currentCompany: null, companies: [] };

export const company = createModel<RootModel>()({
  state: initialState,
  reducers: {
    SET_CURRENT_COMPANY: (state, payload: Company | null) => {
      if (payload?.currency) currencyInstance.setCurrency(payload.currency);

      const appTimezone = AppTimezone.getInstance();

      if (payload?.contact?.region.timezoneId) {
        const { timezoneId } = payload.contact.region;

        appTimezone.setTimezoneId(timezoneId);
      } else {
        appTimezone.resetTimezoneId();
      }

      return {
        ...state,
        currentCompany: payload,
      };
    },
    UPDATE_COMPANY_DATA: (state, payload: Company) => {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          ...payload,
        },
      };
    },
    SET_COMPANIES: (state, payload: Company[]) => {
      if (state.currentCompany && payload.length) {
        const updatedCurrentCompany = payload.find(
          findCompany => findCompany.id === state.currentCompany?.id,
        );

        return {
          ...state,
          companies: payload,
          currentCompany: updatedCurrentCompany ?? state.currentCompany,
        };
      }

      return { ...state, companies: payload };
    },
  },
  effects: dispatch => ({
    async updateCurrentCompany(_, rootState): Promise<Company | undefined> {
      const { currentCompany } = rootState.company;

      if (!currentCompany) return;

      const updatedCompany = await getCompanyById(currentCompany.id);

      if (!updatedCompany) return;

      dispatch.company.UPDATE_COMPANY_DATA(updatedCompany);

      return updatedCompany;
    },
  }),
});
