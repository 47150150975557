import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from '@mui/material';

import { defaultTheme } from 'styles/defaultTheme';
import { GlobalStyle } from 'styles/global';
import { useTranslation } from 'hooks/translation';

import { Button } from 'components/Button';
import { Container, FrameContainer } from './FallbackUI.styled';
import { FixingBugs } from './FixingBugs';

export const FallbackUI = () => {
  const { t } = useTranslation('components');

  const onClick = () => {
    window.location.reload();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <StyledThemeProvider theme={defaultTheme}>
        <GlobalStyle />

        <Container>
          <FrameContainer>
            <FixingBugs />
          </FrameContainer>

          <p>{t('fallbackUI.message')}</p>

          <Button onClick={onClick}>{t('fallbackUI.button')}</Button>
        </Container>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};
