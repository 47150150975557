import { formatCurrency } from 'utils/currency';
import {
  formatDateToApp,
  getAutomatedBenefitFrequency,
  getI18nNamespace,
  parseDateFromServer,
} from 'utils/helpers';

const invalidStatuses: Array<AutomatedBenefit['status']> = [
  'EXPIRED',
  'STOPPED',
  'DELETED',
];

export const automatedBenefitAdapter = (
  benefit: AutomatedBenefit,
): AdaptedAutomatedBenefit => {
  const componentsTranslator = getI18nNamespace('components');

  const intervalLabel: Record<AutomatedBenefitInterval, string> = {
    once: componentsTranslator('automatedBenefitInterval.once'),
    monthly: componentsTranslator('automatedBenefitInterval.monthly'),
    monthly_15th: componentsTranslator('automatedBenefitInterval.monthly15th'),
    monthly_eom: componentsTranslator('automatedBenefitInterval.monthlyEOM'),
    annually: componentsTranslator('automatedBenefitInterval.annually'),
    quarterly: componentsTranslator('automatedBenefitInterval.quarterly'),
  };

  const parsedExpiresAt = parseDateFromServer(benefit.schedule?.expiresAt);
  const parsedStartsAt = parseDateFromServer(benefit.schedule?.startsAt);

  const interval = getAutomatedBenefitFrequency(benefit.schedule);

  return {
    ...benefit,
    description: benefit.description || '',
    interval,
    formatted: {
      startsAt: formatDateToApp(parsedStartsAt),
      expiresAt: formatDateToApp(parsedExpiresAt) || '-',
      nextExecutionAt: formatDateToApp(benefit.nextExecutionAt),
      amount: formatCurrency(benefit.amount / 100),
      interval: intervalLabel[interval] ?? '',
      isInvalidStatus: invalidStatuses.includes(benefit.status),
    },
  };
};
