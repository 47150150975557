import { Snackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

const threeSeconds = 3000;

export const SnackbarAlert = () => {
  const alert = useSelector((state: RootState) => state.alert);
  const dispatch = useDispatch<Dispatch>();

  const handleClose = () => {
    dispatch.alert.CLOSE_SNACKBAR();
  };

  return (
    <Snackbar
      open={alert.isSnackbarOpen}
      autoHideDuration={threeSeconds}
      onClose={handleClose}
      data-testid="snackbar-alert"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={alert.alertDialogType}
      >
        {alert.snackbarMessage}
      </Alert>
    </Snackbar>
  );
};
