import styled from 'styled-components';
import { Button } from 'components/Button';

export const Form = styled.form`
  padding: 0 1rem 1rem 1rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2.5rem 1.5rem 0 1.5rem;
`;

export const ButtonStyled = styled(Button)`
  width: 75%;
`;
