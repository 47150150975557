import { formatCurrency } from 'utils/currency';
import {
  formatDateAndTimeToApp,
  parseDateFromServer,
  resolveGiftcardTransactionType,
  resolveGiftcardType,
} from 'utils/helpers';

export const giftcardTransactionAdapter = (
  transaction: GiftcardTransaction,
): AdaptedGiftcardTransaction => {
  const { confirmedAt, amount, type, context } = transaction;
  const { giftcardType, giftcardContext } = context;
  const typeName = resolveGiftcardTransactionType({ type });
  const giftcardTypeName = resolveGiftcardType({
    type: giftcardType,
    contextName: giftcardContext,
  });

  return {
    ...transaction,
    formatted: {
      confirmedAt: formatDateAndTimeToApp(parseDateFromServer(confirmedAt)),
      amount: formatCurrency(amount / 100),
      type: typeName,
      giftcardType: giftcardTypeName,
    },
  };
};
