import urlcat from 'urlcat';
import { AxiosProgressEvent } from 'axios';
import { api } from 'services/api';
import { StoreEndpoints } from 'utils/endpoints';

interface CreateStoreParams {
  retailerId: string;
  name: string;
  addressStreet: string;
  addressCity: string;
  addressZipcode: string;
  category: string;
}

export const createStore = async (params: CreateStoreParams) => {
  const response = await api.post<Store>(StoreEndpoints.CREATE_STORE, params);

  return response.data;
};

interface GetStoreByIdParams<N extends boolean, FG extends boolean> {
  storeId: string;
  resolveNetworks?: N;
  resolveFeesGroups?: FG;
}

type ResolveStoreNetwork<N extends boolean, FG extends boolean> = N extends true
  ? FG extends true
    ? Array<StoreNetwork & Network & { feesGroup?: FeesGroup }>
    : Array<StoreNetwork & Network>
  : FG extends true
  ? Array<StoreNetwork & { feesGroup?: FeesGroup }>
  : Array<StoreNetwork>;

export interface GetStoreByIdResponse<
  N extends boolean = false,
  FG extends boolean = false,
> extends Store {
  networks: ResolveStoreNetwork<N, FG>;
}

export const getStoreById = async <
  N extends boolean = false,
  FG extends boolean = false,
>(
  params: GetStoreByIdParams<N, FG>,
) => {
  const { storeId, resolveNetworks, resolveFeesGroups } = params;

  const url = urlcat(StoreEndpoints.GET_STORE, { storeId });

  const response = await api.get<GetStoreByIdResponse<N, FG>>(url, {
    params: { resolveNetworks, resolveFeesGroups },
  });

  return response.data;
};

interface UpdateStoreNetworkParams {
  storeId: string;
  networkId: string;
  isActive: boolean;
  canRedeem: boolean;
  canActivate: boolean;
  ghostModeForRedeems: boolean;
  ghostModeForActivations: boolean;
  metadata: Record<string, unknown>;
}

export const updateStoreNetwork = async (params: UpdateStoreNetworkParams) => {
  const { storeId, networkId, ...data } = params;

  const url = urlcat(StoreEndpoints.EDIT_STORE, {
    storeId,
    networkId,
  });

  const response = await api.put<StoreNetwork>(url, data);

  return response.data;
};

interface UpdateStoreProfilePermissionParams {
  storeId: string;
  networkId: string;
  status: Exclude<StoreNetwork['profilePermission'], 'PENDING'>;
  token?: string;
}

export const updateStoreProfilePermission = async (
  params: UpdateStoreProfilePermissionParams,
) => {
  const { storeId, networkId, status, token } = params;

  const url = urlcat(StoreEndpoints.UPDATE_STORE_PROFILE_PERMISSION, {
    storeId,
    networkId,
  });

  const response = await api.put<StoreNetwork>(url, { status, token });

  return response.data;
};

interface UpdateStoreMarketplaceSettingsParams {
  storeId: string;
  marketplacesEnabled: boolean;
}

export const updateStoreMarketplaceSettings = async (
  params: UpdateStoreMarketplaceSettingsParams,
) => {
  const { storeId, marketplacesEnabled } = params;

  const url = urlcat(StoreEndpoints.UPDATE_STORE_MARKETPLACE_SETTINGS, {
    storeId,
  });

  const response = await api.put<Store>(url, { marketplacesEnabled });

  return response.data;
};

interface UpdateStoreTitleImageParams {
  storeId: string;
  image: string;
  onUploadProgress?(event: AxiosProgressEvent): void;
}

export const updateStoreTitleImage = async (
  params: UpdateStoreTitleImageParams,
) => {
  const { storeId, image, onUploadProgress } = params;

  const url = urlcat(StoreEndpoints.UPDATE_STORE_TITLE_IMAGE, {
    storeId,
  });

  const response = await api.put<{ url: string } | undefined>(
    url,
    {
      image,
    },
    { onUploadProgress },
  );

  return response.data;
};

export const deleteStoreTitleImage = async (storeId: string) => {
  const url = urlcat(StoreEndpoints.DELETE_STORE_TITLE_IMAGE, {
    storeId,
  });

  const response = await api.delete<{ success: 'OK' } | undefined>(url);

  return response.data;
};

interface UpdateStoreLogoImageParams {
  storeId: string;
  image: string;
  onUploadProgress?(event: AxiosProgressEvent): void;
}

export const updateStoreLogoImage = async (
  params: UpdateStoreLogoImageParams,
) => {
  const { storeId, image, onUploadProgress } = params;

  const url = urlcat(StoreEndpoints.UPDATE_STORE_LOGO_IMAGE, {
    storeId,
  });

  const response = await api.put<{ url: string } | undefined>(
    url,
    {
      image,
    },
    { onUploadProgress },
  );

  return response.data;
};

export const deleteStoreLogoImage = async (storeId: string) => {
  const url = urlcat(StoreEndpoints.DELETE_STORE_LOGO_IMAGE, {
    storeId,
  });

  const response = await api.delete<{ success: 'OK' } | undefined>(url);

  return response.data;
};

export interface UpdateStoreParams extends StoreSocialMedia {
  storeId: string;
  name: string;
  addressStreet: string;
  addressCity: string;
  addressZipcode: string;
  categoryId: string;
  secondaryCategoryId?: string;
  description?: string;
  tags?: string;
  www?: string;
  email?: string;
  phone?: string;
}

export const updateStore = async (params: UpdateStoreParams) => {
  const { storeId, ...body } = params;

  const url = urlcat(StoreEndpoints.UPDATE_STORE, {
    storeId,
  });

  const response = await api.put<Store | undefined>(url, body);

  return response.data;
};

interface CreateStorePicturesParams {
  storeId: string;
  images: string[];
  onUploadProgress?(event: AxiosProgressEvent): void;
}

export const createStorePictures = async (
  params: CreateStorePicturesParams,
) => {
  const { storeId, images, onUploadProgress } = params;

  const url = urlcat(StoreEndpoints.CREATE_STORE_PICTURES, {
    storeId,
  });

  const response = await api.post<ZmyleImage[]>(
    url,
    {
      images,
    },
    { onUploadProgress, timeout: 60 * 1000 },
  );

  return response.data;
};

interface DeleteStorePictureParams {
  storeId: string;
  pictureId: string;
}

export const deleteStorePicture = async (params: DeleteStorePictureParams) => {
  const { storeId, pictureId } = params;

  const url = urlcat(StoreEndpoints.DELETE_STORE_PICTURE, {
    storeId,
    pictureId,
  });

  const response = await api.delete<{ success: 'OK' } | undefined>(url);

  return response.data;
};

interface UpdateStorePicturesOrderParams {
  storeId: string;
  order: string[];
}

export const updateStorePicturesOrder = async (
  params: UpdateStorePicturesOrderParams,
) => {
  const { storeId, order } = params;

  const url = urlcat(StoreEndpoints.UPDATE_STORE_PICTURES_ORDER, {
    storeId,
  });

  const response = await api.put<ZmyleImage[]>(url, {
    order,
  });

  return response.data;
};

interface DeleteStoreFromNetworkParams {
  storeId: string;
  networkId: string;
}

export const deleteStoreFromNetwork = async (
  params: DeleteStoreFromNetworkParams,
) => {
  const { storeId, networkId } = params;

  const url = urlcat(StoreEndpoints.DELETE_STORE_FROM_NETWORK, {
    storeId,
    networkId,
  });

  const response = await api.delete<{ success: 'OK' }>(url);

  return response.data;
};
