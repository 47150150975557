import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;

  > button {
    margin-left: auto;
  }

  ${MOBILE_BREAKPOINT} {
    flex-direction: column;
    align-items: flex-start;

    > button {
      margin-top: 1.5rem;
    }
  }
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 2rem;

  button {
    padding-inline: 3rem;
    color: ${({ theme }) => theme.palette.zml.white};
  }
`;
