import { CircularProgress, ThemeProvider } from '@mui/material';
import { useLocation } from 'react-router';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { BackdropStyled } from 'components/LoadingScreen/LoadingScreen.styled';
import { isCompany, isNetwork, isRetailer } from 'utils/paths';
import { useBackOfficeTheme } from 'hooks/theme';
import { RootState } from 'store';

export const SuspenseLoading = () => {
  const { pathname } = useLocation();

  const isPage = Boolean(
    isCompany(pathname) || isRetailer(pathname) || isNetwork(pathname),
  );
  const isLoading = useSelector((state: RootState) => state.loading);

  const { theme } = useBackOfficeTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <BackdropStyled
          $isLoading={{ screen: !isPage, page: isPage }}
          open={!isLoading.screen && !isLoading.page}
          transitionDuration={{
            appear: isPage ? 0 : undefined,
          }}
        >
          <CircularProgress color="primary" size="2.5rem" />
        </BackdropStyled>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};
