import { Models } from '@rematch/core';
import { app } from './models/app';
import { auth } from './models/auth';
import { loading } from './models/loading';
import { giftcard } from './models/giftcard';
import { retailer } from './models/retailer';
import { alert } from './models/alert';
import { company } from './models/company';
import { network } from './models/network';
import { theme } from './models/theme';
import { preferences } from './models/preferences';

export interface RootModel extends Models<RootModel> {
  app: typeof app;
  auth: typeof auth;
  loading: typeof loading;
  giftcard: typeof giftcard;
  retailer: typeof retailer;
  alert: typeof alert;
  company: typeof company;
  network: typeof network;
  theme: typeof theme;
  preferences: typeof preferences;
}

export const models: RootModel = {
  app,
  auth,
  loading,
  giftcard,
  retailer,
  alert,
  company,
  network,
  theme,
  preferences,
};
