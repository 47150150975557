import styled from 'styled-components';
import { WizardDialog } from 'components/WizardDialog';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

export const WizardDialogStyled = styled(WizardDialog)`
  div.MuiDialog-paper {
    width: 28.188rem;
    min-height: 20.875rem;
  }
`;

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 16.5rem;
  height: 100%;
  width: 100%;

  padding: 1rem;
  padding-top: 0;

  > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  ${MOBILE_BREAKPOINT} {
    height: 80vh;

    > form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;
  margin-bottom: 0;
  padding-top: 1.25rem;
  width: 15.125rem;

  ${MOBILE_BREAKPOINT} {
    margin-top: auto;
  }
`;
