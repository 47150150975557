import { FC, lazy } from 'react';
import { dispatchManualError } from './errors';

type ImportFn = () => Promise<{ default: FC<any> }>;

const pageRefreshKey = 'page-has-been-force-refreshed';

const setWasPageRefreshed = (value: boolean) => {
  localStorage.setItem(pageRefreshKey, String(value));
};

const getWasPageRefreshed = (): boolean => {
  const value = localStorage.getItem(pageRefreshKey);
  return value ? JSON.parse(value) : false;
};

export const lazyWithRetry = (importFn: ImportFn) => {
  return lazy(async () => {
    try {
      const component = await importFn();

      setWasPageRefreshed(false);

      return component;
    } catch (error) {
      const pageHasAlreadyBeenForceRefreshed = getWasPageRefreshed();

      if (pageHasAlreadyBeenForceRefreshed) {
        dispatchManualError({
          error,
          tags: { pageHasAlreadyBeenForceRefreshed },
        });
      } else {
        setWasPageRefreshed(true);
        window.location.reload();
      }
    }

    return { default: () => null };
  });
};
