import { Slide, SlideProps } from '@mui/material';
import { forwardRef, ReactElement, Ref } from 'react';

interface Props {
  children?: ReactElement<any, any>;
}

export const Transition = forwardRef(
  (props: SlideProps & Props, ref: Ref<unknown>) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);
