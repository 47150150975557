import { TableCell } from '@mui/material';
import styled, { css } from 'styled-components';

interface TableCellStyledProps {
  $isCancelled?: boolean;
}

export const TableCellStyled = styled(TableCell)<TableCellStyledProps>`
  ${({ $isCancelled }) =>
    $isCancelled &&
    css`
      text-decoration: line-through;
    `}
`;
