import { DialogContentText, IconButton } from '@mui/material';
import styled from 'styled-components';

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;

  > svg {
    min-height: 1.5rem;
    min-width: 1.5rem;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const DialogTitleText = styled.span`
  padding-left: 1rem;
`;

export const DialogHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DialogContentTextStyled = styled(DialogContentText).attrs({
  component: 'div',
})`
  padding: 0.5rem 0 1rem 0;
  color: ${({ theme }) => theme.palette.zml.blue800};
`;

export const CloseDialogButton = styled(IconButton)`
  padding: 0.5rem;

  svg {
    margin: 0;
    color: ${({ theme }) => theme.palette.zml.blue800};
  }
`;
