import { getI18nNamespace } from './language';
import { capitalize } from './string';

interface ResolveGiftcardType {
  type: GiftcardType;
  contextName: string;
}

export const resolveGiftcardType = (params: ResolveGiftcardType): string => {
  const { type, contextName } = params;

  const t = getI18nNamespace('components');

  const giftcardTypes = t('giftcardTypes', {
    returnObjects: true,
    disableParentElement: true,
  });

  type GiftcardTypeNames = Extract<GiftcardType, 1000 | 2000 | 3000>;

  const giftcardTypesName: Record<GiftcardTypeNames, string> = {
    1000: giftcardTypes['1000'],
    2000: giftcardTypes['2000'],
    3000: giftcardTypes['3000'],
  };

  return giftcardTypesName[type] || contextName;
};

interface ResolveGiftcardTransactionType {
  type: GiftcardTransactionType;
}

export const resolveGiftcardTransactionType = (
  params: ResolveGiftcardTransactionType,
) => {
  const { type } = params;

  const t = getI18nNamespace('components');

  const transactionTypes = t('transactionTypes', {
    returnObjects: true,
    disableParentElement: true,
  });

  const transactionTypesName: Record<GiftcardTransactionType, string> = {
    CREDIT: transactionTypes.credit,
    DEBIT: transactionTypes.debit,
    CANCELLATION: transactionTypes.cancellation,
    DEPRECIATION: transactionTypes.depreciation,
    REFUND: transactionTypes.refund,
  };

  return transactionTypesName[type] || capitalize(type);
};
