const truthyValues = ['true', '1', 'yes', 'ok'];
const resolveBooleanEnv = (env?: string) => {
  if (env) return truthyValues.indexOf(env.toLowerCase()) >= 0;
  return false;
};

export const config = {
  isDevelopment: import.meta.env.DEV,
  isProduction: import.meta.env.PROD,
  networkHostname: import.meta.env.REACT_APP_NETWORK_HOSTNAME,
  idempotentTotalTimeoutSeconds: Number(
    window.zmyle?.ZMYLE_REACT_APP_IDEMPOTENT_TOTAL_TIMEOUT_SECONDS ||
      import.meta.env.REACT_APP_IDEMPOTENT_TOTAL_TIMEOUT_SECONDS ||
      60,
  ),
  rootHostname:
    window.zmyle?.ZMYLE_REACT_APP_ROOT_HOSTNAME ||
    import.meta.env.REACT_APP_ROOT_HOSTNAME,
  middlewareApiRootUrl: (
    window.zmyle?.ZMYLE_REACT_APP_WEB_API ||
    import.meta.env.REACT_APP_WEB_API ||
    `${window.location.protocol}//${window.location.host}/ui_api/v1.1`
  ).replace(/\/$/, ''),
  appSignalKey:
    window.zmyle?.ZMYLE_REACT_APP_APP_SIGNAL_KEY ||
    import.meta.env.REACT_APP_APP_APP_SIGNAL_KEY,
  accounts: {},
  stores: {},
  benefits: {
    disableFrequencyOnce:
      resolveBooleanEnv(window.zmyle?.ZMYLE_REACT_APP_DISABLE_FREQUENCY_ONCE) ||
      resolveBooleanEnv(import.meta.env.REACT_APP_APP_DISABLE_FREQUENCY_ONCE),
  },
  networks: {
    enableGiftcardsStatistics:
      resolveBooleanEnv(
        window.zmyle?.ZMYLE_REACT_APP_NETWORKS_GIFTCARDS_STATS_ENABLED,
      ) ||
      resolveBooleanEnv(
        import.meta.env.REACT_APP_NETWORKS_GIFTCARDS_STATS_ENABLED,
      ),
    materialLink:
      window.zmyle?.ZMYLE_REACT_APP_NETWORKS_MARKETING_MATERIAL_LINK ||
      import.meta.env.REACT_APP_NETWORKS_MARKETING_MATERIAL_LINK,
  },
};
