import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Dialog, IconButton, LinearProgress } from '@mui/material';
import { MdClose } from 'react-icons/md';
import { Dispatch, RootState } from 'store';
import { Icon } from 'components/Icon';
import { Typography } from 'components/Typography';
import { Transition } from './Transition/Transition';
import { AlertStyled, Container } from './AlertDialog.styled';

const oneSecond = 1000;
const twentySeconds = oneSecond * 20;

let countdownTimer: NodeJS.Timeout;

export const AlertDialog = () => {
  const [closeCountdown, setCloseCountdown] = useState(0);

  const percentCountdown = Math.round((closeCountdown * 100) / twentySeconds);

  const { isAlertDialogOpen, alertDialogType, alertDialogMessage } =
    useSelector((state: RootState) => state.alert);

  const dispatch = useDispatch<Dispatch>();

  const closePopup = () => {
    dispatch.alert.CLOSE_ALERT_DIALOG();
    clearTimeout(countdownTimer);
  };

  useEffect(() => {
    if (isAlertDialogOpen && closeCountdown === 0) {
      closePopup();
      return;
    }

    if (!isAlertDialogOpen || closeCountdown === 0) return;

    countdownTimer = setTimeout(() => {
      setCloseCountdown(closeCountdown - oneSecond);
    }, oneSecond);

    return () => clearTimeout(countdownTimer);
  }, [closeCountdown]);

  useEffect(() => {
    if (isAlertDialogOpen) setCloseCountdown(twentySeconds);
  }, [isAlertDialogOpen]);

  return (
    <Dialog
      open={isAlertDialogOpen}
      onClose={closePopup}
      TransitionComponent={Transition}
      fullWidth
      data-testid="alert-dialog"
    >
      <AlertStyled severity={alertDialogType}>
        <Container>
          <IconButton onClick={closePopup} size="large">
            <Icon icon={MdClose} size="1.2rem" />
          </IconButton>

          <Typography whiteSpace="pre-line">{alertDialogMessage}</Typography>
        </Container>
      </AlertStyled>

      <LinearProgress
        color="primary"
        variant="determinate"
        value={percentCountdown}
      />
    </Dialog>
  );
};
