import { createTheme } from '@mui/material';
import { BenefitColors, NetworkColors } from './colors';

import { defaultThemeConfig, zmlColors } from './defaultTheme';
import { benefitGradient, networkGradient } from './gradient';

export const benefitTheme = createTheme({
  ...defaultThemeConfig,
  palette: {
    ...defaultThemeConfig,
    primary: {
      main: BenefitColors.primary,
    },
    zml: zmlColors,
    gradient: benefitGradient,
  },
});

export const networkTheme = createTheme({
  ...defaultThemeConfig,
  palette: {
    ...defaultThemeConfig,
    primary: {
      main: NetworkColors.primary,
    },
    zml: zmlColors,
    gradient: networkGradient,
  },
});
