import { CssBaseline } from '@mui/material';
import { GlobalStyle } from 'styles/global';
import { useGlobalObservers } from 'hooks/globalObservers';
import { Routes } from './routes/routes';

const App = () => {
  useGlobalObservers();

  return (
    <>
      <CssBaseline />
      <GlobalStyle />

      <Routes />
    </>
  );
};

export default App;
