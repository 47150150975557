import { captureException } from 'utils/errors';
import { getErrorsWhitelist } from 'repositories/download';

interface IErrorWhitelist {
  isErrorWhitelisted(errorId: string): Promise<boolean>;
}

export class ErrorWhitelist implements IErrorWhitelist {
  private static instance: IErrorWhitelist;

  private errorWhitelist: string[] = [];

  private updatedAt = 0;

  private failedAt = 0;

  private isUpdating = false;

  private expiresIn = 1000 * 60 * 60 * 24; // 24 hours

  private retryIn = 1000 * 60 * 60 * 1; // 1 hour

  private constructor() {
    this.updateErrorWhitelist();
  }

  public static getInstance(): IErrorWhitelist {
    if (!ErrorWhitelist.instance) {
      ErrorWhitelist.instance = new ErrorWhitelist();
    }

    return ErrorWhitelist.instance;
  }

  private shouldFetch(): boolean {
    if (this.failedAt) return Date.now() - this.failedAt > this.retryIn;

    return Date.now() - this.updatedAt > this.expiresIn;
  }

  isErrorWhitelisted = async (errorId: string): Promise<boolean> => {
    if (this.shouldFetch()) await this.updateErrorWhitelist();

    return this.errorWhitelist.includes(errorId);
  };

  private async updateErrorWhitelist(): Promise<void> {
    try {
      if (this.isUpdating) return;

      this.isUpdating = true;

      const { errorWhitelist } = await getErrorsWhitelist();

      this.errorWhitelist = errorWhitelist;

      this.updatedAt = Date.now();

      this.isUpdating = false;
      this.failedAt = 0;
    } catch (error) {
      this.isUpdating = false;
      this.failedAt = Date.now();

      captureException({ error, tags: { method: 'updateErrorWhitelist' } });
    }
  }
}
