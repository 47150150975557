import styled from 'styled-components';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Forms/Checkbox';

export const Container = styled.span<{ variant: string }>`
  > form {
    padding: 1rem;

    div:nth-child(5) {
      label {
        padding-left: ${({ variant }) =>
          variant === 'outlined' ? '15px;' : ''};
      }
    }
  }
`;
export const RegisterStoreButton = styled(Button)`
  width: 75%;
  margin-top: 1rem;
`;

export const CheckboxStyled = styled(Checkbox)`
  .MuiButtonBase-root {
    margin-block: -0.375rem auto;
  }

  margin-top: 1rem;
`;

export const HighlightLink = styled.a`
  transition: 0.2s;
  color: ${({ theme }) => theme.palette.primary.main};

  :hover {
    text-decoration: underline;
  }
`;
