import { MouseEvent, ReactNode } from 'react';
import { IconButtonProps } from '@mui/material';
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatStrikethrough,
} from 'react-icons/md';
import { IconButtonStyled } from 'components/RichText/RichText.styled';
import { Icon } from 'components/Icon';
import { ListMark, RichTextMark } from '../../@types';

interface Props extends Omit<IconButtonProps, 'onClick' | 'type'> {
  isActive?: boolean;
  isReversed?: boolean;
  children?: ReactNode;
  type: RichTextMark | ListMark;
  onClick?(param: RichTextMark | ListMark): void;
}

export const RichTextButton = (props: Props) => {
  const { children, type, onClick, isActive, ...rest } = props;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClick?.(type);
  };

  const getIcon = () => {
    const iconType: Record<Exclude<typeof type, 'list_item'>, JSX.Element> = {
      bold: <Icon icon={MdFormatBold} size="1.5rem" />,
      italic: <Icon icon={MdFormatItalic} size="1.5rem" />,
      strikethrough: <Icon icon={MdFormatStrikethrough} size="1.5rem" />,
      bullet: <Icon icon={MdFormatListBulleted} size="1.5rem" />,
      number: <Icon icon={MdFormatListNumbered} size="1.5rem" />,
    };

    return iconType[type] || null;
  };

  return (
    <IconButtonStyled {...rest} $isActive={isActive} onMouseDown={handleClick}>
      {children || getIcon()}
    </IconButtonStyled>
  );
};
