interface TeamMemberAdapter {
  teamMember: TeamMember;
  companyId?: string;
  retailerId?: string;
  networkId?: string;
}

export const teamMemberAdapter = (
  params: TeamMemberAdapter,
): AdaptedTeamMember => {
  const { teamMember, companyId, retailerId, networkId } = params;

  const companyPermission = teamMember.companies.find(
    company => company.companyId === companyId,
  );

  const retailerPermission = teamMember.retailers.find(
    retailer => retailer.retailerId === retailerId,
  );

  const networkPermission = teamMember.networks.find(
    network => network.networkId === networkId,
  );

  return {
    ...teamMember,
    permissions: {
      authRole:
        companyPermission?.authRole ??
        retailerPermission?.authRole ??
        networkPermission?.authRole,
    },
    formatted: {
      email: teamMember.emails[0].email,
    },
  };
};
