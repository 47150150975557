export const formatToNumber = (value: string) => {
  const parsedNumber = Number(value.replace(',', '.'));

  return Number.isNaN(parsedNumber) ? 0 : parsedNumber;
};

export const formatPercentage = (
  value: number,
  options?: Intl.NumberFormatOptions,
) => {
  const formattedValue = new Intl.NumberFormat('DE-de', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(value);

  if (process.env.NODE_ENV === 'test') {
    return formattedValue.replace(/\xa0/g, ' ').replace(/\u202f/g, ' ');
  }

  return formattedValue;
};

export const formatPercentageToNumber = (value: string) => {
  const unformattedValue = value.replace(/%/g, '');

  return formatToNumber(unformattedValue);
};

export const divideBy100 = (value: number | string): number => {
  return Number(value) / 100;
};

export const multiplyBy100 = (value: number | string): number => {
  return Number(value) * 100;
};

export const formatAmountToServer = (value: number | string) => {
  const multipliedValue = (Number(value) * 100).toFixed();

  return parseInt(multipliedValue, 10);
};
