import { TooltipProps } from '@mui/material';
import { ReactElement } from 'react';
import { MdError } from 'react-icons/md';
import { Icon } from 'components/Icon';
import { useDisclosure } from 'hooks/disclosure';

import { TooltipStyled, IconContainer } from './TooltipError.styled';

interface Props extends Partial<TooltipProps> {
  children?: ReactElement;
  error?: string;
  open?: boolean;
  showOnHover?: boolean;
}

export const TooltipError = (props: Props) => {
  const {
    error: errorText,
    children,
    open,
    showOnHover = true,
    ...rest
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure({
    initialValue: Boolean(open),
  });

  const handleOpen = () => {
    if (showOnHover) onOpen();
  };

  const handleClose = () => {
    if (showOnHover) onClose();
  };

  const hasError = Boolean(errorText);

  return (
    <>
      {hasError && (
        <TooltipStyled
          title={String(errorText)}
          placement="bottom-end"
          open={isOpen}
          arrow
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          data-testid="error-icon-tooltip"
          {...rest}
        >
          {children ?? (
            <IconContainer>
              <Icon icon={MdError} size="1.5rem" />
            </IconContainer>
          )}
        </TooltipStyled>
      )}

      {!hasError && children}
    </>
  );
};
