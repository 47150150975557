import { ReactNode } from 'react';
import { DialogContent } from '@mui/material';
import { useResponsiveness } from 'hooks/responsiveness';
import { WizardDialogHeader } from './components/WizardDialogHeader';
import { DialogStyled } from './WizardDialog.styled';

interface Props {
  title?: ReactNode;
  steps?: ReactNode[];
  isOpen: boolean;
  currentStep?: number;
  goBack?(): void;
  onClickClose?: (success: boolean) => void;
  hideHeader?: boolean;
  firstStep?: 1 | 0;
  size?: 'md' | 'xl';
  fullScreenOnMobile?: boolean;
  children?: ReactNode;
  closeOnClickAway?: boolean;
  onExited?(): void;
}

export const WizardDialog = (props: Props) => {
  const {
    title,
    steps,
    currentStep,
    isOpen,
    goBack,
    onClickClose,
    hideHeader,
    firstStep = 1,
    size,
    fullScreenOnMobile = true,
    children,
    closeOnClickAway,
    onExited,
    ...rest
  } = props;

  const { isMobile } = useResponsiveness();

  const showFullScreen = fullScreenOnMobile && isMobile;

  const hasPreviousStep = currentStep ? currentStep > firstStep : false;

  const currentStepIndex = currentStep
    ? currentStep - firstStep
    : firstStep - firstStep;

  return (
    <DialogStyled
      open={isOpen}
      $size={size}
      fullScreen={showFullScreen}
      onClose={closeOnClickAway && onClickClose ? onClickClose : undefined}
      TransitionProps={{ onExited }}
      BackdropProps={
        {
          'data-testid': 'wizard-dialog-backdrop',
        } as KeyValue
      }
      {...rest}
    >
      {!hideHeader && (
        <WizardDialogHeader
          title={title}
          hasPreviousStep={hasPreviousStep && Boolean(goBack)}
          onClickClose={onClickClose ? () => onClickClose?.(false) : undefined}
          goBack={goBack}
        />
      )}

      <DialogContent>{children || steps?.[currentStepIndex]}</DialogContent>
    </DialogStyled>
  );
};
