import { createModel } from '@rematch/core';
import type { RootModel } from '../model';

type AlertDialogType = 'error' | 'warning' | 'info' | 'success';

interface OpenAlertDialogPayload {
  alertDialogType: AlertDialogType;
  alertDialogMessage: string;
}

interface OpenSnackbarPayload {
  message: string;
  type: AlertDialogType;
}

interface AlertStore {
  isSnackbarOpen: boolean;
  snackbarMessage: string | null;
  isAlertDialogOpen: boolean;
  alertDialogMessage: string | null;
  alertDialogType: AlertDialogType;
}

export const alert = createModel<RootModel>()({
  state: {
    isSnackbarOpen: false,
    snackbarMessage: null,
    isAlertDialogOpen: false,
    alertDialogMessage: null,
    alertDialogType: 'info',
  } as AlertStore,
  reducers: {
    OPEN_SNACKBAR: (state, payload: OpenSnackbarPayload) => {
      const { message, type } = payload;

      return {
        ...state,
        isSnackbarOpen: true,
        snackbarMessage: message,
        alertDialogType: type,
      };
    },
    CLOSE_SNACKBAR: state => {
      return { ...state, isSnackbarOpen: false };
    },
    OPEN_SNACKBAR_SUCCESS: (state, payload: string) => {
      return {
        ...state,
        isSnackbarOpen: true,
        snackbarMessage: payload,
        alertDialogType: 'success',
      };
    },
    CLOSE_SNACKBAR_SUCCESS: state => {
      return {
        ...state,
        isSnackbarOpen: false,
        alertDialogType: 'info',
      };
    },
    OPEN_ALERT_DIALOG: (state, payload: OpenAlertDialogPayload) => {
      return {
        ...state,
        alertDialogType: payload.alertDialogType,
        alertDialogMessage: payload.alertDialogMessage,
        isAlertDialogOpen: true,
      };
    },
    CLOSE_ALERT_DIALOG: state => {
      return {
        ...state,
        isAlertDialogOpen: false,
      };
    },
  },
});
