export const getEmployeeLabel = (employee?: Employee | null) => {
  if (!employee) return '';

  if (employee?.name && employee?.staffNumber) {
    return `${employee?.name} (${employee.staffNumber})`;
  }

  if (employee?.name) return employee?.name;

  return employee?.staffNumber ?? '';
};

export const getAutomatedBenefitFrequency = (
  schedule?: AutomatedBenefit['schedule'],
): AutomatedBenefitInterval => {
  if (!schedule) return 'once';

  return schedule.frequency;
};
