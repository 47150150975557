import { api } from 'services/api';
import { CategoryEndpoints } from 'utils/endpoints';

export const getAllCategories = async () => {
  const url = CategoryEndpoints.GET_ALL_CATEGORIES;

  const response = await api.get<GetAllCategoriesResponse[]>(url);

  return response.data;
};
