import { Paper } from '@mui/material';
import styled, { css } from 'styled-components';

export const CardHeader = styled.div<{ hasDivider?: boolean }>`
  margin: 0.938rem;
  margin-bottom: 0;
  padding: 0;
  position: relative;

  ${({ hasDivider }) =>
    hasDivider &&
    css`
      hr {
        margin-top: 0.938rem;
      }
    `}
`;

export const CardTitle = styled.div`
  color: ${({ theme }) => theme.palette.zml.blue800};
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    font-size: 1.125rem;
  }
`;

export const Container = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  word-wrap: break-word;
  font-size: 0.875rem;

  border: 0;
  color: ${({ theme }) => theme.palette.zml.blue800};
  background: ${({ theme }) => theme.palette.zml.white};
  box-shadow: 0 0.063rem 0.25rem 0 rgb(0 0 0 / 14%);
  margin: 1.875rem 0;
  border-radius: 0.375rem;
`;

export const CardBody = styled.div`
  margin: 0.938rem;
`;
