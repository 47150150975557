import { Suspense, useEffect } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { lazyWithRetry } from 'utils/lazyWithRetry';
import { RootState } from 'store';
import paths from 'utils/paths';
import { SuspenseLoading } from 'components/LoadingScreen';
import { useNetwork } from 'hooks/network';
import { RedirectNotFound } from './RedirectNotFound';

const NetworkDashboard = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "network-dashboard" */ 'pages/Networks/NetworkDashboard'
    ),
);

const Stores = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "network-stores" */ 'pages/Networks/Stores'),
);

const StoreDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "network-store-details" */ 'pages/Networks/StoreDetails'
    ),
);

const StoreProfile = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "network-store-profile" */ 'pages/Networks/StoreProfile'
    ),
);

const Benefits = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "network-companies" */ 'pages/Networks/Benefits'
    ),
);

const Fees = lazyWithRetry(
  () => import(/* webpackChunkName: "network-fees" */ 'pages/Networks/Fees'),
);

const AccountStatements = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "network-statements" */ 'pages/Networks/AccountStatements'
    ),
);

const Analytics = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "network-analytics" */ 'pages/Networks/Analytics'
    ),
);

const Links = lazyWithRetry(
  () => import(/* webpackChunkName: "network-links" */ 'pages/Networks/Links'),
);

const BillingDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "network-billingDetails" */ 'pages/Networks/BillingDetails'
    ),
);

const Tech = lazyWithRetry(
  () => import(/* webpackChunkName: "network-tech" */ 'pages/Networks/Tech'),
);

const Team = lazyWithRetry(
  () => import(/* webpackChunkName: "network-team" */ 'pages/Networks/Team'),
);

const TeamMemberDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "network-team-member" */ 'pages/Networks/TeamMemberDetails'
    ),
);

const renderLoading = () => <SuspenseLoading />;

interface Params {
  networkId?: string;
}

export const NetworkRoutes = () => {
  const params = useParams<Params>();
  const history = useHistory();

  const isAppInitialized = useSelector(
    (state: RootState) => state.app.isInitialized,
  );
  const { network, networks, networkActions } = useNetwork();

  const shouldRenderManagerRoutes = network?.authRole === 'MANAGER';
  const shouldRenderAccountantRoutes = network?.authRole === 'ACCOUNTANT';

  useEffect(() => {
    if (!isAppInitialized) return;

    if (!params?.networkId || network?.id === params?.networkId) return;

    const _network = networks.find(
      findNetwork => findNetwork.id === params.networkId,
    );

    if (!_network) return history.replace(paths.DASHBOARD);

    networkActions.SET_CURRENT_NETWORK(_network);
  }, [params?.networkId, isAppInitialized]);

  return (
    <Suspense fallback={renderLoading()}>
      <Switch>
        <Route
          path={paths.NETWORK + paths.DASHBOARD}
          component={NetworkDashboard}
          exact
        />

        <Route path={paths.NETWORK + paths.STORES} component={Stores} exact />
        <Route
          path={paths.NETWORK + paths.STORE_DETAILS}
          component={StoreDetails}
          exact
        />
        <Route
          path={paths.NETWORK + paths.STORE_DETAILS + paths.PROFILE}
          component={StoreProfile}
          exact
        />

        <Route
          path={paths.NETWORK + paths.BENEFITS}
          component={Benefits}
          exact
        />

        <Route path={paths.NETWORK + paths.FEES} component={Fees} exact />

        <Route
          path={paths.NETWORK + paths.STATEMENTS}
          component={AccountStatements}
          exact
        />

        <Route
          path={paths.NETWORK + paths.ANALYTICS}
          component={Analytics}
          exact
        />

        {(shouldRenderAccountantRoutes || shouldRenderManagerRoutes) && (
          <Route
            path={paths.NETWORK + paths.BILLING_DETAILS}
            component={BillingDetails}
            exact
          />
        )}

        {shouldRenderManagerRoutes && (
          <Switch>
            <Route path={paths.NETWORK + paths.USERS} component={Team} exact />

            <Route
              path={paths.NETWORK + paths.USER_DETAILS}
              component={TeamMemberDetails}
              exact
            />

            <Route path={paths.NETWORK + paths.API} component={Tech} exact />

            <Route path={paths.NETWORK + paths.LINKS} component={Links} exact />
          </Switch>
        )}

        <Route path="*" component={RedirectNotFound} />
      </Switch>
    </Suspense>
  );
};
