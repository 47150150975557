import { useCallback, forwardRef, ForwardRefRenderFunction } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: '',
  suffix: ' %',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 2,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const PercentageInput: ForwardRefRenderFunction<
  HTMLElement,
  MaskedInputProps
> = (props, ref) => {
  const percentageMask = createNumberMask(defaultMaskOptions);

  const setRef = useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;

      if (typeof ref === 'function') {
        ref(value);
        return;
      }

      if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );

  return (
    <MaskedInput {...props} ref={setRef} mask={percentageMask} guide={false} />
  );
};

export const MaskedPercentageInput = forwardRef(PercentageInput);
