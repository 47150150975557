import zmyleLogo from 'assets/img/zmyle-logo.svg';
import { ProfilePopup } from './components/ProfilePopup';

import { Container, Logo } from './TopbarDashboard.styled';

export const TopbarDashboard = () => {
  return (
    <Container>
      <Logo src={zmyleLogo} alt="Zmyle logo" />

      <ProfilePopup />
    </Container>
  );
};
