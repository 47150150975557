import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import persistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';
import { RootModel, models } from './model';
import { initialState as networkInitialState } from './models/network';

export const persistKey = '@zmyle:persist';

const persistConfig = {
  key: persistKey,
  storage,
  blacklist: ['theme', 'loading', 'app'],
};

export const store = init<RootModel>({
  models,
  plugins: [persistPlugin(persistConfig)],
  redux: {
    rootReducers: {
      RESET_APP: state => {
        return {
          app: state?.app,
          theme: state?.theme,
          network: {
            ...networkInitialState,
            signedInNetwork: state?.network?.signedInNetwork,
          },
        };
      },
    },
  },
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
