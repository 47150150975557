import AppSignal from '@appsignal/javascript';
import { plugin as breadcrumbsNetworkPlugin } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as breadcrumbsConsolePlugin } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as windowEventsPlugin } from '@appsignal/plugin-window-events';
import { plugin as pathDecoratorPlugin } from '@appsignal/plugin-path-decorator';

import { shouldFilterError } from 'utils/errors';
import zmyleReleaseInfo from '../ZmyleReleaseInfo.js';
import { config } from './config';

interface SendErrorParams {
  error: Error;
  payload: Record<string, any>;
  tags?: KeyValue;
}

export class AppSignalClient {
  private appSignal: AppSignal;

  // eslint-disable-next-line no-use-before-define
  private static instance: AppSignalClient;

  private constructor() {
    const getAppSignalKey = () => {
      if (config.isDevelopment) return undefined;

      return config.appSignalKey;
    };

    this.appSignal = new AppSignal({
      key: getAppSignalKey(),
      revision: zmyleReleaseInfo.release ?? undefined,
    });

    this.appSignal.use(breadcrumbsNetworkPlugin());
    this.appSignal.use(breadcrumbsConsolePlugin());
    this.appSignal.use(windowEventsPlugin());
    this.appSignal.use(pathDecoratorPlugin());

    const bindOnError = window.onerror;

    window.onerror = async (
      event: Event | string,
      source?: string,
      lineno?: number,
      colno?: number,
      error?: Error,
    ) => {
      if (error && error.name.toLowerCase().includes('error')) {
        // eslint-disable-next-line no-param-reassign
        error.name = error.message;
      }

      const isFiltered = await shouldFilterError(error);

      if (!isFiltered && bindOnError)
        bindOnError(event, source, lineno, colno, error);
    };
  }

  public static init() {
    AppSignalClient.instance = new AppSignalClient();
  }

  public static getInstance() {
    if (!AppSignalClient.instance) AppSignalClient.init();

    return AppSignalClient.instance;
  }

  public getClient() {
    return this.appSignal;
  }

  public sendError(params: SendErrorParams) {
    const { error, payload, tags = null } = params;

    const span = this.appSignal.createSpan();

    if (error.name === 'Error') error.name = error.message;

    span.setError(error);
    span.setParams(payload);

    if (tags) span.setTags(tags);

    this.appSignal.send(span);
  }
}
