/* eslint-disable no-param-reassign */

import { AxiosError, AxiosInstance, AxiosStatic } from 'axios';
import axiosRetry from 'axios-retry';

import { ApiErrorId } from 'utils/api';
import { isZmyleApiError } from 'utils/errors';
import { config } from './config';

const minRetryTimeout = 1000 * 15; // 15s
const totalRetryTimeout = config.idempotentTotalTimeoutSeconds * 1000;

const getIdempotencyKeyFromRequest = (error: AxiosError) => {
  if (!error.config?.data) return '';

  const data = JSON.parse(error.config.data || '{}') as {
    idempotencyKey?: string;
  };

  return data.idempotencyKey || '';
};

const retryQueue: Record<string, number> = {};

const shouldRetry = (idempotencyKey: string) => {
  const firstRetryTime = retryQueue[idempotencyKey];

  if (!firstRetryTime) return false;

  const canRetry = Date.now() - firstRetryTime < totalRetryTimeout;

  if (!canRetry) delete retryQueue[idempotencyKey];

  return canRetry;
};

export const setupIdempotentRetry = (zmyleApi: AxiosStatic | AxiosInstance) => {
  return axiosRetry(zmyleApi, {
    retries: 1,
    shouldResetTimeout: true,
    retryDelay: axiosRetry.exponentialDelay,
    onMaxRetryTimesExceeded: error => {
      const idempotencyKey = getIdempotencyKeyFromRequest(error);

      if (retryQueue[idempotencyKey]) delete retryQueue[idempotencyKey];
    },
    retryCondition(error) {
      if (error.message.includes('timeout') && error.config) {
        const idempotencyKey = getIdempotencyKeyFromRequest(error);

        if (!idempotencyKey || !error.config['axios-retry']?.retries) {
          return false;
        }

        error.config.timeout = minRetryTimeout;

        if (!retryQueue[idempotencyKey]) {
          retryQueue[idempotencyKey] = Date.now();
          error.config['axios-retry'].retries += 1;

          return true;
        }

        if (shouldRetry(idempotencyKey)) {
          error.config['axios-retry'].retries += 1;

          return true;
        }

        return false;
      }

      if (!error.response || !isZmyleApiError(error.response?.data)) {
        return false;
      }

      const { errorId } = error.response.data;

      if (
        errorId !== ApiErrorId.IDEMPOTENT_REQUEST_BUSY ||
        !error.config ||
        !error.config['axios-retry']?.retries
      ) {
        return false;
      }

      const idempotencyKey = getIdempotencyKeyFromRequest(error);

      if (shouldRetry(idempotencyKey)) {
        error.config['axios-retry'].retries += 1;

        return true;
      }

      if (!retryQueue[idempotencyKey]) {
        error.config.timeout = minRetryTimeout;
        retryQueue[idempotencyKey] = Date.now();
        error.config['axios-retry'].retries += 1;

        return true;
      }

      return false;
    },
  });
};
