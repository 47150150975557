import { ClickAwayListener, IconButton, Paper } from '@mui/material';
import { MouseEvent, useState, useEffect } from 'react';
import { IoMdPerson } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'hooks/translation';
import paths from 'utils/paths';
import { Dispatch } from 'store';
import { Icon } from 'components/Icon';
import { Grow, MenuList, MenuItem, Popper } from '../Topbar.styled';

export const ProfilePopup = () => {
  const [showMyProfile, setShowMyProfile] = useState(false);
  const [showMyAccounts, setShowMyAccounts] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { pathname } = useLocation();

  const handleClickPopper = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClosePopper = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation('components');
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();

  const handleSignOut = async () => {
    await dispatch.auth.signOut();
  };

  const handleGoToProfile = () => {
    history.push(paths.PROFILE);
    handleClosePopper();
  };

  const handleGoToDashboard = () => {
    history.push(paths.DASHBOARD);
    handleClosePopper();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popper' : undefined;

  useEffect(() => {
    setShowMyProfile(pathname !== paths.PROFILE);
    setShowMyAccounts(pathname !== paths.DASHBOARD);
  }, [open]);

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClickPopper}
        size="large"
      >
        <Icon icon={IoMdPerson} size="1.5rem" />
      </IconButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList role="menu">
                  {showMyProfile && (
                    <MenuItem onClick={handleGoToProfile}>
                      {t('topbar.myProfile')}
                    </MenuItem>
                  )}

                  {showMyAccounts && (
                    <MenuItem onClick={handleGoToDashboard}>
                      {t('topbar.myAccounts')}
                    </MenuItem>
                  )}

                  <MenuItem onClick={handleSignOut}>
                    {t('topbar.logout')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
