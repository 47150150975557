import { useLocation } from 'react-router-dom';
import { useTranslation } from 'hooks/translation';
import {
  isAccountsDashboard,
  isCompany,
  isNetwork,
  isRetailer,
  isUserProfile,
  ZmyleLinks,
} from 'utils/paths';

import { Container, List, TypographyStyled } from './Footer.styled';

export const Footer = () => {
  const { pathname } = useLocation();

  const { t } = useTranslation('components');

  const getHelpLink = () => {
    if (isCompany(pathname)) return ZmyleLinks.BENEFIT_SUPPORT;

    if (isRetailer(pathname)) return ZmyleLinks.STORE_SUPPORT;

    if (isNetwork(pathname)) return ZmyleLinks.NETWORK_SUPPORT;

    if (isUserProfile(pathname)) return ZmyleLinks.PROFILE_SUPPORT;

    if (isAccountsDashboard(pathname)) {
      return ZmyleLinks.ACCOUNT_DASHBOARD_SUPPORT;
    }

    return ZmyleLinks.SUPPORT;
  };

  return (
    <Container>
      <List>
        <li>
          <a href={ZmyleLinks.ZMYLE} target="_blank" rel="noreferrer">
            <TypographyStyled>{t('footer.zmyle')}</TypographyStyled>
          </a>
        </li>

        <li>
          <a href={ZmyleLinks.LEGAL} target="_blank" rel="noreferrer">
            <TypographyStyled>{t('footer.legalInfo')}</TypographyStyled>
          </a>
        </li>

        <li>
          <a href={ZmyleLinks.PRIVACY_POLICY} target="_blank" rel="noreferrer">
            <TypographyStyled>{t('footer.privacyPolicy')}</TypographyStyled>
          </a>
        </li>

        <li>
          <a href={getHelpLink()} target="_blank" rel="noreferrer">
            <TypographyStyled>{t('footer.help')}</TypographyStyled>
          </a>
        </li>
      </List>
    </Container>
  );
};
