import { formatCurrency } from 'utils/currency';
import { formatDateToApp } from 'utils/helpers';

export const networkAnalyticsAdapter = (
  data: NetworkAnalytics,
): AdaptedNetworkAnalytics => {
  const {
    giftcardStats,
    storesCreditsStats,
    storesDebitsStats,
    companiesStats,
  } = data;

  return {
    ...data,
    formatted: {
      fromDate: formatDateToApp(data.fromDate),
      toDate: formatDateToApp(data.toDate),
    },
    companiesStats: companiesStats.map(companyStats => {
      const { credits } = companyStats;

      return {
        ...companyStats,
        formatted: {
          credits: { total: { sum: formatCurrency(credits.total.sum / 100) } },
        },
      };
    }),
    storesCreditsStats: storesCreditsStats.map(storeStats => {
      const { credits } = storeStats;

      return {
        ...storeStats,
        formatted: {
          credits: {
            total: { sum: formatCurrency(credits.total.sum / 100) },
            dynamic: { sum: formatCurrency(credits.dynamic.sum / 100) },
          },
          debits: {
            sum: formatCurrency(storeStats.debits.sum / 100),
          },
        },
      };
    }),
    storesDebitsStats: storesDebitsStats.map(storeStats => {
      const { credits } = storeStats;

      return {
        ...storeStats,
        formatted: {
          credits: {
            total: { sum: formatCurrency(credits.total.sum / 100) },
            dynamic: { sum: formatCurrency(credits.total.sum / 100) },
          },
          debits: {
            sum: formatCurrency(storeStats.debits.sum / 100),
          },
        },
      };
    }),
    giftcardStats: {
      ...giftcardStats,
      formatted: {
        balance: formatCurrency(giftcardStats.balance / 100),
        cancellations: {
          benefits: {
            sum: formatCurrency(giftcardStats.cancellations.benefits.sum / 100),
          },
          offline: {
            sum: formatCurrency(giftcardStats.cancellations.offline.sum / 100),
          },
          online: {
            sum: formatCurrency(giftcardStats.cancellations.online.sum / 100),
          },
          partner: {
            sum: formatCurrency(giftcardStats.cancellations.partner.sum / 100),
          },
          total: {
            sum: formatCurrency(giftcardStats.cancellations.total.sum / 100),
          },
        },
        credits: {
          benefits: {
            sum: formatCurrency(giftcardStats.credits.benefits.sum / 100),
          },
          offline: {
            sum: formatCurrency(giftcardStats.credits.offline.sum / 100),
          },
          online: {
            sum: formatCurrency(giftcardStats.credits.online.sum / 100),
          },
          partner: {
            sum: formatCurrency(giftcardStats.credits.partner.sum / 100),
          },
          total: {
            sum: formatCurrency(giftcardStats.credits.total.sum / 100),
          },
        },
        debits: {
          benefits: {
            sum: formatCurrency(giftcardStats.debits.benefits.sum / 100),
          },
          offline: {
            sum: formatCurrency(giftcardStats.debits.offline.sum / 100),
          },
          online: {
            sum: formatCurrency(giftcardStats.debits.online.sum / 100),
          },
          partner: {
            sum: formatCurrency(giftcardStats.debits.partner.sum / 100),
          },
          total: {
            sum: formatCurrency(giftcardStats.debits.total.sum / 100),
          },
        },
        depreciations: {
          benefits: {
            sum: formatCurrency(giftcardStats.depreciations.benefits.sum / 100),
          },
          offline: {
            sum: formatCurrency(giftcardStats.depreciations.offline.sum / 100),
          },
          online: {
            sum: formatCurrency(giftcardStats.depreciations.online.sum / 100),
          },
          partner: {
            sum: formatCurrency(giftcardStats.depreciations.partner.sum / 100),
          },
          total: {
            sum: formatCurrency(giftcardStats.depreciations.total.sum / 100),
          },
        },
        refunds: {
          benefits: {
            sum: formatCurrency(giftcardStats.refunds.benefits.sum / 100),
          },
          offline: {
            sum: formatCurrency(giftcardStats.refunds.offline.sum / 100),
          },
          online: {
            sum: formatCurrency(giftcardStats.refunds.online.sum / 100),
          },
          partner: {
            sum: formatCurrency(giftcardStats.refunds.partner.sum / 100),
          },
          total: {
            sum: formatCurrency(giftcardStats.refunds.total.sum / 100),
          },
        },
        transactions: {
          benefits: {
            sum: formatCurrency(giftcardStats.transactions.benefits.sum / 100),
          },
          offline: {
            sum: formatCurrency(giftcardStats.transactions.offline.sum / 100),
          },
          online: {
            sum: formatCurrency(giftcardStats.transactions.online.sum / 100),
          },
          partner: {
            sum: formatCurrency(giftcardStats.transactions.partner.sum / 100),
          },
          total: {
            sum: formatCurrency(giftcardStats.transactions.total.sum / 100),
          },
        },
      },
    },
  };
};
