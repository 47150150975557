import styled from 'styled-components';

import { WizardDialog } from 'components';

export const WizardDialogStyled = styled(WizardDialog)`
  div.MuiDialog-paper {
    min-width: 28.125rem;
  }
`;

const borderRadius = '1.75rem';

export const WalletPictureContainer = styled.div`
  height: 7.062rem;
  width: calc(100% - 1.5rem - 2px);
  background: ${({ theme }) => theme.palette.zml.blue300};
  border-radius: 0 0 calc(${borderRadius} - 1px) calc(${borderRadius} - 1px);

  position: absolute;
  bottom: 2.25rem;
  left: 50%;
  transform: translateX(-50%);

  img {
    height: 100%;
    max-width: 100%;
    border-radius: 0 0 calc(${borderRadius} - 1px) calc(${borderRadius} - 1px);
  }
`;

export const TemplateImg = styled.img`
  margin-bottom: 1rem;
  box-shadow: 6px 6px 6px ${({ theme }) => theme.palette.zml.boxShadow};
  width: 100%;

  border: 1px solid ${({ theme }) => theme.palette.zml.grey100};
  border-radius: ${borderRadius};
`;
