import { useDispatch, useSelector } from 'react-redux';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { ComponentType, useEffect } from 'react';
import { getPersistor } from '@rematch/persist';
import paths, {
  ROOT_COMPANY,
  ROOT_HOSTNAME,
  ROOT_NETWORK,
  ROOT_RETAILER,
  ROOT_ROUTE,
} from 'utils/paths';
import { BackofficeLayout } from 'layouts/BackofficeLayout';
import { AuthLayout } from 'layouts/AuthLayout';
import { Dispatch, RootState } from 'store';
import { AccountsLayout } from 'layouts/AccountsLayout';

const backofficeRouteRegex = new RegExp(
  `${ROOT_COMPANY}|${ROOT_RETAILER}|${ROOT_NETWORK}`,
);

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  preventPrivateRedirect?: boolean;
  component: ComponentType<any>;
}

export const Route = (props: RouteProps) => {
  const {
    isPrivate = false,
    preventPrivateRedirect = false,
    component: Component,
    ...rest
  } = props;

  const { pathname } = useLocation();

  const dispatch = useDispatch<Dispatch>();
  const { user } = useSelector((state: RootState) => state.auth);
  const isAppInitialized = useSelector(
    (state: RootState) => state.app.isInitialized,
  );

  const isBackofficeRoute = backofficeRouteRegex.test(pathname);

  useEffect(() => {
    if (!isPrivate || !rest?.location) return;

    if (!ROOT_HOSTNAME || ROOT_HOSTNAME === window.location.origin) return;

    const { location } = rest;

    const rootUrl = new URL(ROOT_HOSTNAME);
    rootUrl.pathname = ROOT_ROUTE + location.pathname;
    rootUrl.hash = location.hash;
    rootUrl.search = location.search;

    getPersistor().purge();
    dispatch({ type: 'RESET_APP' });
    dispatch.loading.SET_SCREEN(true);
    dispatch.app.setInitialized(false);
    window.open(rootUrl.toString(), '_self');
  }, [rest?.location]);

  const getPageLayout = () => {
    if (!isPrivate) return AuthLayout;

    if (user?.id && isBackofficeRoute) return BackofficeLayout;

    return AccountsLayout;
  };

  const Layout = getPageLayout();

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        if (pathname === paths.LOGOUT) return <Component />;

        return isPrivate === Boolean(user?.id) ||
          preventPrivateRedirect ||
          !isAppInitialized ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? paths.LOGIN : paths.DASHBOARD,
              state: { from: rest?.location?.pathname },
            }}
          />
        );
      }}
    />
  );
};
