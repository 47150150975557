import { addDays, isLeapYear } from 'date-fns';

import { useBenefitCheckout } from 'components/BenefitCheckout/BenefitCheckoutContext';
import { DatePickerProps } from 'components/Forms';
import { useTranslation } from 'hooks/translation';
import { getMinQuarterlyDate, isQuarterlyMonth } from './utils';

const tomorrow = addDays(new Date(), 1);

export const useStartsAtProps = (): DatePickerProps => {
  const { type, schedule } = useBenefitCheckout();

  const { t } = useTranslation('components');

  if (type === 'scheduled') {
    return {
      label: t('benefitCheckoutDialog.benefitSchedule.scheduledBenefitLabel'),
      minDate: tomorrow,
    };
  }

  if (schedule.interval === 'quarterly') {
    return {
      label: t('benefitCheckoutDialog.benefitSchedule.quarterlyStartsAtLabel'),
      minDate: getMinQuarterlyDate(),
      shouldDisableDate: date => !isQuarterlyMonth(date),
    };
  }

  if (schedule.interval === 'annually') {
    return {
      label: t('benefitCheckoutDialog.benefitSchedule.annuallyStartsAtLabel'),
      minDate: tomorrow,
      shouldDisableDate: date => {
        if (!isLeapYear(date)) return false;

        const isFebruary29 = date.getDate() === 29 && date.getMonth() === 1;

        return isFebruary29;
      },
    };
  }

  return {
    label: t('benefitCheckoutDialog.benefitSchedule.monthlyStartsAtLabel'),
    minDate: tomorrow,
  };
};
