import styled, { css } from 'styled-components';
import { TableHead, TableRow, TableContainer, TableCell } from '@mui/material';

interface TableRowStyledProps {
  $clickable?: boolean;
}

export const TableRowStyled = styled(TableRow)<TableRowStyledProps>`
  td,
  th {
    border: none;
    color: ${({ theme }) => theme.palette.zml.blue800};
  }

  > td {
    font-size: 0.938rem;
    color: ${({ theme }) => theme.palette.zml.blue800};
    font-weight: 300;
    border-top: 0.063rem solid ${({ theme }) => theme.palette.zml.grey100};
    ${({ $clickable }) =>
      $clickable &&
      css`
        :hover {
          cursor: pointer;
        }
      `};
  }

  :hover {
    td {
      background-color: ${({ theme }) => theme.palette.zml.grey50};
    }
  }
`;

export const TableHeadStyled = styled(TableHead)`
  > tr th {
    font-size: 1.0625rem;
  }
`;

interface TableContainerStyledProps {
  $topSeparator?: boolean;
  $bottomSeparator?: boolean;
}

export const TableContainerStyled = styled(
  TableContainer,
)<TableContainerStyledProps>`
  ${({ $topSeparator, theme }) =>
    $topSeparator &&
    css`
      border-top: 0.063rem solid ${theme.palette.zml.grey100};
    `}

  ${({ $bottomSeparator, theme }) =>
    $bottomSeparator &&
    css`
      border-bottom: 0.063rem solid ${theme.palette.zml.grey100};
    `}
`;

export const TableCellStyled = styled(TableCell)`
  > div {
    display: flex;
    align-items: center;

    > span {
      margin-left: 0.5rem;
    }
  }

  && {
    :hover {
      background-color: transparent;
    }
  }
`;
