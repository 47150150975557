import { useState } from 'react';

interface DisclosureParams<T> {
  initialValue?: boolean;
  data?: T;
}

export const useDisclosure = <T>(params?: DisclosureParams<T>) => {
  const { initialValue = false, data = null } = params || {};

  const [isOpen, setIsOpen] = useState(initialValue);
  const [dialogData, setDialogData] = useState<T | null>(data);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onToggle = (_isOpen?: boolean) => {
    if (typeof _isOpen === 'boolean') return setIsOpen(_isOpen);

    setIsOpen(state => !state);
  };

  const onChangeDialogData = (newData: T | null) => {
    setDialogData(newData);
  };

  return {
    isOpen,
    onOpen,
    onClose,
    dialogData,
    onChangeDialogData,
    onToggle,
  };
};
