import { ListItemText } from '@mui/material';
import { useMemo } from 'react';
import {
  matchPath,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import urlcat from 'urlcat';
import { IconType } from 'react-icons';
import paths, { isCompany, isNetwork, isRetailer } from 'utils/paths';
import { getCurrentRoutePath } from 'utils/helpers';
import { Icon } from 'components/Icon';
import { useRetailer } from 'hooks/retailer';
import { useCompany } from 'hooks/company';
import { useNetwork } from 'hooks/network';

import { IconContainer, ListItemStyled, NavLink } from '../../Sidebar.styled';
import { isLinkSubPagePath } from './isLinkedSubMenuPath';
import {
  companySettingsMenuItems,
  networkSettingsMenuItems,
  retailerSettingsMenuItems,
} from './SettingsMenu';

interface Props {
  description: string;
  icon: IconType | SVG;
  path?: string;
  onClick?(): void;
}

export const MenuItem = (props: Props) => {
  const { description, icon, path, onClick } = props;

  const { pathname } = useLocation();

  const history = useHistory();

  const { company } = useCompany();
  const { network } = useNetwork();
  const { retailer } = useRetailer();

  const baseURL = useMemo(() => {
    const currentRoute = getCurrentRoutePath(pathname);

    const currentPath = pathname.replace(currentRoute, '');

    if (isCompany(currentPath) && company) {
      const companyBaseUrl = urlcat(paths.COMPANY, {
        companyId: company.id,
      });

      return companyBaseUrl;
    }

    if (isNetwork(currentPath) && network) {
      const networkBaseUrl = urlcat(paths.NETWORK, {
        networkId: network.id,
      });

      return networkBaseUrl;
    }

    if (isRetailer(currentPath) && retailer) {
      const retailerBaseUrl = urlcat(paths.RETAILER, {
        retailerId: retailer.id,
      });

      return retailerBaseUrl;
    }

    return currentPath;
  }, [pathname, company?.id, network?.id, retailer?.id]);

  const match = useRouteMatch({ path: `${baseURL}${path}`, exact: false });

  const menuPath = `${baseURL}${path}`;

  const isSettingsMenu = useMemo(() => {
    const currentRoute = getCurrentRoutePath(pathname);

    const currentPath = pathname.replace(currentRoute, '');

    const resolveSettingsMenuItems = () => {
      if (isRetailer(currentPath)) return retailerSettingsMenuItems;

      if (isCompany(currentPath)) return companySettingsMenuItems;

      if (isNetwork(currentPath)) return networkSettingsMenuItems;

      return [];
    };

    const settingsMenuItems = resolveSettingsMenuItems();

    return settingsMenuItems.some(menu => {
      return matchPath(menuPath, {
        path: `${baseURL}${menu.path}`,
        exact: true,
      });
    });
  }, [baseURL]);

  const isActive = () => {
    if (match) return true;

    return isLinkSubPagePath({
      currentPath: pathname,
      menuPath,
      isSettingsMenu,
    });
  };

  // TODO: refactor and abstract it o its own component
  // eslint-disable-next-line react/no-unstable-nested-components
  const ListItemContent = () => {
    return (
      <>
        <IconContainer>
          <Icon icon={icon} size="1.5rem" />
        </IconContainer>
        <ListItemText>{description}</ListItemText>
      </>
    );
  };

  return path ? (
    <ListItemStyled
      key={description}
      component={NavLink}
      to={isActive() ? `${menuPath}${history.location.search}` : menuPath}
      isActive={isActive}
      onClick={onClick}
    >
      <ListItemContent />
    </ListItemStyled>
  ) : (
    <ListItemStyled key={description} onClick={onClick}>
      <ListItemContent />
    </ListItemStyled>
  );
};
