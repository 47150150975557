/* eslint-disable no-restricted-syntax */
import { ReactNode, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import {
  InputLabel,
  Select,
  Typography,
  TypographyProps,
  BaseSelectProps,
  SelectChangeEvent,
  ListSubheader,
  Box,
} from '@mui/material';
import { isInternetExplorer } from 'utils/helpers';
import { useDisclosure } from 'hooks/disclosure';
import { TooltipError } from '../TooltipError';

import {
  FormControlStyled,
  CircularProgressStyled,
  useStyles,
  MenuItemStyled,
} from './Dropdown.styled';
import { NativeDropdown } from './NativeDropdown';

export interface Subheader {
  name: string;
  options: Array<{
    name: string;
    id: string;
  }>;
}

interface Props<T extends DropdownValue> extends BaseSelectProps<T> {
  id?: string;
  label?: ReactNode;
  options?: DropdownOption<T>[];
  uniqueOptionId?: string;
  value?: T;
  errortext?: string;
  focused?: boolean;
  submitcount?: number;
  isLoading?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  listSubheader?: Subheader[];
  itemTypographyProps?: TypographyProps;
}

export const Dropdown = <T extends DropdownValue>(props: Props<T>) => {
  const {
    id,
    label,
    options,
    errortext,
    focused,
    submitcount,
    isLoading,
    listSubheader,
    variant,
    required = true,
    fullWidth = true,
    margin,
    itemTypographyProps = {},
    ...rest
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dropdownRef = useRef<HTMLInputElement>(null);
  const hasError = Boolean(errortext);

  const classes = useStyles();
  const isOldIE = isInternetExplorer();
  const isNative = isOldIE;

  const isGroupDropdownButNotOldIE = listSubheader && !isOldIE;
  const isGroupDropdownAndIsOldIE = listSubheader && isOldIE;
  const isNotGroupDropdownAndNotOldIE = !listSubheader && !isOldIE;
  const isNotGroupDropdownAndIsOldIE = !listSubheader && isOldIE;

  const onChange = (event: SelectChangeEvent<T>, child: ReactNode) => {
    rest?.onChange?.(event, child);
    onClose();
  };

  const renderGroupDropdown = (subheaders: Subheader[]) => {
    if (!subheaders) return;

    const items: JSX.Element[] = [];

    for (const header of subheaders) {
      items.push(
        <ListSubheader
          key={uuid()}
          classes={{ root: classes.listSubHeaderRoot }}
        >
          {header.name}
        </ListSubheader>,
      );

      for (const option of header.options) {
        items.push(
          <MenuItemStyled key={option.id} value={option.id}>
            {option.name}
          </MenuItemStyled>,
        );
      }
    }

    return items;
  };

  useEffect(() => {
    if (!focused) return;

    dropdownRef.current?.focus();
  }, [focused, submitcount]);

  return (
    <FormControlStyled
      variant={variant ?? 'standard'}
      fullWidth={fullWidth}
      $hasError={hasError}
      margin={margin}
    >
      {Boolean(label) && (
        <InputLabel id={id} error={hasError} required={required}>
          {label}
        </InputLabel>
      )}
      <Select
        {...rest}
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        native={isNative}
        onChange={onChange}
        labelId={id}
        fullWidth={fullWidth}
        margin={margin}
        inputProps={{ ...rest?.inputProps, ref: dropdownRef }}
        SelectDisplayProps={{ 'data-testid': 'dropdown-button' } as KeyValue}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
        endAdornment={
          <>
            <Box marginRight="1rem">
              <TooltipError error={errortext} />
            </Box>

            {rest?.endAdornment}

            {isLoading && (
              <CircularProgressStyled color="primary" size="0.938rem" />
            )}
          </>
        }
        error={hasError}
      >
        {isGroupDropdownButNotOldIE && renderGroupDropdown(listSubheader)}

        {isGroupDropdownAndIsOldIE && (
          <NativeDropdown listSubheader={listSubheader} />
        )}

        {isNotGroupDropdownAndNotOldIE &&
          options?.map(option => (
            <MenuItemStyled
              key={option.id ?? option.value}
              value={option.value}
              data-testid={`${option.value}-option`}
              disabled={option?.disabled}
            >
              <Typography
                variant="body2"
                component="span"
                {...itemTypographyProps}
              >
                {option.label}
              </Typography>
            </MenuItemStyled>
          ))}

        {isNotGroupDropdownAndIsOldIE && <NativeDropdown options={options} />}
      </Select>
    </FormControlStyled>
  );
};
