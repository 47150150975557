import styled, { css } from 'styled-components';

interface LinkContentProps {
  $noHoverEffect?: boolean;
}

export const LinkContent = styled.span<LinkContentProps>`
  color: ${({ theme }) => theme.palette.primary.main};
  transition: color 0.2s;

  ${({ $noHoverEffect }) =>
    !$noHoverEffect &&
    css`
      &:hover {
        text-decoration: underline;
        filter: brightness(0.7);
      }
    `}
`;
