import styled from 'styled-components';
import { Backdrop } from '@mui/material';
import { drawerWidth } from 'components/Sidebar/Sidebar.styled';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

interface BackdropStyledProps {
  $isLoading: {
    page: boolean;
    screen: boolean;
  };
}

export const BackdropStyled = styled(Backdrop)<BackdropStyledProps>`
  background: ${({ theme }) => theme.palette.zml.grey50};

  left: ${({ $isLoading: isLoading }) =>
    isLoading.page && !isLoading.screen ? `${drawerWidth}` : 0};

  ${MOBILE_BREAKPOINT} {
    left: 0;
  }

  transition: opacity 0;

  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;
