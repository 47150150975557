import styled from 'styled-components';
import { FaArrowUpLong } from 'react-icons/fa6';
import { FaCalendarCheck } from 'react-icons/fa';
import { FiRepeat } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { FcCheckmark } from 'react-icons/fc';

const OneTimeBenefitIcon = styled(FaArrowUpLong)`
  transform: rotate(45deg);
`;

type PascalCaseBenefitCheckoutType =
  | 'OneTime'
  | 'Scheduled'
  | 'Recurring'
  | 'Success';

export const BenefitCheckoutIcons: Record<
  PascalCaseBenefitCheckoutType,
  IconType
> = {
  OneTime: OneTimeBenefitIcon,
  Scheduled: FaCalendarCheck,
  Recurring: FiRepeat,
  Success: FcCheckmark,
} as const;
