import { Box, BoxProps } from '@mui/material';
import {
  forwardRef,
  MouseEvent,
  ReactNode,
  Ref,
  useImperativeHandle,
} from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

interface ChildrenFunctionParams {
  isDragActive: boolean;
  openFileSelection(): void;
}

type ChildrenFunction = (params: ChildrenFunctionParams) => ReactNode;

interface Props extends Omit<BoxProps, 'children'> {
  children?: ReactNode | ChildrenFunction;
  onSelectFile?(
    file: File[],
    rejectedFiles: FileRejection[],
    event: DropEvent,
  ): void;
  accept?: string | string[];
  maxFiles?: number;
  inputTestId?: string;
  noClick?: boolean;
}

export interface DropzoneRef {
  openFileSelection(): void;
}

const DropzoneBase = (props: Props, ref: Ref<DropzoneRef>) => {
  const {
    children,
    onSelectFile,
    accept = 'image/*',
    inputTestId,
    noClick = false,
    maxFiles,
    ...rest
  } = props;

  const onDrop = (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
    event: DropEvent,
  ) => {
    event.stopPropagation();
    onSelectFile?.(acceptedFiles, rejectedFiles, event);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    noClick,
  });

  useImperativeHandle(ref, () => ({
    openFileSelection() {
      open();
    },
  }));

  const resolveChildren = () => {
    if (typeof children === 'function') {
      return children({ isDragActive, openFileSelection: open });
    }

    return children;
  };

  const onClick = (event: MouseEvent<HTMLInputElement>) => {
    if (noClick) return;

    event.stopPropagation();
    open();
  };

  return (
    <Box {...getRootProps()} onClick={onClick} {...rest}>
      <input {...getInputProps()} data-testid={inputTestId} />

      {resolveChildren()}
    </Box>
  );
};

export const Dropzone = forwardRef<DropzoneRef, Props>(DropzoneBase);
