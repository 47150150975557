import styled from 'styled-components';
import { Avatar, ListItemText, ListItem } from '@mui/material';
import { FaPlusCircle } from 'react-icons/fa';

export const ListItemStyled = styled(ListItem).attrs({
  button: true,
} as KeyValue)`
  padding: 0.625rem;
  color: ${({ theme }) => theme.palette.zml.blue800};

  :not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const AvatarStyled = styled(Avatar)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 0.5rem;
  background-color: ${({ theme }) => theme.palette.zml.blue800};
  font-size: 1.2rem;
  font-weight: 600;
  width: 2.125rem;
  height: 2.125rem;

  svg {
    color: ${({ theme }) => theme.palette.zml.white};
  }
`;

export const ListItemTextStyled = styled(ListItemText)`
  margin-right: 0.5rem;

  overflow: hidden;
  word-wrap: nowrap;
  white-space: nowrap;
`;

export const FaPlusCircleStyled = styled(FaPlusCircle)`
  color: ${({ theme }) => theme.palette.zml.blue800};
  background-color: ${({ theme }) => theme.palette.zml.white};
`;
