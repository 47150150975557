import { IconButton } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import {
  getMainMenuItems,
  getSettingsMenuItems,
} from 'components/Sidebar/components/Menu';
import { getCurrentRoutePath } from 'utils/helpers';
import { HideComponent } from 'components/HideComponent';
import paths, { isCompany, isRetailer } from 'utils/paths';
import { Icon } from 'components/Icon';
import { useRetailer } from 'hooks/retailer';
import { useCompany } from 'hooks/company';
import { PageTitleContainer } from './TopbarDashboard.styled';
import { Container, TypographyStyled } from './Topbar.styled';
import { ProfilePopup } from './components/ProfilePopup';
import { pagesTitle } from './pagesTitle';
// import { SearchInput } from './components/SearchInput';

interface Props {
  handleHamburguerMenuClick: () => void;
}

export const TopbarBackoffice = ({ handleHamburguerMenuClick }: Props) => {
  const { pathname } = useLocation();

  const { retailer } = useRetailer();

  const { company } = useCompany();

  const pageTitle = useMemo(() => {
    const subRoute = pagesTitle.find(route =>
      matchPath(pathname, { path: route.pathname, exact: true }),
    );

    if (subRoute) return subRoute.title;

    const mainMenuItems = getMainMenuItems(pathname);

    if (!pathname || !mainMenuItems.length || !company) return null;

    const currentRoute = getCurrentRoutePath(pathname);

    const mainMenu = mainMenuItems?.find(menu => menu.path === currentRoute);

    if (mainMenu?.path === paths.DASHBOARD) {
      if (isCompany(pathname)) return company.contact?.name || '';

      if (isRetailer(pathname)) return retailer?.contact.name || '';
    }

    if (mainMenu) return mainMenu.description;

    const settingsMenuItems = getSettingsMenuItems(pathname);

    const settingsMenu = settingsMenuItems.find(
      menu => menu.path === currentRoute,
    );

    if (settingsMenu) return settingsMenu.description;

    return null;
  }, [pathname, company, retailer]);

  return (
    <Container>
      <PageTitleContainer>
        <TypographyStyled noWrap>{pageTitle}</TypographyStyled>
      </PageTitleContainer>

      <aside>
        <HideComponent onMobileDown>
          {/* <SearchInput /> */}
          <ProfilePopup />
        </HideComponent>
        <HideComponent onMobileUp>
          <IconButton onClick={handleHamburguerMenuClick} size="large">
            <Icon icon={GiHamburgerMenu} size="1.5rem" />
          </IconButton>
        </HideComponent>
      </aside>
    </Container>
  );
};
