import {
  useCallback,
  forwardRef,
  ForwardRefRenderFunction,
  useMemo,
} from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { useTranslation } from 'hooks/translation';

const DateInput: ForwardRefRenderFunction<HTMLElement, MaskedInputProps> = (
  props,
  ref,
) => {
  const { t } = useTranslation('form');

  const dateFormat = t('dateFormat', { disableParentElement: true });

  const dateMask = useMemo(() => {
    return dateFormat.split('').map(char => {
      if (char.match(/[a-zA-Z]/)) return /\d/;

      return char;
    });
  }, [dateFormat]);

  const setRef = useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;

      if (typeof ref === 'function') {
        ref(value);
        return;
      }

      if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );

  return <MaskedInput {...props} ref={setRef} guide={false} mask={dateMask} />;
};

export const MaskedDateInput = forwardRef(DateInput);
