import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

export const useCompany = () => {
  const company = useSelector(
    (state: RootState) => state.company.currentCompany,
  );
  const companies = useSelector((state: RootState) => state.company.companies);
  const dispatch = useDispatch<Dispatch>();

  return {
    company,
    companies,
    companyActions: dispatch.company,
  };
};
