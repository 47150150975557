import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { StyledEngineProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import { ROOT_ROUTE } from 'utils/paths';

interface Props {
  children: ReactNode;
}

export const Providers = (props: Props) => {
  const { children } = props;

  const persistor = getPersistor();

  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <BrowserRouter basename={ROOT_ROUTE}>{children}</BrowserRouter>
        </StyledEngineProvider>
      </Provider>
    </PersistGate>
  );
};
