import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getEmployeeGiftcardDepotsConnection } from 'repositories/employees';
import { useValues } from 'hooks/values';
import { parseDateFromServer } from 'utils/helpers';
import { BenefitCheckoutForm } from './components/BenefitCheckoutForm/types';

import {
  BenefitCheckoutType,
  Email,
  GiftcardAmount,
  BenefitTransaction,
  Schedule,
  CheckoutAction,
} from './types';

export interface BenefitCheckoutProps {
  type: BenefitCheckoutType;
  isOpen: boolean;
  onClose(): void;
  onBooked?(): void;
  action?: CheckoutAction;
  initial?: {
    employee?: Employee;
    network?: Network;
    transaction?: AdaptedAutomatedBenefit;
    delivery?: DeliveryType;
    depotCardConnections?: DepotCardConnection[];
  };
}

interface BenefitCheckoutContextData extends BenefitCheckoutProps {
  employee: Employee | null;
  setEmployee(employee: Employee | null): void;
  connections: {
    depotCardConnections: DepotCardConnection[] | null;
  };
  benefit: AdaptedBenefit | null;
  setBenefit(benefit: Benefit | null): void;
  network: Network | null;
  setNetwork(network: Network | null): void;
  delivery: DeliveryType | '';
  setDelivery(delivery: DeliveryType): void;
  needsEmployeeGiftcard: boolean;
  email: Email;
  setEmail(email: Email): void;
  reason: string;
  setReason(reason: string): void;
  giftcard: AdaptedGiftcard<MiniGiftcard> | null;
  setGiftcard(giftcard: AdaptedGiftcard<MiniGiftcard> | null): void;
  amount: GiftcardAmount;
  setAmount(amount: GiftcardAmount): void;
  errors: Record<keyof Partial<BenefitCheckoutForm>, string> | null;
  setErrors(
    errors: Record<keyof Partial<BenefitCheckoutForm>, string> | null,
  ): void;
  isSubmitted: boolean;
  transaction: BenefitTransaction;
  setTransaction(transaction: Partial<BenefitTransaction>): void;
  schedule: Schedule;
  setSchedule(schedule: Partial<Schedule>): void;
  resetCheckout(): void;
}

const BenefitCheckoutContext = createContext({} as BenefitCheckoutContextData);

type Props = PropsWithChildren<BenefitCheckoutProps>;

interface ContextValues {
  employee: Employee | null;
  benefit: AdaptedBenefit | null;
  network: Network | null;
  delivery: DeliveryType | '';
  email: Email;
  reason: string;
  giftcard: AdaptedGiftcard<MiniGiftcard> | null;
  amount: GiftcardAmount;
  errors: Record<keyof Partial<BenefitCheckoutForm>, string> | null;
  transaction: BenefitTransaction;
  schedule: Schedule;
}

export const BenefitCheckoutContextProvider = (props: Props) => {
  const { children, action = 'create', initial, ...rest } = props;

  const [connections, setConnections] = useState<{
    depotCardConnections: DepotCardConnection[] | null;
  }>({
    depotCardConnections: initial?.depotCardConnections || null,
  });

  const { values, setValue, setValues, resetValues } = useValues<ContextValues>(
    {
      initialValues: {
        employee: null,
        benefit: null,
        network: null,
        delivery: '',
        email: { email: '', emailConfirmation: '' },
        reason: '',
        giftcard: null,
        amount: { value: 0, formatted: '' },
        errors: null,
        transaction: { oneTime: null, automated: null },
        schedule: { interval: 'monthly', startsAt: null, endsAt: null },
      },
    },
  );

  const { employee, network, schedule, transaction } = values;

  const isSubmitted = Boolean(transaction.oneTime || transaction.automated);

  const needsEmployeeGiftcard = useMemo(() => {
    if (!network || !employee) return false;

    return !connections.depotCardConnections?.some(
      findConnection => findConnection.networkId === network?.id,
    );
  }, [network, employee, connections.depotCardConnections]);

  const setEmployee = async (data: Employee | null) => {
    if (!data || !data.giftcardDepotId) {
      setValue('employee', data);
      setConnections({ depotCardConnections: null });

      return;
    }

    const _connections = await getEmployeeGiftcardDepotsConnection({
      employeeId: data.id,
      giftcardDepotId: data.giftcardDepotId,
    });

    setConnections(_connections);
    setValue('employee', data);
  };

  const setBenefit = (data: AdaptedBenefit) => {
    setValue('benefit', data);
  };

  const setNetwork = (data: Network) => {
    setValue('network', data);
  };

  const setDelivery = (data: DeliveryType | '') => {
    setValue('delivery', data);
  };

  const setEmail = (data: Email) => {
    setValue('email', data);
  };

  const setReason = (data: string) => {
    setValue('reason', data);
  };

  const setGiftcard = (data: AdaptedGiftcard<MiniGiftcard> | null) => {
    setValue('giftcard', data);
  };

  const setAmount = (data: GiftcardAmount) => {
    setValue('amount', data);
  };

  const setErrors = (
    data: Record<keyof Partial<BenefitCheckoutForm>, string> | null,
  ) => {
    setValue('errors', data);
  };

  const setTransaction = (data: Partial<BenefitTransaction>) => {
    setValue('transaction', { ...transaction, ...data });
  };

  const setSchedule = (data: Partial<Schedule>) => {
    setValue('schedule', { ...schedule, ...data });
  };

  const resetCheckout = () => {
    resetValues();
  };

  useEffect(() => {
    const manageTransaction = () => {
      if (!initial?.employee || !initial?.transaction) return;

      setValues({
        employee: initial.employee,
        network: initial.network,
        reason: initial.transaction.description,
        amount: {
          value: initial.transaction.amount,
          formatted: initial.transaction.formatted.amount,
        },
        schedule: {
          interval:
            initial.transaction.interval !== 'once'
              ? initial.transaction.interval
              : 'monthly',
          startsAt: parseDateFromServer(initial.transaction.schedule.startsAt),
          endsAt: initial.transaction.schedule.expiresAt
            ? parseDateFromServer(initial.transaction.schedule.expiresAt)
            : null,
        },
      });
    };

    const initCheckout = async () => {
      if (action === 'create') {
        if (initial?.depotCardConnections) {
          setConnections({
            depotCardConnections: initial.depotCardConnections,
          });
        }

        if (initial?.employee) {
          setValue('employee', initial.employee);
        }

        if (initial?.network) setNetwork(initial.network);

        return;
      }

      if (initial) manageTransaction();
    };

    initCheckout();
  }, [initial, action]);

  const value = useMemo<BenefitCheckoutContextData>(() => {
    return {
      ...rest,
      ...values,
      initial,
      action,
      isSubmitted,
      connections,
      needsEmployeeGiftcard,
      setEmployee,
      setBenefit,
      setNetwork,
      setDelivery,
      setEmail,
      setReason,
      setGiftcard,
      setAmount,
      setErrors,
      setTransaction,
      setSchedule,
      resetCheckout,
    };
  }, [rest, initial, values, connections]);

  return (
    <BenefitCheckoutContext.Provider value={value}>
      {children}
    </BenefitCheckoutContext.Provider>
  );
};

export const useBenefitCheckout = () => useContext(BenefitCheckoutContext);
