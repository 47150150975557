import { createModel } from '@rematch/core';
import type { RootModel } from '../model';

interface PreferencesStore {
  benefitBook: {
    deliveryFormat?: DeliveryType;
  };
}

const initialState: PreferencesStore = {
  benefitBook: {},
};

export const preferences = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setBenefitBook: (state, payload: PreferencesStore['benefitBook']) => {
      return { ...state, benefitBook: { ...state.benefitBook, ...payload } };
    },
  },
});
