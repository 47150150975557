import { useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import urlcat from 'urlcat';
import paths, { isCompany } from 'utils/paths';
import { getCurrentRoutePath } from 'utils/helpers';
import { useCompany } from 'hooks/company';

export const RedirectNotFound = () => {
  const { pathname } = useLocation();

  const { company } = useCompany();

  const route = useMemo(() => {
    const currentRoute = getCurrentRoutePath(pathname);
    const currentPath = pathname.replace(currentRoute, '');

    if (isCompany(currentPath) && company) {
      const companyBaseUrl = urlcat(paths.COMPANY, {
        companyId: company.id,
      });

      return companyBaseUrl + paths.DASHBOARD;
    }

    return paths.DASHBOARD;
  }, [pathname, company]);

  return <Redirect to={route} />;
};
