import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isListItemNode } from '@lexical/list';
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  INSERT_LINE_BREAK_COMMAND,
  RangeSelection,
  RootNode,
} from 'lexical';

interface Props {
  maxEmptyLines?: number;
}

interface IsEmptyLinesLimitReachedParams {
  selection: RangeSelection;
  textContent: string;
  isParagraph: boolean;
}

export const LimitMaxEmptyLinesPlugin = (props: Props) => {
  const { maxEmptyLines = 2 } = props;

  const [editor] = useLexicalComposerContext();

  const isEmptyLinesLimitReached = (params: IsEmptyLinesLimitReachedParams) => {
    const { selection, textContent, isParagraph } = params;

    const element = selection.anchor.getNode();

    const isList = $isListItemNode(element);

    const getValidateLinesRegexp = (isEmpty: boolean) => {
      const oneLineBreak = isParagraph ? 2 : 1;
      const paragraphMaxEmptyLines = maxEmptyLines * 2 + oneLineBreak;

      const resolveAllowedEmptyLines = () => {
        if (isEmpty) return oneLineBreak;

        if (isList) return oneLineBreak * 3;

        return paragraphMaxEmptyLines;
      };

      return new RegExp(
        `(\\w+)?\\n{${resolveAllowedEmptyLines()},}(\\w+)?${isList ? '$' : ''}`,
        'g',
      );
    };

    const isLimitReached = textContent.match(
      getValidateLinesRegexp(textContent.trim() === ''),
    );

    return {
      isLimitReached: Boolean(isLimitReached),
      isList,
    };
  };

  useEffect(() => {
    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection();

      if (!$isRangeSelection(selection) || !selection.isCollapsed()) return;

      const prevEditorState = editor.getEditorState();
      const prevTextContent = prevEditorState.read(() => {
        const root = $getRoot();

        return root.getTextContent();
      });

      const textContent = rootNode.getTextContent();

      const { isLimitReached, isList } = isEmptyLinesLimitReached({
        selection,
        textContent,
        isParagraph: true,
      });

      if (!isLimitReached || isList) return;

      if (prevTextContent !== textContent) {
        editor.setEditorState(prevEditorState);
      }
    });
  }, [editor, maxEmptyLines]);

  useEffect(() => {
    return editor.registerCommand(
      INSERT_LINE_BREAK_COMMAND,
      () => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
          return false;
        }

        const element = selection.anchor.getNode();

        const textContent = element.getTextContent();

        const { isLimitReached, isList } = isEmptyLinesLimitReached({
          selection,
          textContent,
          isParagraph: false,
        });

        if (isList) return isLimitReached;

        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, maxEmptyLines]);

  return null;
};
