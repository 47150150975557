import { Button, darken } from '@mui/material';
import styled, { css } from 'styled-components';

import { Icon } from 'components/Icon';

interface ButtonStyledProps {
  $bgColor?: keyof ZmyleThemeColors;
}

export const ButtonStyled = styled(Button)<ButtonStyledProps>`
  font-weight: 400;

  :disabled {
    background-color: ${({ theme }) => theme.palette.zml.grey50};
    color: ${({ theme }) => theme.palette.zml.grey100};
  }

  ${({ variant }) =>
    variant === 'contained' &&
    css`
      &:hover {
        background-color: ${({ theme }) =>
          darken(theme.palette.primary.main, 0.2)};
      }
    `};

  ${({ $bgColor, theme }) =>
    $bgColor &&
    css`
      background-color: ${theme.palette.zml[$bgColor]};

      &:hover {
        background: ${darken(theme.palette.zml[$bgColor], 0.2)};
      }
    `}
`;

export const IconStyled = styled(Icon)`
  color: ${({ theme }) => theme.palette.zml.white};

  margin-right: 0.3rem;
`;
