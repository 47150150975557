export enum MainColors {
  zmlBlue = '#1EBCD4', // TODO: Verify color using on retailers back office
  zmlSuccessGreen = '#4CAF50', // TODO: Verify color using on retailers back office

  grey50 = '#E9EEF2',
  grey70 = '#f5f5f5',
  grey100 = '#CBD9E2',
  grey200 = '#E5E5E5',
  grey300 = '#bdbdbd',
  grey400 = '#A9AFBB',
  grey500 = '#eaeef280',
  grey700 = '#3c4858',
  grey800 = '#333333',
  grey900 = '#252525',
  blue300 = '#76FFFF',
  blue400 = '#594f99',
  blue500 = '#69BBD9',
  blue800 = '#0e2645',
  red100 = '#f44336',
  pink400 = '#924f8d',
  pink500 = '#E83675',
  pink700 = '#c02169',
  pink800 = '#AD2173',
  orange200 = '#e98623',
  blue900 = '#9f27b024',
  white = '#fff',
  boxShadow = '#0000003d',
  black14Opacity = 'rgba(0, 0, 0, 0.14)',
}

export enum DefaultColors {
  primary = '#c02169',
}

export enum BenefitColors {
  primary = '#594F99',
}

export enum NetworkColors {
  primary = '#244A8F',
  chart = '#4F6EA5',
}

export enum StoreColors {
  primary = '#e98623',
}
