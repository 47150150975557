import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { CircularProgress, Stack } from '@mui/material';
import urlcat from 'urlcat';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'hooks/translation';
import { useForm } from 'hooks/form';
import { Dispatch, RootState } from 'store';
import { TextFieldForm } from 'components/Forms/TextFieldForm';
import { getAllCategories } from 'repositories/categories';
import { Dropdown } from 'components/Forms/Dropdown';
import { getNetworkByDomainName } from 'repositories/networks';
import { getHostname, apiErrorHandler, getI18nNamespace } from 'utils/helpers';
import { createRetailer } from 'repositories/retailers';
import { createStore } from 'repositories/stores';
import { yup } from 'services/yup';
import paths, { ZmyleLinks } from 'utils/paths';

import { getThemeStyleBackOffice } from 'styles/helpers';
import { useBackOfficeTheme } from 'hooks/theme';
import { useRetailer } from 'hooks/retailer';
import {
  CheckboxStyled,
  Container,
  HighlightLink,
  RegisterStoreButton,
} from './NewStoreForm.styled';

interface NewStoreForm {
  businessName: string;
  addressStreet: string;
  addressCity: string;
  addressZipcode: string;
  categoryId: string;
  acceptedTerms: boolean;
}

const initialValues: NewStoreForm = {
  businessName: '',
  addressStreet: '',
  addressCity: '',
  addressZipcode: '',
  categoryId: '',
  acceptedTerms: false,
};
interface DropdownOptions {
  name: string;
  options: Array<{
    name: string;
    id: string;
  }>;
}

const componentsTranslator = getI18nNamespace('components');

const validationSchemaBase = {
  businessName: yup
    .string()
    .label(componentsTranslator('newStoreForm.businessName'))
    .required(),
  addressStreet: yup
    .string()
    .label(componentsTranslator('newStoreForm.addressStreet'))
    .required(),
  addressCity: yup
    .string()
    .label(componentsTranslator('newStoreForm.addressCity'))
    .required(),
  addressZipcode: yup
    .string()
    .label(componentsTranslator('newStoreForm.addressZipcode'))
    .required(),
  categoryId: yup
    .string()
    .label(componentsTranslator('newStoreForm.category'))
    .required(),
};

interface Props {
  onFinishSubmit?(): void;
  variant: 'standard' | 'filled' | 'outlined';
  retailerId?: string;
}

export const NewStoreForm = (props: Props) => {
  const { variant, onFinishSubmit, retailerId = null } = props;

  const [loading, setLoading] = useState(false);
  const [isLoadingNetwork, setIsLoadingNetwork] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [network, setNetwork] = useState<Network | null>(null);
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions[]>([]);

  const { user } = useSelector((state: RootState) => state.auth);

  const history = useHistory();

  const { changeTheme } = useBackOfficeTheme();

  const { retailers, retailerActions } = useRetailer();

  const { t } = useTranslation('components');

  const dispatch = useDispatch<Dispatch>();

  const hasRetailerId = Boolean(retailerId);

  const showAcceptTerms = !hasRetailerId && !isLoadingNetwork;
  const showLoadingNetwork = !hasRetailerId && isLoadingNetwork;

  const validationSchema = useMemo(() => {
    if (hasRetailerId) {
      return yup.object().shape(validationSchemaBase);
    }

    return yup.object().shape({
      ...validationSchemaBase,
      acceptedTerms: yup
        .boolean()
        .isTrue(componentsTranslator('newStoreForm.acceptTermsTooltip')),
    });
  }, [hasRetailerId]);

  const navigateToStoreProfile = async (store: Store, retailer: Retailer) => {
    retailerActions.SET_CURRENT_RETAILER(retailer);

    const storeProfile = urlcat(
      paths.RETAILER + paths.STORE_DETAILS + paths.PROFILE,
      {
        retailerId: retailer.id,
        storeId: store.id,
      },
    );

    const storeTheme = getThemeStyleBackOffice(storeProfile);

    changeTheme(storeTheme);

    history.push(storeProfile);
  };

  const onSubmit = async (formData: NewStoreForm) => {
    if (!user) return;

    const isNetworkMaster = Boolean(network?.networkType === 'MASTER');

    try {
      setIsRedirecting(true);

      const submitData = async () => {
        if (retailerId) {
          const store = await createStore({
            retailerId,
            name: formData.businessName,
            addressStreet: formData.addressStreet,
            addressCity: formData.addressCity,
            addressZipcode: formData.addressZipcode,
            category: formData.categoryId,
          });

          const retailer = retailers.find(
            findRetailer => findRetailer.id === store.retailer.id,
          );

          if (!retailer) return;

          await navigateToStoreProfile(store, retailer);

          return;
        }

        const retailer = await createRetailer({
          businessName: formData.businessName,
          addressStreet: formData.addressStreet,
          addressCity: formData.addressCity,
          addressZipcode: formData.addressZipcode,
          store: {
            category: formData.categoryId,
            networkId: network && !isNetworkMaster ? network.id : '',
          },
          userId: user.id,
        });

        const [store] = retailer.stores;

        if (!retailer || !store) return;

        await navigateToStoreProfile(store, retailer);
      };

      await submitData();

      const successMessage = t('newStoreForm.createdStoreSuccessMessage', {
        disableParentElement: true,
      });

      dispatch.alert.OPEN_SNACKBAR_SUCCESS(successMessage);

      await dispatch.auth.whoAmI();

      onFinishSubmit?.();
    } catch (error) {
      setIsRedirecting(false);

      apiErrorHandler(error, dispatch);
    }
  };

  const getCategories = async () => {
    try {
      setLoading(true);
      const response = await getAllCategories();
      if (!response) return;

      const modeledResponse = response.map(items => ({
        name: items.name,
        options: items.children.map(item => ({ name: item.name, id: item.id })),
      }));

      setDropdownOptions(modeledResponse);
    } catch (error) {
      apiErrorHandler(error, dispatch);
    } finally {
      setLoading(false);
    }
  };

  const getNetworkData = async () => {
    try {
      if (hasRetailerId) return;

      const hostname = getHostname();

      if (!hostname) return;

      setIsLoadingNetwork(true);

      const networkData = await getNetworkByDomainName(hostname);

      if (!networkData) return;

      setNetwork(networkData);
    } catch (error) {
      apiErrorHandler(error, dispatch);
    } finally {
      setIsLoadingNetwork(false);
    }
  };

  const { getFieldProps, handleSubmit, values, setValue } =
    useForm<NewStoreForm>({
      initialValues,
      onSubmit,
      validationSchema,
    });

  useEffect(() => {
    getCategories();
    getNetworkData();
  }, []);

  const handleChangeAcceptedTerms = () => {
    setValue('acceptedTerms', !values.acceptedTerms);
  };

  const getCheckboxLabel = () => {
    if (network?.networkType === 'COMMUNITY') {
      return (
        <Trans
          ns="components"
          i18nKey="newStoreForm.acceptCommunityTermsLabel"
          components={{
            'retailer-terms': (
              <HighlightLink
                href={`${window.location.origin}/${ZmyleLinks.RETAILER_TERMS}`}
                target="_blank"
              />
            ),
            'community-terms': (
              <HighlightLink
                href={`${window.location.origin}/${ZmyleLinks.COMMUNITY_TERMS}`}
                target="_blank"
              />
            ),
            'privacy-terms': (
              <HighlightLink href={ZmyleLinks.PRIVACY_POLICY} target="_blank" />
            ),
          }}
        />
      );
    }

    return (
      <Trans
        ns="components"
        i18nKey="newStoreForm.acceptRetailerTermsLabel"
        components={{
          'retailer-terms': (
            <HighlightLink href={ZmyleLinks.RETAILER_TERMS} target="_blank" />
          ),
          'privacy-terms': (
            <HighlightLink href={ZmyleLinks.PRIVACY_POLICY} target="_blank" />
          ),
        }}
      />
    );
  };

  return (
    <Container variant={variant ?? ''}>
      <form onSubmit={handleSubmit} noValidate data-testid="new-store-form">
        <TextFieldForm
          {...getFieldProps('businessName')}
          variant={variant}
          required
          label={t('newStoreForm.businessName')}
          autoComplete="name"
          autoFocus
          inputProps={{ 'data-testid': 'business-name-input' }}
        />
        <TextFieldForm
          {...getFieldProps('addressStreet')}
          variant={variant}
          required
          label={t('newStoreForm.addressStreet')}
          autoComplete="street"
          inputProps={{ 'data-testid': 'street-address-input' }}
        />

        <TextFieldForm
          {...getFieldProps('addressZipcode')}
          variant={variant}
          required
          label={t('newStoreForm.addressZipcode')}
          autoComplete="zipcode"
          inputProps={{ 'data-testid': 'zipcode-input' }}
        />

        <TextFieldForm
          {...getFieldProps('addressCity')}
          variant={variant}
          required
          label={t('newStoreForm.addressCity')}
          autoComplete="city"
          inputProps={{ 'data-testid': 'city-name-input' }}
        />

        <Dropdown
          {...getFieldProps('categoryId')}
          variant={variant}
          isLoading={loading}
          label={t('newStoreForm.category')}
          listSubheader={dropdownOptions}
          data-testid="group-select"
        />

        {showLoadingNetwork && (
          <Stack width="100%" alignItems="center" mt="1rem" minHeight="3.85rem">
            <CircularProgress color="primary" size="1rem" />
          </Stack>
        )}

        {showAcceptTerms && (
          <CheckboxStyled
            {...getFieldProps('acceptedTerms')}
            onClick={handleChangeAcceptedTerms}
            checked={values.acceptedTerms}
            data-testid="accepted-terms-checkbox"
            inputProps={
              {
                'data-testid': 'accepted-terms-input',
              } as KeyValue
            }
            label={getCheckboxLabel()}
          />
        )}

        <Stack alignItems="center" justifyContent="center" p="1.5rem" pb="1rem">
          <RegisterStoreButton
            data-testid="register-new-store-button"
            isLoading={isRedirecting}
            fullWidth
            type="submit"
          >
            {t('newStoreForm.register')}
          </RegisterStoreButton>
        </Stack>
      </form>
    </Container>
  );
};
