export enum ApiErrorId {
  REQUIRES_AUTHENTICATION = 'api:biz:requiresAuthentication',
  CHECKOUT_DISABLED = 'api:biz:checkoutDisabledError',
  IDEMPOTENT_REQUEST_BUSY = 'api:biz:idempotentRequestBusy',
  CANT_ACTIVATE_SPONSORED_GIFTCARD = 'api:biz:cantActivateSponsoredGiftcard',
  INVALID_DATE_RANGE = 'api:io:invalidDateRange',
  TOO_MANY_REQUESTS = 'api:biz:tooManyRequests',
  PDF_SERVICE_ERROR = 'api:biz:pdfServiceError',
}

export enum BookingReasons {
  OK = 'OK',
  AMOUNT_EXCEEDED = 'AMOUNT_EXCEEDED',
  COUNT_EXCEEDED = 'COUNT_EXCEEDED',
  INVALID_BENEFIT_TYPE = 'INVALID_BENEFIT_TYPE',
  NEEDS_EMPLOYEE_EMAIL = 'NEEDS_EMPLOYEE_EMAIL',
  NEEDS_DELIVERY = 'NEEDS_DELIVERY',
  GIFTCARD_IS_FULL = 'GIFTCARD_IS_FULL',
  INVALID_DATE_RANGE = 'api:io:invalidDateRange',
}
