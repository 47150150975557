import { createModel } from '@rematch/core';
import type { RootModel } from '../model';

export const loading = createModel<RootModel>()({
  state: {
    screen: false,
    page: false,
  },
  reducers: {
    SET_SCREEN: (state, payload: boolean) => {
      return {
        ...state,
        screen: payload,
      };
    },
    SET_PAGE: (state, payload: boolean) => {
      return {
        ...state,
        page: payload,
      };
    },
  },
});
