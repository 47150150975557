import { config } from 'services/config';

export const getSearchParams = <T = KeyValue>(params: string) => {
  if (!params) return null;

  const searchParams = params.replace('?', '').split('&');

  const objectParams = searchParams.reduce((acc, curr) => {
    const [key, value] = curr.split('=');

    return {
      ...acc,
      [key]: decodeURI(value),
    };
  }, {});

  return objectParams as T;
};

export const genQueryParams = (params: KeyValue) => {
  const queryParams = Object.entries(params).reduce((acc, current, index) => {
    const prefix = index > 0 ? '&' : '';
    const [key, value] = current;

    const encodedValue = encodeURI(value);

    return `${acc}${prefix}${key}=${encodedValue}`;
  }, '');

  return queryParams;
};

export const getCurrentRoutePath = (pathname: string) => {
  const matched = pathname.match(/(\/\w+)$/);

  if (!matched) return '';

  return matched[0];
};

export const isValidUrl = (url: string) => {
  try {
    const urlInstance = new URL(url);

    return Boolean(urlInstance.href);
  } catch {
    return false;
  }
};

export const getDomainFromUrl = (website: string) => {
  const regexUrlPrefix = /^(?:https?:\/\/)?(?:www\.)?/i;

  return website?.replace(regexUrlPrefix, '');
};

export const extractTextFromHTML = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const text = div.textContent || div.innerText || '';
  return text;
};

export const getHostname = () => {
  if (config.isDevelopment) {
    return config.networkHostname || window.location.origin;
  }

  return window.location.origin;
};

export const isInternetExplorer = () => {
  if (!window) return false;

  const { userAgent } = window.navigator;
  const msie = userAgent.indexOf('MSIE '); // IE 10 or older
  const trident = userAgent.indexOf('Trident/'); // IE 11

  return msie > 0 || trident > 0;
};
