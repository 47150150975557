import styled from 'styled-components';
import { drawerWidth } from 'components/Sidebar/Sidebar.styled';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

interface Props {
  isOpen?: boolean;
}

const totalLateralPadding = '1.938rem';
const containerWidth = `calc(100vw - ${drawerWidth} - ${totalLateralPadding})`;

export const BackofficeLayoutStyled = styled.div<Props>`
  background-color: ${({ theme }) => theme.palette.zml.grey50};

  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;

  .MuiContainer-root {
    margin-left: ${props => (props.isOpen ? drawerWidth : 0)};
    max-width: ${props => (props.isOpen ? containerWidth : '100%')};
    padding: 0 0 0 1.3rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 100vh;

    > div {
      height: 100%;
    }

    ${MOBILE_BREAKPOINT} {
      padding: 0 1rem;
    }
  }
`;
