import { TOptions, KeyPrefix, TFunction, Namespace } from 'i18next';

import i18n from 'services/i18n';

export const getLanguage = (): 'en-US' | 'de-DE' => {
  return i18n.language === 'en-US' ? 'en-US' : 'de-DE';
};

export const getI18nNamespace =
  <N extends Namespace, K extends KeyPrefix<N>, O extends TOptions>(
    namespace: N,
  ): TFunction<N> =>
  // @ts-expect-error: TFunctionReturn is not assignable to TFunction
  (key: K, options?: O) => {
    // @ts-expect-error: Hack to make types work
    return i18n.t(key, { ns: namespace, ...options });
  };
