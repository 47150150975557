import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getThemeStyleBackOffice } from 'styles/helpers';
import { Dispatch, RootState } from 'store';

export const useBackOfficeTheme = () => {
  const currentTheme = useSelector(
    (state: RootState) => state.theme.backOffice,
  );
  const dispatch = useDispatch<Dispatch>();

  const { pathname } = useLocation();

  const changeTheme = (theme: ThemeStyleBackOffice) => {
    dispatch.theme.CHANGE_BACKOFFICE_THEME(theme);
  };

  useEffect(() => {
    const backOfficeTheme = getThemeStyleBackOffice(pathname);

    if (backOfficeTheme.name === currentTheme.name) return;

    changeTheme(backOfficeTheme);
  }, [pathname]);

  return { ...currentTheme, changeTheme };
};
