import styled, { css } from 'styled-components';

interface HeaderRIghtButtonStyledProps {
  color?: keyof ZmyleThemeColors | 'primary';
}

export const HeaderRightButtonStyled = styled.button<HeaderRIghtButtonStyledProps>`
  display: flex;
  align-items: center;

  background: transparent;
  border: 0;

  color: ${({ theme }) => theme.palette.primary.main};

  svg {
    margin: 0;
    margin-right: 1rem;
    color: ${({ theme }) => theme.palette.primary.main};
  }

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ${({ color, theme }) =>
    color &&
    color !== 'primary' &&
    css`
      color: ${theme.palette.zml[color]};

      svg {
        color: ${theme.palette.zml[color]};
      }
    `};
`;
