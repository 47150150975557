import type { Transformer } from '@lexical/markdown';
import {
  CHECK_LIST,
  ELEMENT_TRANSFORMERS,
  TEXT_FORMAT_TRANSFORMERS,
  TEXT_MATCH_TRANSFORMERS,
  ORDERED_LIST,
  UNORDERED_LIST,
} from '@lexical/markdown';

export const TRANSFORMERS: Transformer[] = [
  CHECK_LIST,
  ORDERED_LIST,
  UNORDERED_LIST,
  ...ELEMENT_TRANSFORMERS,
  ...TEXT_FORMAT_TRANSFORMERS,
  ...TEXT_MATCH_TRANSFORMERS,
];
