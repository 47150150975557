import styled from 'styled-components';

export const OptionsContainer = styled.div`
  padding: 1rem;
`;

export const OptionButtonStyled = styled.button`
  display: flex;
  flex-direction: column;
  min-height: 6.25rem;
  width: 100%;

  border: none;
  border-radius: 0.25rem;

  padding: 1rem 1rem 0 1rem;
  margin-top: 1rem;

  background-color: ${({ theme }) => theme.palette.zml.grey50};
  color: ${({ theme }) => theme.palette.zml.blue800};
  box-shadow: 0 0.188rem 0.375rem
    ${({ theme }) => theme.palette.zml.black14Opacity};

  & + & {
    margin-top: 2rem;
  }

  > span {
    display: flex;
    align-items: center;

    svg {
      color: ${({ theme }) => theme.palette.zml.grey400};
      margin-right: 2rem;
    }

    strong {
      font-size: 1.125rem;
    }
  }

  :hover {
    box-shadow: 0 0.188rem 0.375rem ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  > span {
    align-self: center;
    margin: 1rem 0 1rem 0;

    svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
