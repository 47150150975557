const today = new Date();
const quarterlyMonths: Readonly<number[]> = [0, 3, 6, 9];

export const isQuarterlyMonth = (date: Date) => {
  return quarterlyMonths.includes(date.getMonth());
};

export const getMinQuarterlyDate = () => {
  const todayMonth = today.getMonth();

  const closestAllowedMonthIndex = quarterlyMonths.reduce(
    (acc, curr, index) => {
      if (Math.abs(curr - todayMonth) < Math.abs(acc - todayMonth)) {
        return index;
      }

      return acc;
    },
    0,
  );

  const isCurrentMonthQuarterly = isQuarterlyMonth(today);

  if (isCurrentMonthQuarterly) return today;

  const closestAllowedMonth = new Date().setMonth(
    quarterlyMonths[closestAllowedMonthIndex],
  );

  const firstDayOfClosestAllowedMonth = new Date(closestAllowedMonth).setDate(
    1,
  );

  return new Date(firstDayOfClosestAllowedMonth);
};
