import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

const LogoImg = styled.img`
  width: 15rem;
  margin-bottom: 1.5rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2rem;
`;

const PresentationSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.zml.white};

  > div {
    max-width: 26.938rem;

    h1 {
      font-size: 2.5rem;
      font-weight: 700;
      padding-bottom: 1.563rem;
      font-family: 'Roboto Slab', serif;
      max-width: 14.125rem;
    }

    p {
      font-weight: 400;
      margin: 0 0 0.5rem;
      font-size: 1.125rem;
    }
  }

  ${MOBILE_BREAKPOINT} {
    display: none;
  }
`;

const ContentSection = styled.div`
  background: ${({ theme }) => theme.palette.zml.grey50};

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.MOBILE}px) {
    width: 100%;
    padding: 0 1rem;
  }

  > footer {
    margin-bottom: 0.2rem;

    ul {
      justify-content: center;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1.5rem;
  max-width: 28.125rem;
  min-height: 28.125rem;
  width: 100%;
  margin: auto 0;
  gap: 1.5rem;

  ${MOBILE_BREAKPOINT} {
    padding-top: 0;
    margin-top: 3rem;
  }
`;

const Content = styled.div`
  padding: 1.938rem 1.625rem;

  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.875rem;

  border: 0;
  color: ${({ theme }) => theme.palette.zml.blue800};
  background: ${({ theme }) => theme.palette.zml.white};
  box-shadow: 0 0.063rem 0.25rem 0 ${({ theme }) => theme.palette.zml.grey400};
  border-radius: 0.375rem;
`;

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 100vh;
  overflow: hidden auto;

  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: 100vh;

  // IE 11 grid setup
  div:nth-child(1) {
    grid-column: 1;
    -ms-grid-column: 1;
    grid-row: 1;
    -ms-grid-row: 1;
  }

  div:nth-child(2) {
    grid-column: 2;
    -ms-grid-column: 2;
    grid-row: 1;
    -ms-grid-row: 1;
  }

  ${MOBILE_BREAKPOINT} {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;

    div:nth-child(2) {
      grid-column: 1;
      -ms-grid-column: 1;
      grid-row: 1;
      -ms-grid-row: 1;
    }
  }
`;

export const AuthLayout = {
  LogoImg,
  Title,
  PresentationSection,
  ContentSection,
  ContentContainer,
  Content,
  PageContainer,
} as const;
