import { formatDateToApp, parseDateFromServer } from 'utils/helpers';

export const apiTenantAdapter = (data: ApiTenant): AdaptedApiTenant => {
  return {
    ...data,
    formatted: {
      createdAt: formatDateToApp(parseDateFromServer(data.createdAt)),
    },
  };
};

export const partnerApiKeyAdapter = (
  data: PartnerApiKey,
): AdaptedPartnerApiKey => {
  return {
    ...data,
    formatted: {
      createdAt: formatDateToApp(parseDateFromServer(data.createdAt)),
    },
  };
};
