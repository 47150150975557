import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LinkToDashboard = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 0.7rem 0 1rem 0;
  font-weight: 500;

  :hover {
    filter: brightness(0.6);
  }
`;

export const CreateStoreHeader = styled.div<{ showNewStoreForm: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;

  h4 {
    width: 100%;
    text-align: center;
    margin-right: ${({ showNewStoreForm }) => (showNewStoreForm ? '25px' : '')};
  }

  svg {
    color: ${({ theme }) => theme.palette.zml.blue800};

    :hover {
      cursor: pointer;
      color: ${({ theme }) => theme.palette.zml.blue400};
    }
  }
`;

export const CardContainer = styled.div`
  margin-bottom: 1.5rem;
  padding: 1.938rem 1.625rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 23.75rem;
  font-size: 0.875rem;
  align-self: center;
  justify-self: center;

  border: 0;
  color: ${({ theme }) => theme.palette.zml.blue800};
  background: ${({ theme }) => theme.palette.zml.white};
  box-shadow: 0 0.063rem 0.25rem 0 ${({ theme }) => theme.palette.zml.grey400};
  border-radius: 0.375rem;
`;

export const SelectStoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const StoreButton = styled(Button)`
  border-color: ${({ theme }) => theme.palette.zml.orange200};
  color: ${({ theme }) => theme.palette.zml.orange200};
`;

export const NewStoreContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

export const OR = styled.div`
  align-self: center;
  background-color: #cbd9e2;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  padding: 1.5rem;
  font-weight: 500;
  color: white;
  margin: 1.4rem 0;

  :before {
    content: 'OR';
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    font-size: 1.25rem;
  }
`;

export const Title = styled.h4`
  font-weight: 400;
`;
