import { MenuList } from '@mui/material';
import { ProfileSidebarMenu } from 'components/Topbar/components/ProfileSidebarMenu';
import { useResponsiveness } from 'hooks/responsiveness';

import { Divider } from '../../Sidebar.styled';

// import { SearchInput } from 'components/Topbar/components/SearchInput';

export const TopbarMenu = () => {
  const { isMobile } = useResponsiveness();

  if (!isMobile) return null;

  return (
    <MenuList>
      {/* <SearchInput onClick={handleClickSearch} isSidebar fullWidthOnMobile /> */}

      <Divider />

      <ProfileSidebarMenu />

      <Divider />
    </MenuList>
  );
};
