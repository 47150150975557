import { ComponentProps, ElementType } from 'react';
import { IconType } from 'react-icons';

import { IconContainer } from './Icon.styled';

interface Props extends ComponentProps<ElementType> {
  icon: IconType;
  size?: `${number}${'rem' | 'px'}` | number;
  color?: keyof ZmyleThemeColors | 'primary';
}

export type IconProps = Props;

/**
 * @description
 * Component created to solve Firefox issue in older versions with height and width
 * attributes that doesn't support REM in SVGs.
 */

export const Icon = (props: Props) => {
  const {
    icon: IconComponent,
    size = '1rem',
    color = 'blue800',
    ...rest
  } = props;

  const iconSize = typeof size === 'number' ? `${size / 16}rem` : size;

  return (
    <IconContainer size={iconSize} color={color} {...rest}>
      <IconComponent />
    </IconContainer>
  );
};
