import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 3.125rem;

  > img {
    height: 1.875rem;
    margin-right: auto;
  }
`;

export const PageTitleContainer = styled.div`
  display: flex;
`;

export const Logo = styled.img`
  && {
    height: 2.25rem;
  }
`;
