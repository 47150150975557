import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

export const useNetwork = () => {
  const network = useSelector(
    (state: RootState) => state.network.currentNetwork,
  );
  const networks = useSelector((state: RootState) => state.network.networks);
  const signedInNetwork = useSelector(
    (state: RootState) => state.network.signedInNetwork,
  );

  const dispatch = useDispatch<Dispatch>();

  return {
    network,
    networks,
    signedInNetwork,
    networkActions: dispatch.network,
  };
};
