import { ListItemText } from '@mui/material';
import { IoMdPerson } from 'react-icons/io';
import { BsBoxArrowInRight } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaExchangeAlt } from 'react-icons/fa';
import { useTranslation } from 'hooks/translation';
import { Dispatch } from 'store';
import { IconContainer as SidebarIconContainer } from 'components/Sidebar/Sidebar.styled';
import paths from 'utils/paths';
import { Icon } from 'components/Icon';
import { ListItemStyled } from '../Topbar.styled';

export const ProfileSidebarMenu = () => {
  const history = useHistory();

  const { t } = useTranslation('components');
  const dispatch = useDispatch<Dispatch>();

  const handleGoToProfile = () => {
    history.push(paths.PROFILE);
  };

  const handleSignOut = async () => {
    await dispatch.auth.signOut();
  };

  const handleNavigateToDashboard = () => {
    history.push(paths.DASHBOARD);
  };

  return (
    <>
      <ListItemStyled button onClick={handleNavigateToDashboard}>
        <SidebarIconContainer>
          <Icon icon={FaExchangeAlt} size="1.3rem" />
        </SidebarIconContainer>

        <ListItemText>{t('sidebar.yourAccounts')}</ListItemText>
      </ListItemStyled>

      <ListItemStyled button onClick={handleGoToProfile}>
        <SidebarIconContainer>
          <Icon icon={IoMdPerson} size="1.5rem" />
        </SidebarIconContainer>

        <ListItemText>{t('sidebar.profile')}</ListItemText>
      </ListItemStyled>

      <ListItemStyled button onClick={handleSignOut}>
        <SidebarIconContainer>
          <Icon icon={BsBoxArrowInRight} size="1.5rem" />
        </SidebarIconContainer>

        <ListItemText>{t('sidebar.logout')}</ListItemText>
      </ListItemStyled>
    </>
  );
};
