/* eslint-disable import/no-duplicates */
import { format } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { formatCurrency } from 'utils/currency';
import {
  formatDateToApp,
  getLanguage,
  parseDateFromServer,
} from 'utils/helpers';

const companiesPlaceholder: AdaptedNetworkStatistics['companies'] = {
  all: {
    num: 0,
  },
};

const storesPlaceholder: AdaptedNetworkStatistics['stores'] = {
  outlets: {
    num: 0,
    numHidden: 0,
  },
  vendors: {
    num: 0,
    numHidden: 0,
  },
};

const giftcardsPlaceholder: AdaptedNetworkStatistics['giftcards'] = {
  buckets: [],
  bucketsFromDate: '',
  bucketsToDate: '',
  overallEffectiveDate: '',
  overallStats: {
    transactions: {
      balance: 0,
    },
    credits: {
      sum: 0,
    },
    debits: {
      sum: 0,
    },
  },
  bucketsSummaryStats: {
    credits: { sum: 0, num: 0 },
    debits: { sum: 0, num: 0 },
    transactions: { sum: 0, num: 0 },
    cancellations: { sum: 0, num: 0 },
    refunds: { sum: 0, num: 0 },
    depreciations: { sum: 0, num: 0 },
  },
  formatted: {
    bucketsFromDate: '',
    bucketsToDate: '',
    overallEffectiveDate: '',
    overallStats: {
      transactions: {
        balance: '',
      },
      credits: {
        sum: '',
      },
      debits: {
        sum: '',
      },
    },
    bucketsSummaryStats: {
      transactions: { sum: '' },
      credits: { sum: '' },
      cancellations: { sum: '' },
      debits: { sum: '' },
      refunds: { sum: '' },
      depreciations: { sum: '' },
    },
  },
};

export const networkStatisticsAdapter = (
  statistics: NetworkStatistics,
): AdaptedNetworkStatistics => {
  const giftcards = { ...giftcardsPlaceholder, ...statistics.giftcards };

  const { overallStats, bucketsSummaryStats } = giftcards;

  return {
    ...statistics,
    companies: {
      ...companiesPlaceholder,
      ...statistics.companies,
    },
    stores: {
      ...storesPlaceholder,
      ...statistics.stores,
    },
    giftcards: {
      ...giftcards,
      buckets: giftcards.buckets.map(bucket => ({
        ...bucket,
        formatted: {
          toDate: formatDateToApp(bucket.toDate),
          toDateWeekDay: format(parseDateFromServer(bucket.toDate), 'iii', {
            locale: getLanguage() === 'en-US' ? enUS : de,
          }),
          bucketStats: {
            transactions: {
              sum: formatCurrency(bucket.bucketStats.transactions.sum / 100),
            },
            credits: {
              sum: formatCurrency(bucket.bucketStats.credits.sum / 100),
            },
            cancellations: {
              sum: formatCurrency(bucket.bucketStats.cancellations.sum / 100),
            },
            debits: {
              sum: formatCurrency(bucket.bucketStats.debits.sum / 100),
            },
            refunds: {
              sum: formatCurrency(bucket.bucketStats.refunds.sum / 100),
            },
            depreciations: {
              sum: formatCurrency(bucket.bucketStats.depreciations.sum / 100),
            },
          },
        },
      })),
      formatted: {
        ...giftcardsPlaceholder.formatted,
        bucketsFromDate: formatDateToApp(giftcards.bucketsFromDate),
        bucketsToDate: formatDateToApp(giftcards.bucketsToDate),
        overallEffectiveDate: formatDateToApp(giftcards.overallEffectiveDate),
        overallStats: {
          ...giftcardsPlaceholder.formatted.overallStats,
          transactions: {
            ...giftcardsPlaceholder.formatted.overallStats.transactions,
            balance: formatCurrency(
              (overallStats?.transactions.balance || 0) / 100,
            ),
          },
          credits: {
            ...giftcardsPlaceholder.formatted.overallStats.credits,
            sum: formatCurrency((overallStats?.credits.sum || 0) / 100),
          },
          debits: {
            ...giftcardsPlaceholder.formatted.overallStats.debits,
            sum: formatCurrency((overallStats?.debits.sum || 0) / 100),
          },
        },
        bucketsSummaryStats: {
          ...giftcardsPlaceholder.formatted.bucketsSummaryStats,
          transactions: {
            sum: formatCurrency(
              (bucketsSummaryStats?.transactions.sum || 0) / 100,
            ),
          },
          credits: {
            sum: formatCurrency((bucketsSummaryStats?.credits.sum || 0) / 100),
          },
          cancellations: {
            sum: formatCurrency(
              (bucketsSummaryStats?.cancellations.sum || 0) / 100,
            ),
          },
          debits: {
            sum: formatCurrency((bucketsSummaryStats?.debits.sum || 0) / 100),
          },
          refunds: {
            sum: formatCurrency((bucketsSummaryStats?.refunds.sum || 0) / 100),
          },
          depreciations: {
            sum: formatCurrency(
              (bucketsSummaryStats?.depreciations.sum || 0) / 100,
            ),
          },
        },
      },
    },
  };
};
