import styled from 'styled-components';

interface IconContainerProps {
  size: string;
  color: keyof ZmyleThemeColors | 'primary';
}

export const IconContainer = styled.span<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme, color }) =>
    color === 'primary'
      ? theme.palette.primary.main
      : theme.palette.zml[color]};

  svg {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    margin: 0;
  }
`;
