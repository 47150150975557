import { Suspense, useEffect } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { useSelector } from 'react-redux';
import { lazyWithRetry } from 'utils/lazyWithRetry';
import paths from 'utils/paths';
import { SuspenseLoading } from 'components/LoadingScreen';
import { RootState } from 'store';
import { useRetailer } from 'hooks/retailer';
import { RedirectNotFound } from './RedirectNotFound';

const RetailerDashboard = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-dashboard" */ 'pages/Retailers/RetailerDashboard'
    ),
);
const Transactions = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-transactions" */ 'pages/Retailers/Transactions'
    ),
);

const TransactionDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-transaction" */ 'pages/Retailers/TransactionDetails'
    ),
);

const GiftcardDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-giftcard-details" */ 'pages/Retailers/GiftcardDetails'
    ),
);

const Stores = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "retailer-stores" */ 'pages/Retailers/Stores'),
);

const StoreProfile = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-store-profile" */ 'pages/Retailers/StoreProfile'
    ),
);

const StoreSettings = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-store-settings" */ 'pages/Retailers/StoreSettings'
    ),
);

const Team = lazyWithRetry(
  () => import(/* webpackChunkName: "retailer-team" */ 'pages/Retailers/Team'),
);

const Settings = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-settings" */ 'pages/Retailers/Settings'
    ),
);

const ApiKeys = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-api-keys" */ 'pages/Retailers/ApiKeys'
    ),
);

const ApiKeyDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-api-key-details" */ 'pages/Retailers/ApiKeyDetails'
    ),
);

const PartnerApiKeyDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-partner-api-key-details" */ 'pages/Retailers/PartnerApiKeyDetails'
    ),
);

const TeamMemberDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-team-member" */ 'pages/Retailers/TeamMemberDetails'
    ),
);

const Billing = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "retailer-billing" */ 'pages/Retailers/Billing'
    ),
);

const renderLoading = () => <SuspenseLoading />;

interface Params {
  retailerId?: string;
}

export const RetailerRoutes = () => {
  const { path } = useRouteMatch();

  const params = useParams<Params>();
  const history = useHistory();

  const isAppInitialized = useSelector(
    (state: RootState) => state.app.isInitialized,
  );
  const { retailer, retailers, retailerActions } = useRetailer();

  const shouldRenderManagerRoutes = retailer?.authRole === 'MANAGER';
  const shouldRenderAccountantRoutes = retailer?.authRole === 'ACCOUNTANT';

  useEffect(() => {
    if (!isAppInitialized) return;

    if (!params?.retailerId || retailer?.id === params?.retailerId) return;

    const _retailer = retailers.find(
      findRetailer => findRetailer.id === params.retailerId,
    );

    if (!_retailer) return history.replace(paths.DASHBOARD);

    retailerActions.SET_CURRENT_RETAILER(_retailer);
  }, [params?.retailerId, isAppInitialized]);

  return (
    <Suspense fallback={renderLoading()}>
      <Switch>
        <Route path={path + paths.DASHBOARD} component={RetailerDashboard} />
        <Route
          path={path + paths.TRANSACTIONS}
          component={Transactions}
          exact
        />
        <Route
          path={path + paths.TRANSACTION_DETAILS}
          component={TransactionDetails}
          exact
        />

        <Route
          path={path + paths.GIFTCARDS_DETAILS}
          component={GiftcardDetails}
          exact
        />

        <Route
          path={path + paths.STORE_DETAILS + paths.PROFILE}
          component={StoreProfile}
          exact
        />
        <Route
          path={path + paths.STORE_DETAILS + paths.SETTINGS}
          component={StoreSettings}
          exact
        />

        {(shouldRenderAccountantRoutes || shouldRenderManagerRoutes) && (
          <Route
            path={paths.RETAILER + paths.BILLING}
            component={Billing}
            exact
          />
        )}

        {shouldRenderManagerRoutes && (
          <Switch>
            <Route path={path + paths.STORES} component={Stores} exact />

            <Route path={paths.RETAILER + paths.USERS} component={Team} exact />

            <Route
              path={paths.RETAILER + paths.SETTINGS}
              component={Settings}
              exact
            />

            <Route
              path={paths.RETAILER + paths.USER_DETAILS}
              component={TeamMemberDetails}
              exact
            />

            <Route
              path={paths.RETAILER + paths.DEVELOPERS}
              component={ApiKeys}
              exact
            />

            <Route
              path={paths.RETAILER + paths.DEVELOPERS + paths.API_KEYS_DETAILS}
              component={ApiKeyDetails}
              exact
            />

            <Route
              path={
                paths.RETAILER +
                paths.DEVELOPERS +
                paths.PARTNER_API_KEYS_DETAILS
              }
              component={PartnerApiKeyDetails}
              exact
            />
          </Switch>
        )}

        <Route path="*" component={RedirectNotFound} />
      </Switch>
    </Suspense>
  );
};
