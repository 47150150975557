import { useEffect, useState } from 'react';
import { Collapse, List } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { FaExchangeAlt } from 'react-icons/fa';
import { useTranslation } from 'hooks/translation';
import paths from 'utils/paths';
import { nameToInitials } from 'utils/helpers';
import { Icon } from 'components/Icon';
import { useDisclosure } from 'hooks/disclosure';
import { useRetailer } from 'hooks/retailer';
import { useCompany } from 'hooks/company';
import { useNetwork } from 'hooks/network';

import { SidebarType } from '../../types';

import {
  ListItemStyled,
  AvatarStyled,
  ListItemTextStyled,
} from './SliderMenu.styled';

interface CurrentData {
  initials: string;
  name: string;
}
interface Props {
  type: SidebarType;
}

export const SliderMenu = (props: Props) => {
  const { type } = props;

  const { isOpen, onToggle } = useDisclosure();
  const [currentData, setCurrentData] = useState<CurrentData | null>(null);

  const history = useHistory();

  const { t } = useTranslation('components');

  const { retailer } = useRetailer();

  const { network } = useNetwork();

  const { company } = useCompany();

  const getNetworkInitialsAndName = () => {
    if (!network) return null;

    const name = network.networkName;

    const initials = nameToInitials(name);

    return { initials, name };
  };

  const getRetailerInitialsAndName = () => {
    if (!retailer) return null;

    const { contact } = retailer;

    const name = String(contact?.name ?? '');

    const initials = nameToInitials(name);

    return { initials, name };
  };

  const getCompanyInitialsAndName = () => {
    if (!company) return null;

    const { contact } = company;

    const name = String(contact?.name);

    const initials = nameToInitials(name);

    return { initials, name };
  };

  useEffect(() => {
    const resolveMenuData = (): CurrentData | null => {
      if (type === 'network') return getNetworkInitialsAndName();

      if (type === 'company') return getCompanyInitialsAndName();

      return getRetailerInitialsAndName();
    };

    const _currentData = resolveMenuData();

    setCurrentData(_currentData);
  }, [type, network, company, retailer]);

  const handleGoToDashboard = () => {
    history.push(paths.DASHBOARD);
  };

  return (
    <List>
      <ListItemStyled onClick={onToggle}>
        <AvatarStyled>{currentData?.initials}</AvatarStyled>
        <ListItemTextStyled>{currentData?.name}</ListItemTextStyled>
        <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
      </ListItemStyled>

      <Collapse in={isOpen} unmountOnExit>
        <ListItemStyled onClick={handleGoToDashboard}>
          <AvatarStyled>
            <Icon icon={FaExchangeAlt} size="1.3rem" />
          </AvatarStyled>
          <ListItemTextStyled>{t('menu.myAccounts')}</ListItemTextStyled>
        </ListItemStyled>
      </Collapse>
    </List>
  );
};
