import styled from 'styled-components';

export const ImagePreview = styled.div<{ imagePath: string | undefined }>`
  background-image: ${({ imagePath }) =>
    imagePath ? `url(${imagePath})` : ''};
  background-size: 18rem 15rem;
  height: 15rem;
  width: 18rem;
  border-radius: 5%;
`;

export const ImageIcon = styled.div<{ imagePath?: string }>`
  background-image: ${({ imagePath }) =>
    imagePath ? `url(${imagePath})` : ''};
  background-color: ${({ theme }) => theme.palette.zml.grey400};
  border-radius: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  height: 15rem;
  width: 18rem;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  text-align: center;
  color: white;

  svg,
  p {
    color: ${({ theme }) => theme.palette.zml.white};
    mix-blend-mode: difference;

    filter: drop-shadow(
      0 5px 15px ${({ theme }) => theme.palette.zml.boxShadow}
    );

    border-bottom: 1rem;
  }

  img {
    object-fit: cover;
  }

  :hover {
    cursor: pointer;
  }
`;
