import { formatCurrency } from 'utils/currency';
import { divideBy100 } from 'utils/helpers';

export const benefitAdapter = (benefit: Benefit): AdaptedBenefit => {
  return {
    ...benefit,
    formatted: {
      allowedBenefitAmount: formatCurrency(
        divideBy100(benefit.allowedBenefitAmount),
      ),
      maxBenefitAmount: formatCurrency(divideBy100(benefit.maxBenefitAmount)),
    },
  };
};
