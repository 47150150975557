// eslint-disable-next-line import/no-duplicates
import de from 'date-fns/locale/de';
// eslint-disable-next-line import/no-duplicates
import { format, isValid, parse, parseISO } from 'date-fns';
import {
  getTimezoneOffset,
  utcToZonedTime,
  zonedTimeToUtc,
  formatInTimeZone,
} from 'date-fns-tz';
import i18n from 'services/i18n';
import { AppTimezone } from 'services/timezone';

export const parseISODate = (date: string) => {
  const parsedISO = parseISO(date);

  return parsedISO;
};

export const applyBrowserTimezone = (date: Date | string | null) => {
  if (!date) return new Date('Invalid Date');

  const timezoneId = AppTimezone.getInstance().getTimezoneId();

  const zonedDate = utcToZonedTime(date, timezoneId);

  return zonedDate;
};

export const formatDateToISOinUTC = (date: Date | null) => {
  if (!date) return '';

  const utcDate = zonedTimeToUtc(
    date,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const timezoneId = AppTimezone.getInstance().getTimezoneId();

  const timezoneOffset = getTimezoneOffset(timezoneId, utcDate);

  const isoZonedDate = format(
    utcDate.getTime() - timezoneOffset,
    "yyyy-MM-dd'T'HH:mm:ss",
  );

  return isoZonedDate;
};

export const parseDateFromServer = (date?: string | null): Date => {
  if (!date) return new Date('Invalid Date');

  const parsedApiDate = parse(date, "yyyyMMdd'T'HHmmssX", new Date());

  if (isValid(parsedApiDate)) return parsedApiDate;

  const parsedISODate = parseISODate(date);

  if (isValid(parsedISODate)) return parsedISODate;

  return new Date('Invalid Date');
};

export const parseFormattedDate = (date: string) => {
  const dateFormat = i18n.t('form:dateFormat');

  const parsedDate = parse(date, dateFormat, new Date());

  return parsedDate;
};

export const formatDateToAppWithoutTz = (date?: Date | string | null) => {
  const _date = typeof date === 'string' ? parseDateFromServer(date) : date;

  if (!_date || !isValid(_date)) return '';

  const dateFormat = i18n.t('form:dateFormat');

  return format(_date, dateFormat);
};

export const formatDateToApp = (date?: Date | string | null) => {
  if (!date) return '';

  const _date = typeof date === 'string' ? parseDateFromServer(date) : date;

  if (!_date || !isValid(_date)) return '';

  const dateFormat = i18n.t('form:dateFormat');

  const appTimezone = AppTimezone.getInstance();

  const timezoneId = appTimezone.getTimezoneId();

  return formatInTimeZone(_date, timezoneId, dateFormat, { locale: de });
};

export const formatDateAndTimeToApp = (date?: Date | string | null) => {
  if (!date) return '';

  const _date = typeof date === 'string' ? parseDateFromServer(date) : date;

  if (!_date || !isValid(_date)) return '';

  const dateAndTimeFormat = i18n.t('form:dateAndTimeFormat');

  const appTimezone = AppTimezone.getInstance();

  const timezoneId = appTimezone.getTimezoneId();

  return formatInTimeZone(_date, timezoneId, dateAndTimeFormat, { locale: de });
};

export const formatDateToServer = (date: Date) => {
  if (!isValid(date)) return '';

  const appTimezone = AppTimezone.getInstance();

  const timezoneId = appTimezone.getTimezoneId();

  const utcDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000,
  );

  return formatInTimeZone(utcDate, timezoneId, "yyyy-MM-dd'T'hh:mm:ss", {
    locale: de,
  });
};

export const formatDateToServerOldFormat = (date: Date) => {
  if (!isValid(date)) return '';

  const appTimezone = AppTimezone.getInstance();

  const timezoneId = appTimezone.getTimezoneId();

  const utcDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000,
  );

  return formatInTimeZone(utcDate, timezoneId, 'yyyyMMdd', {
    locale: de,
  });
};
