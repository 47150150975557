import styled from 'styled-components';

import { WizardDialog, Button } from 'components';

export const WizardDialogStyled = styled(WizardDialog)`
  div.MuiDialog-paper {
    width: 70rem;
    padding-bottom: 1rem;

    max-width: 100vw;
  }
`;

export const ImagePreview = styled.img`
  max-height: 40rem;
  object-fit: scale-down;
`;

export const ActionButton = styled(Button)`
  padding-inline: 3rem;
`;
