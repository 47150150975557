import { BookingReasons } from 'utils/api';
import { getI18nNamespace } from 'utils/helpers';

export const benefitBookingErrorHandler = (reason: BookingReasons) => {
  const companyTranslator = getI18nNamespace('company');

  switch (reason) {
    case BookingReasons.AMOUNT_EXCEEDED:
      return companyTranslator(
        'benefits.benefitBookDialog.benefitBookErrorMessage.amountExceeded',
      );
    case BookingReasons.COUNT_EXCEEDED:
      return companyTranslator(
        'benefits.benefitBookDialog.benefitBookErrorMessage.countExceeded',
      );
    case BookingReasons.GIFTCARD_IS_FULL:
      return companyTranslator(
        'benefits.benefitBookDialog.benefitBookErrorMessage.giftcardIsFull',
      );
    case BookingReasons.NEEDS_DELIVERY:
      return companyTranslator(
        'benefits.benefitBookDialog.benefitBookErrorMessage.needsDelivery',
      );
    case BookingReasons.NEEDS_EMPLOYEE_EMAIL:
      return companyTranslator(
        'benefits.benefitBookDialog.benefitBookErrorMessage.needsEmployeeEmail',
      );
    case BookingReasons.INVALID_DATE_RANGE:
      return companyTranslator(
        'benefits.benefitBookDialog.benefitBookErrorMessage.invalidDateRange',
      );
    default:
      return companyTranslator(
        'benefits.benefitBookDialog.benefitBookErrorMessage.unHandledError',
      );
  }
};
