import { getI18nNamespace } from 'utils/helpers';

export const giftcardFormFactorAdapter = (
  giftcard: MiniGiftcard,
): AdaptedGiftcard<MiniGiftcard>['formatted']['formFactor'] => {
  const { info, formFactor } = giftcard;

  const isPlasticCard =
    formFactor?.name === 'ISO7810' ||
    Boolean(info?.batchId || giftcard?.batchId);

  const componentsT = getI18nNamespace('components');

  return {
    name: isPlasticCard ? 'ISO7810' : 'DIGITAL',
    description: isPlasticCard
      ? componentsT('giftcardFormFactor.plasticCard')
      : componentsT('giftcardFormFactor.wallet'),
  };
};
