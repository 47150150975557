import styled from 'styled-components';
import { Tooltip, TooltipProps } from '@mui/material';

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: ${({ theme }) => theme.palette.error.main};
  }

  & .MuiTooltip-arrow {
    ::before {
      background-color: ${({ theme }) => theme.palette.error.main};
    }
  }
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-inline: 0.5rem;
  padding: 0;

  svg {
    color: ${({ theme }) => theme.palette.error.main};
  }

  :hover {
    cursor: default;
  }
`;
