import styled from 'styled-components';
import { Avatar } from '@mui/material';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Forms/Checkbox';

export const Title = styled.strong`
  font-size: 1.375rem;
  font-weight: 300;
`;

export const Form = styled.form`
  padding: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1.5rem;
  padding-bottom: 1rem;
`;

export const ButtonStyled = styled(Button)`
  width: 75%;
`;

export const TriggeredElement = styled.button`
  border: none;
  background: transparent;
`;

export const HighlightLink = styled.a`
  transition: 0.2s;
  color: ${({ theme }) => theme.palette.primary.main};

  :hover {
    text-decoration: underline;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  .MuiButtonBase-root {
    margin-block: -0.375rem auto;
  }

  margin-top: 1rem;
`;

export const AvatarStyled = styled(Avatar)`
  background: ${({ theme }) => theme.palette.zml.grey400};
  font-weight: 500;
`;

export const ShowMoreStoresButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.palette.zml.blue800};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
