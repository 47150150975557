import { createModel } from '@rematch/core';
import type { RootModel } from '../model';

interface AppStore {
  isInitialized: boolean;
}

const initialState: AppStore = {
  isInitialized: false,
};

export const app = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setInitialized: (state, payload: boolean) => {
      return {
        ...state,
        isInitialized: payload,
      };
    },
  },
});
