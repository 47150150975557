import axios from 'axios';
import { ApiErrorId } from 'utils/api';
import { store } from 'store';
import { captureException } from 'utils/errors';
import { config } from './config';
import { setupIdempotentRetry } from './idempotentRetry';

const timeout = 1000 * 60; // 60s

export const api = axios.create({
  baseURL: config.middlewareApiRootUrl,
  withCredentials: true,
  timeout,
});

setupIdempotentRetry(api);

api.interceptors.response.use(
  response => response,
  error => {
    if (!error?.response) return Promise.reject(error);

    const { data } = error.response;

    if (data?.errorId === ApiErrorId.REQUIRES_AUTHENTICATION) {
      store.dispatch.auth.signOutByExpiredToken();
    }

    captureException({ error });

    return Promise.reject(error);
  },
);
