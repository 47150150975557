import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { Dispatch, RootState } from 'store';
import { getHostname } from 'utils/helpers';
import { isCompany, isNetwork, isRetailer } from 'utils/paths';
import { AppTimezone } from 'services/timezone';
import { useNetwork } from './network';

const fiveMinutes = 1000 * 60 * 5;
export const useGlobalObservers = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector((state: RootState) => state.auth.user);

  const { signedInNetwork, networkActions } = useNetwork();

  const { pause, start } = useIdleTimer({
    crossTab: { emitOnAllTabs: true },
    timeout: fiveMinutes,
    onIdle: user ? dispatch.auth.whoAmI : undefined,
    startManually: true,
    startOnMount: false,
  });

  useEffect(() => {
    if (!user) return pause();

    start();
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isRetailer(pathname) || isCompany(pathname) || isNetwork(pathname)) {
      return;
    }

    const appTimezone = AppTimezone.getInstance();

    appTimezone.resetTimezoneId();
  }, [pathname]);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        dispatch.loading.SET_SCREEN(true);
        dispatch.app.setInitialized(false);

        const loadSignedInNetwork = async () => {
          const hostname = getHostname();

          if (signedInNetwork) {
            dispatch.theme.buildAuthTheme(signedInNetwork);
          }

          networkActions.getSignedInNetwork(hostname);
        };

        await Promise.all([dispatch.auth.whoAmI(), loadSignedInNetwork()]);
      } finally {
        dispatch.app.setInitialized(true);
        dispatch.loading.SET_SCREEN(false);
      }
    };

    initializeApp();
  }, []);
};
