import { createGlobalStyle } from 'styled-components';
import { MainColors } from './colors';
import { defaultTheme } from './defaultTheme';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
    visibility: hidden;
  }

  body, input, textarea, button {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: ${MainColors.blue800};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background: ${MainColors.grey50};
  }

  html {
    ${defaultTheme.breakpoints.down(
      defaultTheme.breakpoints.values.DESKTOP_SMALL,
    )} {
      font-size: 93.75%;
    }

    ${defaultTheme.breakpoints.down(defaultTheme.breakpoints.values.MOBILE)} {
      font-size: 87.5%;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }

  strong {
    font-weight: 400;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }
`;
