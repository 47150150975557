import {
  ComponentProps,
  forwardRef,
  ReactNode,
  useImperativeHandle,
} from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';

import {
  $convertToMarkdownString,
  $convertFromMarkdownString,
} from '@lexical/markdown';

import { LexicalComposer } from '@lexical/react/LexicalComposer';

import { $setSelection } from 'lexical';
import { ListMaxIndentLevelPlugin } from './plugins/ListMaxIndentLevelPlugin';
import { MarkdownPlugin, TRANSFORMERS } from './plugins/MarkdownPlugin';
import { Toolbar } from './components/Toolbar/Toolbar';
import { ContentEditable } from './components/ContentEditable';

import { nodes } from './nodes';
import { themeConfig } from './themeConfig';

import { Container, Placeholder } from './RichText.styled';
import { LimitMaxEmptyLinesPlugin } from './plugins/LimitMaxEmptyLinesPlugin';

type LexicalComposerProps = ComponentProps<typeof LexicalComposer>;

const richTextConfig: LexicalComposerProps['initialConfig'] = {
  editorState: null,
  namespace: 'rich-text',
  nodes,
  theme: themeConfig,
  onError: (error: Error) => {
    throw error;
  },
};

interface Ref {
  getMarkdownValue(): string;
  setMarkdownValue(value: string): void;
}

export type RichTextRef = Ref;

interface Props {
  placeholder?: ReactNode;
  disableToolbar?: boolean;
}

export const RichText = forwardRef<Ref, Props>((props, ref) => {
  const { placeholder, disableToolbar = false, ...rest } = props;

  const [editor] = useLexicalComposerContext();

  const getMarkdownValue = () => {
    const markdownValue = editor.getEditorState().read(() => {
      return $convertToMarkdownString(TRANSFORMERS);
    });

    const mdValue = markdownValue.replace(/\n\n\n\n/g, '\n\u2063\n');

    return mdValue;
  };

  const setMarkdownValue = (value: string) => {
    editor.update(() => {
      $convertFromMarkdownString(value, TRANSFORMERS);
      $setSelection(null);
    });
  };

  useImperativeHandle(ref, () => ({
    getMarkdownValue,
    setMarkdownValue,
  }));

  return (
    <>
      {!disableToolbar && <Toolbar />}

      <Container {...rest}>
        <RichTextPlugin
          contentEditable={<ContentEditable />}
          placeholder={
            placeholder ? <Placeholder>{placeholder} </Placeholder> : ''
          }
        />

        <ListPlugin />
        <MarkdownPlugin />
        <HistoryPlugin />

        <LimitMaxEmptyLinesPlugin maxEmptyLines={2} />

        <ListMaxIndentLevelPlugin maxDepth={7} />
      </Container>
    </>
  );
});

export const RichTextContext = (props: Partial<LexicalComposerProps>) => {
  const {
    initialConfig = richTextConfig,
    children = <span />,
    ...rest
  } = props;

  return (
    <LexicalComposer initialConfig={initialConfig} {...rest}>
      {children}
    </LexicalComposer>
  );
};
