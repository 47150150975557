import styled from 'styled-components';

export const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 4.65rem;
  margin-right: 0.5rem;

  .MuiIconButton-root {
    margin-right: 0;
    padding: 0.25rem;
  }
`;
