import styled from 'styled-components';

export const NoGiftcardAlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.875rem;
  gap: 1.5rem;
  border-radius: 5px;

  background-color: ${({ theme }) => theme.palette.zml.pink700};

  span {
    align-self: flex-start;
  }

  p {
    color: white;
    font-weight: bold;
    margin-top: 0.75rem;
  }
`;
