export const convertPxToRem = (px: number) => {
  return px / 16;
};

export const getImageDimensions = (file: File): Promise<ImageDimensions> => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();

      img.onload = () => {
        resolve({ height: img.height, width: img.width });
      };

      img.src = URL.createObjectURL(file);
    };

    reader.readAsDataURL(file);
  });
};

interface CheckImageSizeParams {
  minSize: ImageDimensions;
  imageSize: ImageDimensions;
}

export const checkImageSize = (params: CheckImageSizeParams) => {
  const { minSize, imageSize } = params;

  if (imageSize.height < minSize.height || imageSize.width < minSize.width) {
    return false;
  }

  const heightRatio = imageSize.height / minSize.height;
  const widthRatio = imageSize.width / minSize.width;

  if (heightRatio < 1 || widthRatio < 1) return false;

  return true;
};
