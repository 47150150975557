import { createModel } from '@rematch/core';
import { AppTimezone } from 'services/timezone';
import { currencyInstance } from 'utils/currency';
import type { RootModel } from '../model';

interface CurrentRetailerPayload {
  currentRetailer: Retailer | null;
}

interface RetailerStore {
  currentRetailer: Retailer | null;
  retailers: Retailer[];
}

const initialState: RetailerStore = {
  currentRetailer: null,
  retailers: [],
};

export const retailer = createModel<RootModel>()({
  state: initialState,
  reducers: {
    SET_CURRENT_RETAILER: (state, payload: Retailer | null) => {
      if (payload?.currency) currencyInstance.setCurrency(payload.currency);

      const appTimezone = AppTimezone.getInstance();

      if (payload?.contact.region.timezoneId) {
        const { timezoneId } = payload.contact.region;

        appTimezone.setTimezoneId(timezoneId);
      } else {
        appTimezone.resetTimezoneId();
      }

      return {
        ...state,
        currentRetailer: payload,
      };
    },
    SET_RETAILERS: (state, payload: Retailer[]) => {
      return {
        ...state,
        retailers: payload,
      };
    },
  },
  effects: dispatch => ({
    async setCurrentRetailer(payload: CurrentRetailerPayload) {
      dispatch({
        type: 'retailer/SET_CURRENT_RETAILER',
        payload: payload.currentRetailer,
      });
    },
  }),
});
