import axios, { AxiosError } from 'axios';
import { AppSignalClient } from 'services/appSignal';
import { config } from 'services/config';
import { ErrorWhitelist } from 'services/errorWhitelist';
import { ApiErrorId } from './api';
import { getI18nNamespace, isInternetExplorer } from './helpers';

export const isAxiosError = (error: unknown): error is AxiosError<any> => {
  return axios.isAxiosError(error);
};

export const isZmyleApiError = (error: unknown): error is ZmyleApiError => {
  if (typeof error !== 'object' || !error) return false;

  if (isAxiosError(error)) {
    const { data: responseData = {} } = error.response || {};

    return Boolean('errorId' in responseData);
  }

  return Boolean('errorId' in error || {});
};

const isChunkLoadError = (name: string, message: string) => {
  return Boolean(
    message.match(/Loading chunk [\d]+ failed/i) ||
      name.match(/ChunkLoadError/i),
  );
};

const isFilteredErrorMessage = (error: AxiosError<ZmyleApiError> | Error) => {
  const getResponseMessage = () => {
    if (isAxiosError(error) && error.response) {
      return error.response.data?.errorMsg || error.response.statusText;
    }

    return error.message;
  };

  const message = getResponseMessage();

  const filteredMessages = [
    /Request failed with status code 403/gi,
    /Camera not found/gi,
  ];

  return filteredMessages.some(filteredMessage =>
    message.match(filteredMessage),
  );
};

const isRequiresAuthenticationError = (
  error: AxiosError<ZmyleApiError> | Error,
) => {
  if (!isAxiosError(error) || !error.response) return false;

  const { errorId } = error.response.data as ZmyleApiError;

  return errorId && errorId === ApiErrorId.REQUIRES_AUTHENTICATION;
};

const isFilteredErrorId = (error: AxiosError<ZmyleApiError> | Error) => {
  if (!isAxiosError(error) || !error.response) return false;

  const { errorId } = error.response.data as ZmyleApiError;

  const filteredErrorIds = [
    'api:biz:giftcardAlreadyConnected',
    'api:biz:needsSepaMandate',
    'api:biz:poorPasswordError',
    'api:io:invalidIban',
    'api:io:invalidPincodeChars',
    'api:biz:noSuchUser',
    'api:io:invalidDateRange',
    'api:biz:transactionLocked',
    'api:io:malformedId',
    'api:biz:inconsistentGiftcardBatchSize',
    'api:biz:noSuchRetailer',
    'api:biz:noSuchNetwork',
    'api:io:invalidInputParam',
    'api:io:invalidCompanyStatus',
  ];

  return filteredErrorIds.includes(errorId);
};

const isFilteredStatusCode = (error: AxiosError<ZmyleApiError> | Error) => {
  if (!isAxiosError(error) || !error.response) return false;

  const { status } = error.response;

  if (status === 429) return false; // Rate limiter error

  return status >= 400 && status < 500;
};

const isWhitelisted = (error: AxiosError<ZmyleApiError> | Error) => {
  if (!isAxiosError(error) || !isZmyleApiError(error.response?.data)) {
    return false;
  }

  const { errorId } = error.response.data as ZmyleApiError;

  if (errorId === ApiErrorId.TOO_MANY_REQUESTS) return true;

  const { isErrorWhitelisted } = ErrorWhitelist.getInstance();

  return isErrorWhitelisted(errorId);
};

export const shouldFilterError = async (error?: Error | AxiosError) => {
  if (!error) return false;

  const isErrorWhitelisted = await isWhitelisted(error);

  if (isErrorWhitelisted) return false;

  const shouldFilter = [
    isRequiresAuthenticationError(error),
    isChunkLoadError(error.name, error.message),
    isFilteredStatusCode(error),
    isFilteredErrorId(error),
    isFilteredErrorMessage(error),
    isInternetExplorer(),
  ].includes(true);

  return shouldFilter;
};

export const buildErrorInstanceFromZmyleError = (
  error: ZmyleApiError,
): Error => {
  const { errorMsg, errorId, status } = error;

  const errorInstance = new Error(errorMsg);

  errorInstance.name = status >= 400 && status < 500 ? errorId : errorMsg;

  return errorInstance;
};

interface CaptureExceptionParams {
  error: unknown;
  tags?: KeyValue;
}

export const captureException = async (params: CaptureExceptionParams) => {
  const { error, tags } = params;

  const appSignal = AppSignalClient.getInstance();

  if (isAxiosError(error) && error.response) {
    if (await shouldFilterError(error)) return;

    const { data = {} } = error.response as { data: Record<string, any> };

    const getErrorInstance = () => {
      if (isZmyleApiError(data)) return buildErrorInstanceFromZmyleError(data);

      return new Error(error.message);
    };

    const errorInstance = getErrorInstance();

    appSignal.sendError({
      error: errorInstance,
      payload: { error, response: data },
      tags,
    });

    return;
  }

  if (error instanceof Error) {
    if (await shouldFilterError(error)) return;

    appSignal.sendError({
      error,
      payload: error as Record<string, any>,
      tags,
    });

    return;
  }

  const errorInstance = new Error('Unknown Error');

  appSignal.sendError({
    error: errorInstance,
    payload: error as Record<string, any>,
  });
};
interface DispatchManualErrorParams {
  error: InstanceType<ErrorConstructor>;
  tags?: KeyValue;
}

export const dispatchManualError = (params: DispatchManualErrorParams) => {
  if (!config.isProduction) return;

  const { error, tags } = params;

  captureException({ error, tags: { ...tags, manuallyDispatched: true } });
};

export const isApiError = (
  error: Error | AxiosError,
): error is AxiosError<ZmyleApiError> => {
  if (typeof error !== 'object' || !error) return false;

  return Boolean('response' in error && 'data' in (error?.response || {}));
};

interface GetMessageByErrorIdParams {
  errorId: ApiErrorId | string;
}

export const getMessageByErrorId = (
  params: GetMessageByErrorIdParams,
): string | undefined => {
  const { errorId } = params;

  const errorKey = errorId.replace(/^.+:/, '');

  const errorTranslator = getI18nNamespace('error');

  const apiErrorMessages = errorTranslator('error.api', {
    returnObjects: true,
  });

  return apiErrorMessages[errorKey];
};

export const getApiErrorMessage = (error: ZmyleApiError): string => {
  const { errorId = '', errorMsg } = error;

  const handledErrorMessage = getMessageByErrorId({ errorId });

  if (handledErrorMessage) return handledErrorMessage;

  if (errorMsg) return errorMsg;

  const errorTranslator = getI18nNamespace('error');

  const unexpectedErrorMessage = errorTranslator('error.unexpectedError');

  return unexpectedErrorMessage;
};
