import { Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import styled from 'styled-components';

const DialogSize = {
  small: '18.75rem',
  medium: '34.375rem',
};

interface DialogStyledProps {
  size: 'small' | 'medium';
}

export const DialogStyled = styled(Dialog)<DialogStyledProps>`
  div.MuiDialog-paper {
    color: inherit;
    width: ${({ size }) => DialogSize[size]};

    padding: 0.5rem 1rem;
  }

  .MuiDialogContent-root {
    padding: 0;
  }
`;

export const DialogTitleStyled = styled(DialogTitle)`
  padding: 0 0 1rem 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  text-align: center;

  > strong {
    transform: translateY(0.6rem);
    font-size: 1.2rem;
    font-weight: 300;
  }

  button,
  span {
    width: 2rem;
    height: 2rem;
  }
`;

export const DialogActionsStyled = styled(DialogActions)`
  button {
    color: ${({ theme }) => theme.palette.zml.white};

    :hover {
      filter: brightness(0.9);
    }
  }

  button:first-child {
    color: ${({ theme }) => theme.palette.zml.blue800};
  }
`;

export const IconButtonStyled = styled(IconButton)`
  padding: 0.2rem;
`;
