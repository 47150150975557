import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { HideComponent } from 'components/HideComponent';
import { RootState } from 'store';
import { isRetailer, isCompany, isNetwork } from 'utils/paths';
import benefitsLogo from 'assets/img/benefits-logo.svg';
import storeLogo from 'assets/img/stores-logo.svg';
import networkLogo from 'assets/img/networks-logo.svg';
import zmyleLogo from 'assets/img/zmyle-logo.svg';
import { useBackOfficeTheme } from 'hooks/theme';

import { getCurrentRoutePath } from 'utils/helpers';
import { useCompany } from 'hooks/company';
import { useNetwork } from 'hooks/network';
import { useRetailer } from 'hooks/retailer';
import { Drawer, DrawerContent, Logo, Divider } from './Sidebar.styled';
import { MainMenu, SettingsMenu } from './components/Menu';
import { TopbarMenu } from './components/TopbarMenu';
import { SliderMenu } from './components/SliderMenu';
import { SidebarType } from './types';

const backOfficeLogo = {
  network: networkLogo,
  benefit: benefitsLogo,
  store: storeLogo,
  default: zmyleLogo,
};

interface Props {
  open?: boolean;
  anchor?: 'right' | 'left';
  variant?: 'temporary' | 'persistent';
  handleDrawerToggle?: () => void;
}

export const Sidebar = (props: Props) => {
  const { open, anchor, variant = 'persistent', handleDrawerToggle } = props;

  const isLoading = useSelector((state: RootState) => state.loading);

  const { pathname } = useLocation();

  const { retailer } = useRetailer();

  const { company } = useCompany();

  const { network } = useNetwork();

  const currentPath = useMemo(() => {
    const currentRoute = getCurrentRoutePath(pathname);

    return pathname.replace(currentRoute, '');
  }, [pathname]);

  const sidebarType = useMemo<SidebarType | ''>(() => {
    if (company && isCompany(currentPath)) return 'company';

    if (network && isNetwork(currentPath)) return 'network';

    if (retailer && isRetailer(currentPath)) return 'retailer';

    return '';
  }, [company, network, retailer]);

  const authRoles: Record<SidebarType, AuthRole | undefined> = {
    company: company?.authRole,
    network: network?.authRole,
    retailer: retailer?.authRole,
  };

  const authRole: AuthRole = authRoles[sidebarType] || null;

  const isSettingsVisible = authRole === 'MANAGER' || authRole === 'ACCOUNTANT';

  const { name: themeName } = useBackOfficeTheme();

  return (
    <Drawer
      variant={variant}
      open={!isLoading.screen && open}
      anchor={anchor}
      onClose={handleDrawerToggle}
      ModalProps={{ keepMounted: true }}
    >
      <DrawerContent>
        <Logo src={backOfficeLogo[themeName]} alt="Dashboard Logo" />
        <Divider />

        {sidebarType && <SliderMenu type={sidebarType} />}

        {authRole !== 'ACCOUNTANT' && (
          <>
            <Divider />

            <MainMenu toggleDrawer={handleDrawerToggle} />
          </>
        )}

        {isSettingsVisible && (
          <>
            <Divider />

            <SettingsMenu
              toggleDrawer={handleDrawerToggle}
              authRole={authRole}
            />
          </>
        )}

        <HideComponent onTabletUp>
          <TopbarMenu />
        </HideComponent>
      </DrawerContent>
    </Drawer>
  );
};
