import { useCallback, forwardRef, ForwardRefRenderFunction } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { generateNumberMask, currencyInstance } from 'utils/currency';

const CurrencyInput: ForwardRefRenderFunction<HTMLElement, MaskedInputProps> = (
  props,
  ref,
) => {
  const currencyMask = generateNumberMask(currencyInstance.getCurrency);

  const setRef = useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;

      if (typeof ref === 'function') {
        ref(value);
        return;
      }

      if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );

  return <MaskedInput {...props} ref={setRef} mask={currencyMask} />;
};

export const MaskedCurrencyInput = forwardRef(CurrencyInput);
