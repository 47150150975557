import { createModel } from '@rematch/core';
import { getPersistor } from '@rematch/persist';
import {
  createAuthentication,
  CreateAuthenticationParams,
  deleteAuthentication,
  getUserData,
} from 'repositories/auth';
import { RootModel } from 'store';

interface AuthStore {
  user: User | null;
  isTokenExpired: boolean;
}

const initialState: AuthStore = { user: null, isTokenExpired: false };

export const auth = createModel<RootModel>()({
  state: initialState,
  reducers: {
    SET_USER: (state, payload: User | null) => {
      return {
        ...state,
        user: payload,
      };
    },
    UPDATE_USER_DATA: (state, payload: User) => {
      if (!state.user) return state;

      return { ...state, user: { ...state.user, ...payload } };
    },
    SET_TOKEN_EXPIRED: (state, payload: boolean) => {
      return { ...state, isTokenExpired: payload };
    },
    RESET: (_, payload: Partial<AuthStore>) => {
      return { ...initialState, ...payload };
    },
    UPDATE_COMPANY(state, payload: Company) {
      if (!state.user?.authRoles.companies) return state;

      const { companies } = state.user.authRoles;

      const _companies = companies.map(company =>
        company.id === payload.id ? { ...company, ...payload } : company,
      );

      return {
        ...state,
        user: {
          ...state.user,
          authRoles: { ...state.user.authRoles, companies: _companies },
        },
      };
    },
    SIGN_IN_USER(state, payload: User) {
      return { ...state, user: payload, isTokenExpired: false };
    },
  },
  effects: dispatch => ({
    async signIn(payload: CreateAuthenticationParams) {
      const user = await createAuthentication(payload);

      if (!user) return;

      dispatch.auth.SIGN_IN_USER(user);
      dispatch.auth.setBackofficesData(user);

      return user;
    },
    async whoAmI(_, state) {
      const userData = await getUserData({ resolveAll: true });

      if (!userData) return;

      if (state.auth.user) dispatch.auth.UPDATE_USER_DATA(userData);

      if (!state.auth.user) dispatch.auth.SIGN_IN_USER(userData);

      dispatch.auth.setBackofficesData(userData);

      return userData;
    },
    setBackofficesData(payload: User | null, state) {
      if (!payload) {
        dispatch.company.SET_COMPANIES([]);
        dispatch.retailer.SET_RETAILERS([]);
        dispatch.network.SET_NETWORKS([]);

        return;
      }

      dispatch.company.SET_COMPANIES(payload.authRoles.companies);
      dispatch.retailer.SET_RETAILERS(payload.authRoles.retailers);
      dispatch.network.SET_NETWORKS(payload.authRoles.networks);

      const { currentCompany } = state.company;
      const { currentRetailer } = state.retailer;
      const { currentNetwork } = state.network;

      if (currentCompany) {
        const updatedCompany = payload.authRoles.companies.find(
          company => company.id === currentCompany.id,
        );

        dispatch.company.SET_CURRENT_COMPANY(updatedCompany ?? null);
      }

      if (currentRetailer) {
        const updatedRetailer = payload.authRoles.retailers.find(
          retailer => retailer.id === currentRetailer.id,
        );

        dispatch.retailer.SET_CURRENT_RETAILER(updatedRetailer ?? null);
      }

      if (currentNetwork) {
        const updatedNetwork = payload.authRoles.networks.find(
          network => network.id === currentNetwork.id,
        );

        dispatch.network.SET_CURRENT_NETWORK(updatedNetwork ?? null);
      }
    },
    async signOut() {
      await deleteAuthentication();
      getPersistor().purge();
      dispatch({ type: 'RESET_APP' });
      dispatch.auth.RESET({});
    },
    async signOutByExpiredToken(_, state) {
      if (!state.auth.user) return;

      await deleteAuthentication();
      getPersistor().purge();
      dispatch({ type: 'RESET_APP' });
      dispatch.auth.RESET({ isTokenExpired: true });
    },
  }),
});
