import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  float: right;
  display: flex;
  align-items: center;
  margin-top: 1rem;

  span {
    margin: 0 0.625rem;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  height: 2rem;
  width: 2rem;
  padding: 0;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.zml.white};

  & + button {
    margin-left: 1rem;
  }

  :hover {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.zml.white};
    filter: brightness(0.9);
  }

  :disabled {
    background-color: ${({ theme }) => theme.palette.zml.grey50};
    color: ${({ theme }) => theme.palette.zml.grey100};
  }
`;
