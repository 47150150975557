import type { EditorThemeClasses } from 'lexical';

export const themeConfig: EditorThemeClasses = {
  list: {
    listitem: 'richText_listItem',
    nested: {
      listitem: 'nestedListItem',
    },
  },
  text: {
    bold: 'richText_bold',
    italic: 'richText_italic',
    strikethrough: 'richText_strikethrough',
  },
};
