import { formatCurrency } from 'utils/currency';
import { resolveGiftcardType } from 'utils/helpers';
import { giftcardTransactionAdapter } from '../transaction';
import { giftcardFormFactorAdapter } from './giftcardFormFactor';

export const miniGiftcardAdapter = (
  giftcard: MiniGiftcard,
): AdaptedGiftcard<MiniGiftcard> => {
  const { type, transactions = [], contextName } = giftcard;

  const giftcardTypeName = resolveGiftcardType({ type, contextName });

  const formattedTransactions = transactions.map(giftcardTransactionAdapter);

  return {
    ...giftcard,
    transactions: formattedTransactions,
    formatted: {
      type: giftcardTypeName,
      balance: formatCurrency(giftcard.balance / 100),
      availableBalance: formatCurrency(giftcard.availableBalance / 100),
      formFactor: giftcardFormFactorAdapter(giftcard),
      activation: {
        amount: formatCurrency(giftcard.activation.amount / 100),
      },
    },
  };
};
