import { ReactNode } from 'react';
import { useMediaQuery } from '@mui/material';
import { defaultTheme } from 'styles/defaultTheme';

const { values: breakpoints } = defaultTheme.breakpoints;

interface Props {
  onMobileUp?: boolean;
  onMobileDown?: boolean;
  onTabletUp?: boolean;
  onTabletDown?: boolean;
  onDesktopSmallUp?: boolean;
  onDesktopSmallDown?: boolean;
  onDesktopUp?: boolean;
  onDesktopDown?: boolean;
  children?: ReactNode;
}

export const HideComponent = (props: Props) => {
  const {
    onMobileUp,
    onMobileDown,
    onTabletUp,
    onTabletDown,
    onDesktopSmallUp,
    onDesktopSmallDown,
    onDesktopUp,
    onDesktopDown,
    children,
  } = props;

  const isMobileUp = useMediaQuery(
    defaultTheme.breakpoints.up(breakpoints.MOBILE),
  );
  const isMobileDown = useMediaQuery(
    defaultTheme.breakpoints.down(breakpoints.MOBILE),
  );

  const isTabletUp = useMediaQuery(
    defaultTheme.breakpoints.up(breakpoints.TABLET),
  );
  const isTabletDown = useMediaQuery(
    defaultTheme.breakpoints.down(breakpoints.TABLET),
  );

  const isDesktopSmallUp = useMediaQuery(
    defaultTheme.breakpoints.up(breakpoints.DESKTOP_SMALL),
  );
  const isDesktopSmallDown = useMediaQuery(
    defaultTheme.breakpoints.down(breakpoints.DESKTOP_SMALL),
  );

  const isDesktopUp = useMediaQuery(
    defaultTheme.breakpoints.up(breakpoints.DESKTOP),
  );
  const isDesktopDown = useMediaQuery(
    defaultTheme.breakpoints.down(breakpoints.DESKTOP),
  );

  if (onMobileUp && isMobileUp) return null;
  if (onMobileDown && isMobileDown) return null;

  if (onTabletUp && isTabletUp) return null;
  if (onTabletDown && isTabletDown) return null;

  if (onDesktopSmallUp && isDesktopSmallUp) return null;
  if (onDesktopSmallDown && isDesktopSmallDown) return null;

  if (onDesktopUp && isDesktopUp) return null;
  if (onDesktopDown && isDesktopDown) return null;

  return <span>{children}</span>;
};
