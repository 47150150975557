import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const IconButtonStyled = styled(IconButton)`
  padding: 0;

  span svg {
    margin: 0;
  }
`;
