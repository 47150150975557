import { createModel } from '@rematch/core';
import { getNetworkByDomainName, getNetworkById } from 'repositories/networks';
import { AppTimezone } from 'services/timezone';
import { RootModel } from 'store';
import { currencyInstance } from 'utils/currency';

interface NetworkStore {
  currentNetwork: Network | null;
  networks: Network[];
  signedInNetwork: SignedNetwork | null;
}

export const initialState: NetworkStore = {
  currentNetwork: null,
  networks: [],
  signedInNetwork: null,
};

export const network = createModel<RootModel>()({
  state: initialState,
  reducers: {
    SET_CURRENT_NETWORK: (state, payload: Network | null) => {
      if (payload?.currency) currencyInstance.setCurrency(payload.currency);

      const appTimezone = AppTimezone.getInstance();

      if (payload?.region?.timezoneId) {
        const { timezoneId } = payload.region;

        appTimezone.setTimezoneId(timezoneId);
      } else {
        appTimezone.resetTimezoneId();
      }

      return {
        ...state,
        currentNetwork: payload,
      };
    },
    UPDATE_NETWORK_DATA: (state, payload: Network) => {
      return {
        ...state,
        currentNetwork: {
          ...state.currentNetwork,
          ...payload,
        },
      };
    },
    SET_NETWORKS: (state, payload: Network[]) => {
      const updatedCurrentNetwork = payload.find(
        findNetwork => findNetwork.id === state.currentNetwork?.id,
      );

      return {
        ...state,
        networks: payload,
        currentNetwork: updatedCurrentNetwork ?? state.currentNetwork,
      };
    },
    SET_SIGNED_IN_NETWORK: (state, payload: Network | null) => {
      return {
        ...state,
        signedInNetwork: payload,
      };
    },
  },
  effects: dispatch => ({
    async updateCurrentNetwork(_, rootState): Promise<Network | undefined> {
      const { currentNetwork } = rootState.network;

      if (!currentNetwork) return;

      const updateNetwork = await getNetworkById(currentNetwork.id);

      if (!updateNetwork) return;

      dispatch.network.UPDATE_NETWORK_DATA(updateNetwork);

      return updateNetwork;
    },
    async getSignedInNetwork(hostname: string) {
      const networkData = await getNetworkByDomainName(hostname);

      if (!networkData || networkData?.networkType === 'MASTER') return;

      dispatch.network.SET_SIGNED_IN_NETWORK(networkData);
      await dispatch.theme.buildAuthTheme(networkData);
    },
  }),
});
