import { useMediaQuery } from '@mui/material';
import { defaultTheme } from 'styles/defaultTheme';

const { values: breakpoints } = defaultTheme.breakpoints;

export const useResponsiveness = () => {
  const isTablet = useMediaQuery(
    defaultTheme.breakpoints.down(breakpoints.TABLET),
  );

  const isMobile = useMediaQuery(
    defaultTheme.breakpoints.down(breakpoints.MOBILE),
  );

  const isDesktopSmall = useMediaQuery(
    defaultTheme.breakpoints.between(
      breakpoints.DESKTOP_SMALL,
      breakpoints.DESKTOP,
    ),
  );

  const isMobileOrTablet = isMobile || isTablet;

  return { isMobile, isTablet, isMobileOrTablet, isDesktopSmall };
};
