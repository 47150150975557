import { Redirect, Switch } from 'react-router-dom';
import { Suspense } from 'react';

import { lazyWithRetry } from 'utils/lazyWithRetry';
import { SuspenseLoading } from 'components/LoadingScreen';
import paths from 'utils/paths';
import { Route } from './Route';

import { RedirectNotFound } from './RedirectNotFound';
import { CompanyRoutes } from './CompanyRoutes';
import { NetworkRoutes } from './NetworkRoutes';
import { RetailerRoutes } from './RetailerRoutes';

const Login = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "auth" */
      /* webpackPreload: true */
      'pages/Accounts/Login'
    ),
);
const Logout = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "auth" */
      /* webpackPreload: true */
      'pages/Accounts/Logout'
    ),
);
const ForgotPassword = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "forgot-password" */
      'pages/Accounts/ForgotPassword'
    ),
);
const ResetPassword = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "reset-password" */
      'pages/Accounts/ResetPassword'
    ),
);
const Signup = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "auth" */
      /* webpackPreload: true */
      'pages/Accounts/Signup'
    ),
);
const VerifyEmail = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "verify-email" */
      /* webpackPreload: false */
      'pages/Accounts/VerifyEmail'
    ),
);
const AcceptInvitation = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "verify-email" */
      /* webpackPreload: false */
      'pages/Accounts/AcceptInvitation'
    ),
);
const AccountDashboard = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "account-dashboard" */
      /* webpackPrefetch: true */
      'pages/Accounts/AccountDashboard'
    ),
);
const Profile = lazyWithRetry(
  () => import(/* webpackChunkName: "profile" */ 'pages/Accounts/Profile'),
);
const ManageStoreProfilePermission = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "manage-store-profile-permission" */ 'pages/Accounts/ManageStoreProfilePermission'
    ),
);

const renderLoading = () => <SuspenseLoading />;

export const Routes = () => {
  return (
    <Suspense fallback={renderLoading()}>
      <Switch>
        <Redirect path={paths.HOME} exact to={paths.LOGIN} />

        <Route path={paths.LOGIN} component={Login} />
        <Route path={paths.LOGOUT} component={Logout} isPrivate />
        <Route path={paths.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route
          path={paths.MAGIC_URL + paths.RESET_PASSWORD}
          component={ResetPassword}
        />
        <Route
          path={paths.MAGIC_URL + paths.VERIFY_EMAIL}
          component={VerifyEmail}
          preventPrivateRedirect
        />
        <Route
          path={paths.MAGIC_URL + paths.ACCEPT_INVITE}
          component={AcceptInvitation}
          preventPrivateRedirect
        />
        <Route path={paths.SIGNUP} component={Signup} />
        <Route
          path={paths.SIGNUPSTORE}
          component={Signup}
          preventPrivateRedirect
        />
        <Route
          path={paths.SIGNUP_COMPANY}
          component={Signup}
          preventPrivateRedirect
        />

        <Route
          path={paths.MAGIC_URL + paths.PROFILE_PERMISSION}
          exact
          component={ManageStoreProfilePermission}
          preventPrivateRedirect
        />

        <Route
          path={paths.DASHBOARD}
          exact
          component={AccountDashboard}
          isPrivate
        />
        <Route path={paths.PROFILE} exact component={Profile} isPrivate />

        <Route path={paths.RETAILER} component={RetailerRoutes} isPrivate />
        <Route path={paths.COMPANY} component={CompanyRoutes} isPrivate />
        <Route path={paths.NETWORK} component={NetworkRoutes} isPrivate />

        <Route path="*" component={RedirectNotFound} />
      </Switch>
    </Suspense>
  );
};
