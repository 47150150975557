import { createTheme, ThemeOptions } from '@mui/material';
import { FlattenSimpleInterpolation } from 'styled-components';
import { MainColors, DefaultColors } from './colors';
import { defaultGradient } from './gradient';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    MOBILE: true;
    TABLET: true;
    DESKTOP_SMALL: true;
    DESKTOP: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    zml: ZmyleThemeColors;
    gradient: FlattenSimpleInterpolation;
  }
  interface PaletteOptions {
    zml: ZmyleThemeColors;
    gradient: FlattenSimpleInterpolation;
  }
}

export const zmlColors = Object.entries(MainColors).reduce((acc, curr) => {
  const [key, value] = curr;
  return {
    [key]: value,
    ...acc,
  };
}, {} as ZmyleThemeColors);

export const defaultThemeConfig: ThemeOptions = {
  palette: {
    primary: {
      main: DefaultColors.primary,
      contrastText: MainColors.white,
    },
    zml: zmlColors,
    gradient: defaultGradient,
    text: {
      primary: MainColors.blue800,
      secondary: MainColors.blue800,
    },
    divider: MainColors.grey100,
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: MainColors.blue800,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: MainColors.grey100,
        },
      },
    },
  },
  breakpoints: {
    keys: ['MOBILE', 'TABLET', 'DESKTOP_SMALL', 'DESKTOP'],
    values: {
      MOBILE: 800,
      TABLET: 1024,
      DESKTOP_SMALL: 1440,
      DESKTOP: 1920,
      xs: 0,
      sm: 800,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
};

export const defaultTheme = createTheme(defaultThemeConfig);

export const MOBILE_BREAKPOINT = defaultTheme.breakpoints.down(
  defaultTheme.breakpoints.values.MOBILE,
);

export const TABLET_BREAKPOINT = defaultTheme.breakpoints.down(
  defaultTheme.breakpoints.values.TABLET,
);

export const DESKTOP_SMALL_BREAKPOINT = defaultTheme.breakpoints.down(
  defaultTheme.breakpoints.values.DESKTOP_SMALL,
);

export const TABLET_AND_UP_BREAKPOINT = defaultTheme.breakpoints.up(
  defaultTheme.breakpoints.values.TABLET,
);

export const DESKTOP_SMALL_AND_UP_BREAKPOINT = defaultTheme.breakpoints.up(
  defaultTheme.breakpoints.values.DESKTOP_SMALL,
);

export const BETWEEN_TABLET_AND_DESKTOP_SMALL =
  defaultTheme.breakpoints.between(
    defaultTheme.breakpoints.values.TABLET,
    defaultTheme.breakpoints.values.DESKTOP_SMALL,
  );
