import styled from 'styled-components';
import { Paper } from '@mui/material';

export const PaperStyled = styled(Paper)`
  .MuiAutocomplete-listbox {
    padding-bottom: 0;
  }
`;

export const ButtonStyled = styled.button`
  border: 0;
  background: transparent;
  width: 100%;
  text-align: left;

  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0.375rem 1rem;
  margin-bottom: 0.5rem;

  :hover {
    text-decoration: underline;
  }
`;
