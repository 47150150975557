import styled from 'styled-components';

import { WizardDialog } from 'components';

export const WizardDialogStyled = styled(WizardDialog)`
  div.MuiDialog-paper {
    min-width: 28.125rem;
  }
`;

export const WalletPictureContainer = styled.div`
  max-height: 8.812rem;
  width: calc(100% - 1.5rem - 2px);
  background: ${({ theme }) => theme.palette.zml.blue300};

  position: absolute;
  top: 6rem;
  left: 50%;
  transform: translateX(-50%);

  img {
    max-width: 100%;
  }
`;

export const TemplateImg = styled.img`
  margin-bottom: 1rem;
  box-shadow: 6px 6px 6px ${({ theme }) => theme.palette.zml.boxShadow};
  width: 100%;

  border: 1px solid ${({ theme }) => theme.palette.zml.grey100};
  border-radius: 0.75rem;
`;
