export class AppTimezone {
  // eslint-disable-next-line no-use-before-define
  private static instance: AppTimezone;

  private timezoneId: string;

  private defaultTimezoneId = 'Europe/Berlin';

  constructor() {
    this.timezoneId = this.defaultTimezoneId;
  }

  public static init() {
    AppTimezone.instance = new AppTimezone();
  }

  public static getInstance() {
    if (!AppTimezone.instance) AppTimezone.init();

    return AppTimezone.instance;
  }

  public getTimezoneId() {
    return this.timezoneId;
  }

  public setTimezoneId(timezoneId: string) {
    this.timezoneId = timezoneId;
  }

  public resetTimezoneId() {
    this.timezoneId = this.defaultTimezoneId;
  }
}
