import { ComponentProps } from 'react';
import { ContentEditable as LexicalContentEditable } from '@lexical/react/LexicalContentEditable';

import { ContentEditableStyled } from './ContentEditable.styled';

type Props = ComponentProps<typeof LexicalContentEditable>;

export const ContentEditable = (props: Props) => {
  return <ContentEditableStyled {...props} />;
};
