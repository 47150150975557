import styled from 'styled-components';

export const Container = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.1875rem;
  padding: 0.312rem 0.625rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.zml.white};
  ${({ theme }) => theme.palette.gradient};
`;
