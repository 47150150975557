import styled from 'styled-components';
import { Container as MaterialContainer } from '@mui/material';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

export const Container = styled(MaterialContainer).attrs({
  component: 'main',
  maxWidth: 'xs',
})`
  padding: 2rem 2rem 0 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  background-color: ${({ theme }) => theme.palette.zml.grey50};

  ${MOBILE_BREAKPOINT} {
    padding: 1rem 1rem 0 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
`;
