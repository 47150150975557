import { getI18nNamespace } from 'utils/helpers';
import paths from 'utils/paths';

const retailerTranslator = getI18nNamespace('retailer');
const companyTranslator = getI18nNamespace('company');
const networkTranslator = getI18nNamespace('network');
const componentsTranslator = getI18nNamespace('components');

export const pagesTitle = [
  // Retailer
  {
    pathname: paths.RETAILER + paths.STORE_DETAILS + paths.PROFILE,
    title: retailerTranslator('retailers.storeProfilePage.title'),
  },
  {
    pathname: paths.RETAILER + paths.TRANSACTION_DETAILS,
    title: retailerTranslator('retailers.transactionDetailsPage.title'),
  },
  {
    pathname: paths.RETAILER + paths.GIFTCARDS_DETAILS,
    title: retailerTranslator('retailers.giftcardDetailsPage.title'),
  },
  {
    pathname: paths.RETAILER + paths.SETTINGS,
    title: retailerTranslator('retailers.settingsPage.title'),
  },
  {
    pathname: paths.RETAILER + paths.DEVELOPERS + paths.API_KEYS_DETAILS,
    title: retailerTranslator('retailers.sidebar.developer'),
  },
  {
    pathname: paths.RETAILER + paths.USER_DETAILS,
    title: componentsTranslator('teamMemberDetails.title'),
  },

  // Company
  {
    pathname: paths.COMPANY + paths.TRANSACTION_DETAILS,
    title: companyTranslator('benefits.transactionDetailsPage.title'),
  },
  {
    pathname: paths.COMPANY + paths.EMPLOYEE,
    title: companyTranslator('benefits.employeeDetailsPage.title'),
  },
  {
    pathname: paths.COMPANY + paths.USERS,
    title: companyTranslator('benefits.teamMembersPage.title'),
  },
  {
    pathname: paths.COMPANY + paths.USER_DETAILS,
    title: componentsTranslator('teamMemberDetails.title'),
  },

  // Network
  {
    pathname: paths.NETWORK + paths.DASHBOARD,
    title: networkTranslator('networks.dashboardPage.title'),
  },
  {
    pathname: paths.NETWORK + paths.STORES,
    title: networkTranslator('networks.sidebar.stores'),
  },
  {
    pathname: paths.NETWORK + paths.STORE_DETAILS,
    title: networkTranslator('networks.storeDetailsPage.title'),
  },
  {
    pathname: paths.NETWORK + paths.STORE_DETAILS + paths.PROFILE,
    title: networkTranslator('networks.storeProfilePage.title'),
  },
  {
    pathname: paths.NETWORK + paths.BENEFITS,
    title: networkTranslator('networks.sidebar.benefits'),
  },
  {
    pathname: paths.NETWORK + paths.FEES,
    title: networkTranslator('networks.sidebar.fees'),
  },
  {
    pathname: paths.NETWORK + paths.STATEMENTS,
    title: networkTranslator('networks.sidebar.statements'),
  },
  {
    pathname: paths.NETWORK + paths.ANALYTICS,
    title: networkTranslator('networks.sidebar.analytics'),
  },
  {
    pathname: paths.NETWORK + paths.BILLING_DETAILS,
    title: networkTranslator('networks.billingDetailsPage.title'),
  },
  {
    pathname: paths.NETWORK + paths.USERS,
    title: networkTranslator('networks.teamMembersPage.title'),
  },
  {
    pathname: paths.NETWORK + paths.USER_DETAILS,
    title: componentsTranslator('teamMemberDetails.title'),
  },
  {
    pathname: paths.NETWORK + paths.LINKS,
    title: networkTranslator('networks.sidebar.services'),
  },
  {
    pathname: paths.NETWORK + paths.API,
    title: networkTranslator('networks.sidebar.api'),
  },
] as const;
