import styled from 'styled-components';
import { IconColors } from 'styles/gradient';

export const Container = styled.div`
  margin: 1rem 0;
  background-color: ${({ theme }) => theme.palette.zml.grey50};
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  span {
    margin-right: 2rem;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  svg {
    margin-top: 0.2rem;
    color: ${IconColors.primary0};
  }
`;
export const Title = styled.h3`
  font-weight: 500;
  text-transform: uppercase;
`;
